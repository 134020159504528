<template>
  <div class="component_padding">
    <div class="text-accent h3">
      <q-btn
        color="positive"
        no-caps
        flat
        rounded
        :icon="lkMode ? 'close' : 'transit_enterexit'"
        :label="lkMode ? message[lang].close_comp : message[lang].goback"
        @click="close"
      />
      <span v-if="lkMode && isImAgree">{{ message[lang].degree360_agreeID }}</span>
      <span v-else-if="lkMode && isImStudent">{{
        message[lang].add_task_degree360
      }}</span>
      <span v-else>{{ message[lang].the_360 }}</span>
    </div>
    <div class="text-warning h4" v-if="degree360 && degree360.readonly">
      <q-icon name="info" />
      {{ message[lang].degree_readonly_info }}
    </div>

    <div v-if="showOptionsBlock">
      <q-expansion-item
        :label="message[lang].show_options_block"
        expand-icon
        default-opened
        header-class="bg-info text-primary"
        expand-icon-class="text-primary"
      >
        <div class="row justify-between q-my-sm">
          <div class="col-8 q-gutter-y-sm">
            <!--  Название -->
            <div>
              <q-input
                :label="message[lang].degree_name"
                v-model="degree360.name"
                borderless
                style="padding-left: 10px; border-radius: 25px !important"
                class="body-0 bg-negative"
                :disable="isForApproved"
              />
            </div>
            <!-- Описание -->
            <div>
              <q-input
                :label="message[lang].description"
                v-model="degree360.description"
                type="textarea"
                autogrow
                borderless
                style="padding-left:10px;  border-radius: 25px !important; background-color: white"
                class="body-0"
                :disable="isForApproved"
                />
            </div>
            <!-- Задачи -->
            <!-- <div v-if="!isForApproved">
              <q-select
                v-if="degree360.flags"
                rounded
                outlined
                option-label="label"
                option-value="value"
                map-options
                emit-value
                v-model="degree360.flags.is_tasks"
                :options="message[lang].degree_tasks"
                :disable="approved === true"
                :label="message[lang].program_tasks"
              />
              <div class="text-accent h6">
                <q-icon name="info" />
                {{ message[lang].degree_task_info }}
              </div>
            </div> -->
            <!-- Флажки -->
            <div class="h4 text-positive" v-if="degree360.flags && !isForApproved">
              <!--Опубликовать в личном кабинете оцениваемого-->
              <q-toggle
                v-model="degree360.flags.pub_lk"
                false-value="N"
                true-value="Y"
                color="primary"
                :label="message[lang].acc"
                left-label
              />
              <div class="text-accent h6">
                <q-icon name="info" />
                {{ message[lang].degree_pub_lk_info }}
              </div>
            </div>

            <div class="h4 text-positive" v-if="degree360.flags && !isForApproved">
              <!-- Скрывать данные оценивающих-->
              <q-toggle
                v-model="degree360.flags.hide_fio"
                false-value="N"
                true-value="Y"
                color="primary"
                :label="message[lang].hide_fio"
                left-label
              />
              <div class="text-accent h6">
                <q-icon name="info" />
                {{ message[lang].degree_hide_fio_info }}
              </div>
            </div>
          </div>
          <!-- правая колонка -->
          <div class="col-4 text-center text-accent" v-if="!isForApproved">
            <q-date
              minimal
              range
              v-model="startFinish"
              :title="message[lang].start_finish"
              :subtitle="showTitleDate"
              :locale="parent.message[lang].appLocale || ''"
              mask="YYYY-MM-DD"
              :readonly="degree360.readonly"
            />
            <div class="text-accent h6">
              <q-icon name="info" />
              {{ message[lang].degree_start_finish_info }}
            </div>
          </div>
        </div>
      </q-expansion-item>
    </div>

    <!-- Список оцениваемых -->
    <div class="q-my-sm">
      <q-expansion-item
        :label="message[lang].show_users_block"
        expand-icon
        v-model="showUsersBlock"
        :default-opened="isForApproved || isImStudent || isImAgree"
        header-class="bg-info text-primary"
        expand-icon-class="text-primary"
      >
        <div class="text-accent h4 q-my-sm">
          <div class="row justify-between items-center">
            <div class="col-5">
              {{ message[lang].add_students }}
              <div class="text-accent h6" v-if="!isForApproved">
                <q-icon name="info" />
                {{ message[lang].degree_add_students_info }}
              </div>
            </div>
            <div class="col-2 text-right" v-if="!isForApproved">
              <q-btn
                v-if="degree360.students && degree360.students.length > 0"
                :disable="degree360.readonly || isForApproved"
                flat
                rounded
                icon="clear"
                color="accent"
                :label="message[lang].em_clear"
                @click="studentsClear()"
              />
            </div>
          </div>

          <div class="q-my-sm q-gutter-x-sm" v-if="!isForApproved">
            <q-btn
              icon="add"
              size="sm"
              :loading="isStudentsLoading"
              outline
              rounded
              color="warning"
              :label="message[lang].add_students"
              @click="usersAdd"
            />
            <q-btn
              icon="add"
              size="sm"
              :loading="isDepsLoading"
              outline
              rounded
              color="secondary"
              :label="message[lang].add_department"
              @click="departmentAdd"
            />
            <!-- <q-btn
              icon="add"
              size="sm"
              :loading="isGroupLoading"
              outline
              rounded
              color="primary"
              :label="message[lang].add_group"
              @click="groupAdd"
            /> -->
          </div>
          <div class="q-my-sm text-accent" v-if="!isForApproved">
            <q-toggle
              v-if="degree360.flags"
              v-model="degree360.flags.is_self"
              :label="message[lang].add_task_degree360"
              color="primary"
              :disable="degree360.readonly || isForApproved"
            >
              <q-tooltip class="h-tt bg-primary" anchor="top middle" self="bottom middle" :delay="500">
                {{ message[lang].add_task_degree360_info }}
              </q-tooltip>
            </q-toggle>
          </div>
        </div>
        <!-- Флаг самооценки -->
        <div class="q-my-sm text-accent">
            <q-toggle
              v-if="degree360.flags"
              v-model="degree360.flags.esteem"
              :label="message[lang].add_esteem"
              color="primary"
              :disable="degree360.readonly"
              @update:model-value="toggleEsteem"
            >
              <q-tooltip class="h-tt bg-primary" anchor="top middle" self="bottom middle" :delay="500">
                {{ message[lang].add_esteem_info }}
              </q-tooltip>
            </q-toggle>
          </div>
        <!-- Оцениваемые -->
        <div v-if="degree360.students && degree360.students.length > 0">
          <q-list bordered class="rounded-borders">
            <q-expansion-item
              :default-opened="degree360.flags.is_self"
              :disable="degree360.flags.is_self"
              group="studentsgroup"
              v-for="(student, stud_index) in fltDegree360Students"
              :key="student.id"
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
              switch-toggle-side
            >
              <template v-slot:header>
                <q-item-section :class="student.experts && student.experts.length? 'text-primary': 'text-accent'">
                  {{ student.name }}
                  <div
                    v-if="!student.experts || !student.experts.length"
                    class="text-warning h5"
                  >
                    {{ message[lang].no_experts }}
                  </div>
                </q-item-section>
                <q-item-section side>
                  <q-btn
                    :disable="degree360.readonly"
                    flat
                    round
                    icon="clear"
                    color="positive"
                    @click="studentRemove(student, stud_index)"
                  />
                </q-item-section>
              </template>

              <template v-slot:default>
                <q-card class="bg-info text-accent">
                  <q-card-section>
                    <!-- Я согласующий у этого оцениваемого -->
                    <div class="text-accent h4 q-py-sm" v-if="isForApproved && isImAgreeThisStudent(student)">
                      {{ message[lang].degree360_agreeID }}
                      <div class="text-accent h5 q-my-sm">
                        <q-icon name="info" />
                        {{ message[lang].add_task_degree360_info }}
                      </div>
                      <q-btn unelevated rounded 
                        :color="student.agreed?'warning':'secondary'"
                        :label="student.agreed?message[lang].decline_degree360:message[lang].agree_degree360"
                        @click="studentAgree(student, stud_index)"
                      >
                        <q-tooltip anchor="top middle" self="bottom middle" :delay="500">
                          {{ message[lang].decline_degree360_info }}
                        </q-tooltip>
                      </q-btn>
                      <div class="q-py-sm">
                        <Comments v-if="show_comments"
                          v-bind:lang="lang"
                          v-bind:entity-id="`agree_${degree360.id}_${student.id}`"
                          v-bind:user_current="parent.user_current"
                          v-bind:parent="parent"
                          v-bind:mode="'raw'"
                       />
                       </div>
                    </div>
                    <div class="text-accent h5" v-if="degree360?.agreeID?.[student.id]">
                      {{ message[lang].agree_user }}: {{ degree360.agreeID[student.id].agree_name }}
                      <span v-if="degree360?.agreeID?.[student.id].agreed" class="text-secondary">
                        ({{ message[lang].degree360_agreed }})
                      </span>
                      <span v-else class="text-warning">
                        ({{ message[lang].degree360_agreeID }})
                      </span>
                      <q-btn
                            :disable="degree360.readonly"
                            flat
                            round
                            icon="clear"
                            color="accent"
                            @click="agreeIDClear(student)"
                          >
                          <q-tooltip anchor="top middle" self="bottom middle" :delay="500">
                            {{ message[lang].clear_agreeID }}
                          </q-tooltip>
                        </q-btn>
                    </div>
                    <!-- Оценивающие у этого оцениваемого -->
                    <div class="text-accent h4">
                      {{ message[lang].add_experts }}
                      <div class="text-accent h5">
                        <q-icon name="info" />
                        {{ message[lang].degree_add_experts_info }}
                      </div>
                    </div>
                    <div class="row q-my-sm q-gutter-x-sm">
                      <div>
                        <q-btn
                          size="xs"
                          outline
                          rounded
                          icon-right="add"
                          color="secondary"
                          :label="message[lang].coworkers"
                          @click="expertsAdd(stud_index)"
                        />
                      </div>
                      <div>
                        <q-btn
                          size="xs"
                          outline
                          rounded
                          color="secondary"
                          :label="message[lang].slaves"
                          @click="slavesAdd(stud_index)"
                        > 
                          <q-tooltip anchor="top middle" self="bottom middle" :delay="500">
                            {{ message[lang].slaves_add }}
                          </q-tooltip>
                        </q-btn>
                        <q-btn
                          size="xs"
                          round
                          color="primary"
                          icon="add"
                          unelevated
                          @click="slavesAddByHands(stud_index)"
                        > 
                          <q-tooltip anchor="top middle" self="bottom middle" :delay="500">
                            {{ message[lang].slaves_add_byhands }}
                          </q-tooltip>
                        </q-btn>
                      </div>
                      <div>
                        <q-btn
                          size="xs"
                          outline
                          rounded
                          color="secondary"
                          :label="message[lang].chief"
                          @click="chiefAdd(stud_index)"
                        > 
                          <q-tooltip anchor="top middle" self="bottom middle" :delay="500">
                            {{ message[lang].chief_add }}
                          </q-tooltip>
                        </q-btn> 
                        <q-btn
                          size="xs"
                          round
                          color="secondary"
                          icon="add"
                          unelevated
                          @click="chiefAddByHands(stud_index)"
                        > 
                          <q-tooltip anchor="top middle" self="bottom middle" :delay="500">
                            {{ message[lang].chief_add_byhands }}
                          </q-tooltip>
                        </q-btn>
                      </div>
                    </div>
                    <!-- Списки Оценивающих -->
                    <div
                      class="text-positive h4"
                      v-if="student.experts && student.experts.length"
                    >
                      <!-- Начальники -->
                      <div
                        class="row justify-between"
                        v-if="student.experts.filter((el) => el.type === 'ch').length > 0" >
                        <div class="col-3">
                          {{ message[lang].chief }}
                        </div>
                        <div class="col-3">
                          <q-btn
                            :disable="degree360.readonly"
                            flat
                            rounded
                            icon="clear"
                            color="accent"
                            :label="message[lang].em_clear"
                            @click="expertsClear(student, 'ch')"
                          />
                        </div>
                      </div>
                      <div class="q-gutter-x-sm text-negative">
                        <q-chip
                          v-for="(ch_item, ch_index) in student.experts.filter(
                            (el) => el.type === 'ch'
                          )"
                          :key="ch_index"
                          :color="ch_item.color"
                          outline
                          :removable="!degree360.readonly"
                          @remove="delExpert(student, ch_item)"
                          size="md"
                          icon="account_circle"
                        >
                          {{ ch_item.name }}
                        </q-chip>
                      </div>
                      <!-- Самооценка (esteem) -->
                      <div  class="q-my-sm q-gutter-y-sm" v-if="degree360.flags.esteem">
                        <div>
                          {{ message[lang].add_esteem }}
                        </div>
                        <div>
                          <q-chip
                            v-for="(exp_item, exp_index) in student.experts.filter(
                              (el) => el.type === 'em' && el.id == student.id)"
                            :key="exp_index"
                            :color="exp_item.color"
                            outline
                            size="md"
                            icon="account_circle"
                          >
                            {{ exp_item.name }}
                          </q-chip>
                        </div>

                      </div>
                      <!-- Коллеги -->
                      <div class="row justify-between"
                        v-if="student.experts.filter((el) => el.type === 'em' && el.id != student.id).length > 0" >
                        <div class="col-3">
                          {{ message[lang].selectusers_label }}
                        </div>
                        <div class="col-3">
                          <q-btn
                            :disable="degree360.readonly"
                            flat
                            rounded
                            icon="clear"
                            color="accent"
                            :label="message[lang].em_clear"
                            @click="expertsClear(student, 'em')"
                          />
                        </div>
                      </div>
                      <div class="q-gutter-x-sm text-negative">
                        <q-chip
                          v-for="(exp_item, exp_index) in student.experts.filter(
                            (el) => el.type === 'em' && el.id != student.id)"
                          :key="exp_index"
                          :color="exp_item.color"
                          outline
                          :removable="!degree360.readonly"
                          @remove="delExpert(student, exp_item)"
                          size="md"
                          icon="account_circle"
                        >
                          {{ exp_item.name }}
                        </q-chip>
                      </div>
                      <!-- Подчиненные -->
                      <div
                        class="row justify-between"
                        v-if="student.experts.filter((el) => el.type === 'sl' && el.id != student.id).length > 0"
                      >
                        <div class="col-3">
                          {{ message[lang].slaves }}
                        </div>
                        <div class="col-3">
                          <q-btn
                            :disable="degree360.readonly"
                            flat
                            rounded
                            icon="clear"
                            color="accent"
                            :label="message[lang].em_clear"
                            @click="expertsClear(student, 'sl')"
                          />
                        </div>
                      </div>
                      <div class="q-gutter-x-sm text-negative">
                        <q-chip
                          v-for="(sl_item, slave_index) in student.experts.filter(
                            (el) => el.type === 'sl' && el.id != student.id
                          )"
                          :key="slave_index"
                          :color="sl_item.color"
                          outline
                          :removable="!degree360.readonly"
                          @remove="delExpert(student, sl_item)"
                          size="md"
                          icon="account_circle"
                        >
                          {{ sl_item.name }}
                        </q-chip>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </template>
            </q-expansion-item>
          </q-list>
        </div>
      </q-expansion-item>
    </div>

    <!-- Компетенции -->
    <div v-if="!isForApproved">
      <q-expansion-item
        :label="message[lang].show_comps_block"
        expand-icon
        v-model="showCompsBlock"
        header-class="bg-info text-primary"
        expand-icon-class="text-primary"
      >
        <div class="q-my-sm q-gutter-y-sm">
          <!-- Добавить Блоки компетенций -->
          <div>
            <q-select
              class="body-0 bg-negative"
              borderless
              style="padding-left: 15px; border-radius: 25px !important"
              v-model="block"
              :options="blocks"
              option-label="name"
              option-value="id"
              map-options
              :label="message[lang].add_block_label"
              :disable="!blocks || blocks.length == 0"
            >
              <template v-slot:append>
                <q-btn
                  icon="add"
                  :color="!profile || degree360.readonly ? 'positive' : 'primary'"
                  dense
                  :disable="!block || degree360.readonly"
                  round
                  flat
                  @click="addCompFromBlock"
                >
                  <q-tooltip>
                    {{ message[lang].add_prof }}
                  </q-tooltip>
                </q-btn>
              </template>
            </q-select>
            <div class="text-accent h5">
              <q-icon name="info" />
              {{ message[lang].degree_add_block_info }}
            </div>
          </div>
          <!-- Добавить Профили -->
          <div>
            <q-select
              class="body-0 bg-negative"
              borderless
              style="padding-left: 15px; border-radius: 25px !important"
              v-model="profile"
              :options="profiles"
              option-label="name"
              option-value="id"
              map-options
              :label="message[lang].add_prof_label"
              :disable="!profiles || profiles.length == 0"
            >
              <template v-slot:append>
                <q-btn
                  icon="add"
                  :color="!profile || degree360.readonly ? 'positive' : 'primary'"
                  dense
                  :disable="!profile || degree360.readonly"
                  round
                  flat
                  @click="addCompFromProfile"
                >
                  <q-tooltip>
                    {{ message[lang].add_prof }}
                  </q-tooltip>
                </q-btn>
              </template>
            </q-select>
            <div class="text-accent h5">
              <q-icon name="info" />
              {{ message[lang].degree_add_prof_info }}
            </div>
          </div>
          <!-- Добавить Компетенции -->
          <div>
            <q-select
              class="body-0 bg-negative"
              borderless
              style="padding-left: 15px; border-radius: 25px !important"
              v-model="competence"
              :options="comps_options"
              option-label="name"
              map-options
              :label="message[lang].add_from_comps"
              @update:model-value="setIndicators"
              :disable="!comps_options || comps_options.length == 0"
            >
              <template v-slot:append>
                <q-btn icon="add" :color=" (!competence && !scale) || degree360.readonly ? 'positive' : 'primary' "
                  dense
                  :disable="!competence || degree360.readonly"
                  round
                  flat
                  @click="addCompFromCompetence"
                >
                  <q-tooltip>
                    {{ message[lang].add_prof }}
                  </q-tooltip>
                </q-btn>
              </template>
            </q-select>
            <div class="text-accent h5">
              <q-icon name="info" />
              {{ message[lang].degree_add_comp_info }}
            </div>
          </div>
          <!-- Выбор шкалы (одна на всю оценку) -->
          <div>
            <q-select
              class="body-0 bg-negative"
              borderless
              style="padding-left: 15px; border-radius: 25px !important"
              v-model="scale"
              :options="scores_options"
              option-label="name"
              map-options
              :label="message[lang].scale_plc"
              @update:model-value="setScale"
              :disable="!scores_options || scores_options.length == 0"
            />
            <div class="text-accent h5">
              <q-icon name="info" />
              {{ message[lang].degree_add_scale_info }}
            </div>
          </div>
          <!--  Шкала оценок -->
          <div v-if="degree360.scale && degree360.scale.length">
            <q-card flat bordered class="bg-info text-accent">
              <q-card-section>
                <q-list dense separator>
                  <q-item-label header>{{ message[lang].selectscale }}</q-item-label>
                  <q-item dense v-for="scale in degree360.scale" :key="scale.id">
                    <q-item-section>
                      <div class="row items-center">
                        <div class="col-2">{{ scale.value }}</div>
                        <div class="col-5">{{ scale.name }}</div>
                        <div class="col-5"><div v-html="scale.desc" /></div>
                      </div>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card-section>
            </q-card>
          </div>

          <div v-if="degree360.comps && degree360.comps.length">
            <!-- Список компетенций -->
            <div class="text-accent h4 q-my-md">
              {{ message[lang].selectcomps_label }}
            </div>
            <q-list bordered>
              <q-expansion-item
                group="somegroup"
                v-for="item in degree360.comps"
                :key="item.id"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                :disable="item.two_levels==='N'" >
                <template v-slot:header>
                  <q-item-section>
                    {{ item.name }}
                  </q-item-section>
                  <q-item-section side>
                    <q-btn
                      :disable="degree360.readonly"
                      flat
                      round
                      icon="clear"
                      color="positive"
                      @click="compRemove(item, index)"
                    />
                  </q-item-section>
                </template>

                <template v-slot:default>
                  <q-card class="bg-info text-accent">
                    <q-card-section>
                      <q-list dense separator>
                        <q-item-label header>{{ message[lang].ipr_ind }}</q-item-label>

                        <q-item dense v-for="ind in item.indicators" :key="ind.id">
                          <q-item-section>
                            {{ ind.name }}
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-card-section>
                  </q-card>
                </template>
              </q-expansion-item>
            </q-list>
          </div>
        </div>
      </q-expansion-item>
    </div>

    <!-- Сохранение -->
    <div class="q-pa-md q-gutter-x-sm" v-if="!isForApproved">
      <!-- Опубликовано toggle-indeterminate  -->
      <div class="h4 text-positive text-left" v-if="!isForApproved">
        <q-toggle
          v-model="approved"
          :label="message[lang].publish_degree360"
          color="primary"
          :disable="!!notValidToPubish && !approved"
        />
         
        <div class="text-accent h6">
          <q-icon name="info" />
          {{ message[lang].degree_agree_degree360_info }}
        </div>
      </div>
      <!-- Сохранить -->
      <q-btn
        :disabled="!validForm"
        icon="save"
        color="secondary"
        no-caps
        unelevated
        rounded
        :label="message[lang].save_comp"
        @click="saveNclose"
      />
      <q-btn
        icon="clear"
        color="warning"
        no-caps
        unelevated
        rounded
        :label="message[lang].close_comp"
        @click="close"
      />

      <ul v-if="notValidToPubish" class="text-warning h4">
        <li v-for="mess_index in notValidToPubish" :key="mess_index">
          {{ message[lang]['degree_valid_'+mess_index] }}
        </li>
      </ul>

    </div>

    <q-inner-loading :showing="loading">
      <q-spinner-oval size="5em" color="positive" />
    </q-inner-loading>
  </div>
</template>

<script>
// import { messageObj } from "src/helpers/message";
import dayjs from "dayjs";
import Comments from "src/Comments.vue";

export default {
  name: "Degree360Add",
  props: {
    degree360Item: Object,
    degree360Index: Number,
    parent: Object,
    lang: String,
    back: String,
    lkMode: Boolean,
  },
  data() {
    return {
      show_comments:true,
      showOptionsBlock: true,
      showUsersBlock: false,
      showCompsBlock: false,
      approved: false,
      degree360: {},
      degree360_OLD: {},
      loading: false,
      profiles: null,
      profile: null,
      blocks:null,
      block:null,
      competence: null,
      scale: null,
      comps_options: [],
      scores_options: [],
      isStudentsLoading: false,
      isDepsLoading: false,
      isGroupLoading: false,
      departments: [],
      startFinish: { from: "", to: "" },
      message: this.parent.dictionary,//messageObj,
    };
  },
  // created(){
  //   this.message = this.parent.dictionary;
  // },
  mounted() {
    let flags_init = { is_tasks: 0, pub_lk: "N", hide_fio: "N", is_self: false, esteem:false };
    //ПРоверим есть ли назначения по этой Оценке 360?
    if (this.degree360Item) {
      console.log(":1: mounted degree360Item=", this.degree360Item);
      this.parent.loadPrograms("udegree360", 1, null, null, this.degree360Item.ID).then((res) => {
          // let DETAIL_TEXT = (this.degree360Item.DETAIL_TEXT && typeof this.degree360Item.DETAIL_TEXT === 'string')?this.degree360Item.DETAIL_TEXT.replace(/\\\\"/g, '\\"'):this.degree360Item.DETAIL_TEXT;
          let DETAIL_TEXT = this.degree360Item.DETAIL_TEXT

           DETAIL_TEXT = DETAIL_TEXT.replace(/(<table[^>]*?>)([\s\S]*?)(<\/table>)/g, (match, p1, p2, p3) => {
            // Экранируем все неэкранированные двойные кавычки в содержимом между <table> и </table>
            const modifiedContent = p2.replace(/(?<!\\)"/g, '\\"');
            return `${p1}${modifiedContent}${p3}`;
            });
            
          if(DETAIL_TEXT && this.parent.isJSON(DETAIL_TEXT)){
            this.degree360 = JSON.parse(DETAIL_TEXT);
          }else if (!DETAIL_TEXT){
            this.degree360 = { name: "", description:"", students: [] };
          }
          else{
            console.log('::bad json: ', DETAIL_TEXT)
          }
          // console.log(":1: mounted DETAIL_TEXT=", DETAIL_TEXT);
          // this.degree360 = this.parent.isJSON(DETAIL_TEXT) ? JSON.parse(DETAIL_TEXT) : (DETAIL_TEXT ? DETAIL_TEXT:{ name: "", description:"", students: [] });
          // console.log(":1.1: mounted degree360=", this.degree360);
          if(this.degree360.comps){
            for(let comp of this.degree360.comps){
              for(let ind of comp.indicators){
                ind.name = this.parent.restoreQuotes(ind.name);
                if(ind.desc){
                  ind.desc = this.parent.restoreQuotes(ind.desc);
                }
                if(ind.value){
                  ind.value = this.parent.restoreQuotes(ind.value);
                }
              }
            }
          }
          if(this.degree360.scale){
            for(let ind of this.degree360.scale){
              ind.name = this.parent.restoreQuotes(ind.name);
                if(ind.desc){
                  ind.desc = this.parent.restoreQuotes(ind.desc);
                }
                if(ind.value){
                  ind.value = this.parent.restoreQuotes(ind.value);
                }
            }
          }
          if(this.degree360?.scale_saved?.indicators){
            this.degree360.scale_saved.name = this.parent.restoreQuotes(this.degree360.scale_saved.name);
            for(let ind of this.degree360.scale_saved.indicators){
              ind.name = this.parent.restoreQuotes(ind.name);
                if(ind.desc){
                  ind.desc = this.parent.restoreQuotes(ind.desc);
                }
                if(ind.value){
                  ind.value = this.parent.restoreQuotes(ind.value);
                }
            }
          }

          // console.log( `:2:mounted ID=${this.degree360Item.ID} isForApproved=${this.isForApproved} udegree360 res=`, res );

          this.degree360.id = this.degree360Item.ID;
          this.degree360.name = this.parent.restoreQuotes(this.degree360.name);
          this.degree360.description = this.parent.restoreQuotes(this.degree360.description);
          this.degree360.students = this.degree360.students || [];
          this.degree360.approved = this.degree360Item.ACTIVE;
          this.scale = this.degree360.scale_saved;
          this.approved = this.degree360Item.ACTIVE == "Y" ? true  : false;
          // this.degree360.agreeID = this.parent.isJSON(this.degree360Item.PREVIEW_TEXT)?JSON.parse(this.degree360Item.PREVIEW_TEXT):"";
          if (this.degree360.agreeID) {
            this.degree360.agreed = this.degree360.agreed ? this.degree360.agreed : false;
            for (let stud_id in this.degree360.agreeID) {
              let stud_index = this.degree360.students.findIndex(
                (item) => item.id == stud_id
              );
              //инициализируем флажки согласования у студентов
              if (stud_index >= 0) {
                this.degree360.students[stud_index].agreed = this.degree360.agreeID[ stud_id ].agreed;
              }
            }
          }
          this.showUsersBlock = this.isForApproved;
          this.degree360.flags = this.parent.isJSON( this.degree360Item.PROPERTY_VALUES.flags )
            ? JSON.parse(this.degree360Item.PROPERTY_VALUES.flags) : this.degree360Item.PROPERTY_VALUES.flags;

          if(!this.degree360.flags || Object.keys(this.degree360.flags).length == 0){
            this.degree360.flags = flags_init;
          }
            

          this.startFinish.from = this.degree360.date_start ? this.degree360.date_start : "";
          this.startFinish.to = this.degree360.date_finish ? this.degree360.date_finish : "";
          //Если есть назначения по этой оценке, то запрещаем редактирование
          if (this.degree360Item.ID && res && res.length > 0) {
            this.degree360.readonly = true;
          }
          console.log(":3: mounted degree360=", this.degree360);
          this.degree360_OLD = {
            students: JSON.parse(JSON.stringify(this.degree360.students)),
          };
        });
    } else {
      this.degree360 = { name: "", students: [], flags: flags_init };
      this.degree360_OLD = { students: [] };
    }

    this.parent.getDepartments().then((departments) => {
      this.departments = departments;
      // console.log('departments', this.departments);

      //Профили
      this.loading = true;
      this.parent.competenceGetList("block").then((res) => {
        if (res && res.length > 0) {
          this.blocks = res.filter((item) => item.published === "Y");
        }
        this.parent.competenceGetList("profile").then((res) => {
          if (res && res.length > 0) {
            this.profiles = res.filter((item) => item.published === "Y");
          }
          this.parent.competenceGetList("competence", true).then((res) => {
            this.comps_options = res;
            this.parent.competenceGetList("score", true).then((res) => {
              this.scores_options = res;
              this.loading = false;
            });
          });
        });
      });


    });
  },
  watch: {
    approved() {
      console.log(":: approved=", this.approved);
    },
    startFinish: function () {
      this.degree360.date_start =
        this.startFinish && this.startFinish.from
          ? dayjs(this.startFinish.from).format("YYYY-MM-DD") + " 00:00:00"
          : "";
      this.degree360.date_finish =
        this.startFinish && this.startFinish.to
          ? dayjs(this.startFinish.to).format("YYYY-MM-DD") + " 23:59:59"
          : "";
    },
    isShowUsersBlock() {
      this.showUsersBlock = this.isShowUsersBlock;
    },
    isShowCompsBlock() {
      this.showCompsBlock = this.isShowCompsBlock;
    },
  },
  computed: {
    isValidStudentsExperts() {
      let valid_experts = false;
      if (this.degree360?.students && this.degree360.students.length > 0) {
        valid_experts = true;
        for (let item of this.degree360.students) {
          if (!item.experts || item.experts.length === 0) {
            valid_experts = false;
            break;
          }
        }
      }
      return valid_experts;
    },
    isStartFinish() {
      return this.startFinish?.from && this.startFinish?.to;
    },
    isForApproved() {
      return this.lkMode;
    },
    isShowUsersBlock() {
      let ret =
        this.lkMode ||
        (this.validForm &&
          this.startFinish &&
          !!this.startFinish.from &&
          !!this.startFinish.to);
      // console.log(":: showUsersBlock=", ret, this.validForm, this.startFinish);
      return ret;
    },
    isShowCompsBlock() {
      let valid_experts = false;
      if (this.degree360.students && this.degree360.students.length > 0) {
        valid_experts = true;
        for (let item of this.degree360.students) {
          if (!item.experts || item.experts.length === 0) {
            valid_experts = false;
            break;
          }
        }
      }
      let ret = this.showUsersBlock && valid_experts;
      // console.log(":: showCompsBlock=",ret, valid_experts, this.degree360.students);
      return ret;
    },
    showTitleDate() {
      let ret = "- - -";
      let date_format = this.parent.appRegion == "ru" ? "DD.MM.YYYY" : "YYYY-MM-DD";
      if (this.startFinish && this.startFinish.from && !this.startFinish.to) {
        ret = dayjs(this.startFinish.from).format(date_format) + " - ?";
      } else if (this.startFinish && this.startFinish.from && this.startFinish.to) {
        ret =
          dayjs(this.startFinish.from).format(date_format) +
          " - " +
          dayjs(this.startFinish.to).format(date_format);
      }
      return ret;
    },
    validForm() {
      let ret = this.degree360 && this.degree360.name && this.degree360.name.trim();
      return !!ret;
    },
    isAllAgreed(){
      if(!this.degree360?.flags?.is_self){
        return true; // это не саморегистрация оценки
      } else {
        if(this.degree360?.students?.length==0){
          console.log(":: Это задание на Оценку 360. Не заданы студенты=");
          return false;
        }
        for(let uid of this.degree360.students.map((item) => item.id)){
          if(!this.degree360?.agreeID?.[uid]?.agreed){
            console.log(":: Это задание на Оценку 360. Нет согласования от ", this.degree360?.agreeID?.[uid]?.agree_name, " для студента ", uid);
            return false;
          }
        }
        return true;
      }

    },
    notValidToPubish() {
      // 
      let ret = [];
        
      if(!this.validForm){
        ret.push(1);
      }
      if(!this.isStartFinish){
        ret.push(2);
      }
      if(!this.isValidStudentsExperts){
        ret.push(3);
      }
      if(!this.degree360.comps || !this.degree360.comps.length){
        ret.push(4);
      }
      if(!this.degree360.scale || !this.degree360.scale.length){
        ret.push(5);
      }
      if(!this.isAllAgreed){
        ret.push(6);
      }
      // false когда все условия выполнены и можно публиковать
      return ret.length == 0?false:ret;
    },
    fltDegree360Students() {
      const uid = this.parent.user_current.ID;
      const my_studs = []; //this.degree360?.agreeID?.[uid]?.agree_id;
      if (this.degree360?.agreeID) {
        for (let stud_id in this.degree360?.agreeID) {
          if (this.degree360.agreeID[stud_id].agree_id == uid) {
            my_studs.push(stud_id); //я у этого студента согласующий
          }
        }
      }
      console.log(":: fltDegree360Students my_studs=", my_studs);
      let ret = this.degree360.students.filter((el) => {
          return (
            !this.isForApproved ||
            (this.isImAgree && my_studs.indexOf(el.id) >= 0) ||
            (this.isImStudent && el.id == uid)
          );
        })
        .map((el) => {
          return { ...el, agreed: el.agreed || false };
        });
        console.log(":: fltDegree360Students ret=", ret);

      return ret;
    },
    //Я Оцениваемый
    isImStudent() {
      if (!this.degree360.students) {
        return false;
      }
      const index = this.degree360.students.findIndex(
        (item) => this.isForApproved && item.id == this.parent.user_current.ID
      );
      return index >= 0;
    },
    //Я Согласующий
    isImAgree() {
      let ret = false; //!!this.degree360?.agreeID?.[this.parent.user_current.ID];
      if (this.degree360?.agreeID) {
        for (let uid in this.degree360.agreeID) {
          if (this.degree360.agreeID[uid].agree_id == this.parent.user_current.ID) {
            ret = true;
            break;
          }
        }
      }
      return ret;
    },
  },
  methods: {
    isImAgreeThisStudent(student){
      if(this.degree360?.agreeID?.[student.id]){
        return this.degree360.agreeID[student.id].agree_id == this.parent.user_current.ID;
      }
      return false;
    },
    isImThisStudent(student){
        return student.id == this.parent.user_current.ID;
    },

    studentAgree(student, stud_index) {
      // console.log('::studentAgree student=',student)
      let $this=this;
      $this.parent.title_confirm = student.agreed ? this.message[this.lang].decline_degree360 : this.message[this.lang].agree_degree360;
      $this.parent.message_confirm = student.agreed ? this.message[this.lang].decline_degree360_mess : this.message[this.lang].agree_degree360_mess;
      $this.parent.confirm_input_label = this.message[this.lang].the_comment;
      $this.parent.show_confirm_input = true;
      $this.parent.confirm_input = '';
      $this.parent.confirm = true;
      $this.parent.do_confirm = ()=> {
        console.log('::studentAgree do_confirm student=',stud_index,student)
        let stud_id = $this.degree360.students.findIndex((item) => item.id == student.id);
        if (stud_id >= 0) {
          $this.degree360.students[stud_id].agreed = !student.agreed;
          if ($this.degree360?.agreeID?.[student.id]) {
            $this.degree360.agreeID[student.id].agreed = !student.agreed;
          }
          this.savePartDegree360({
            student: student,
            agreeID: { stud_id: student.id, obj: $this.degree360.agreeID[student.id] },
          });
          //запишем коммнтарий
          if($this.parent.confirm_input){
            const entityId = 'agree_'+$this.degree360.id+'_'+student.id;
            var user = {
              id: $this.parent.user_current.ID,
              name: $this.parent.user_current.NAME + ' ' + $this.parent.user_current.LAST_NAME,
              photo: $this.parent.user_current.PERSONAL_PHOTO
            };
            let rightNow = new Date();
            let timestamp = Date.now() / 1000 | 0;
            let timeNow = dayjs().format('DD.MM.YYYY HH:mm'); //rightNow.toISOString().slice(0, 19).replace(/T/g, " ");
            
            let text = {id: timestamp, user: user, time: timeNow, text: $this.parent.confirm_input};
            let new_comment = {id: 0, TEXT: text};
            $this.parent.saveComments(entityId, [new_comment]);
            setTimeout(() => {
              $this.show_comments = false;
              $this.$nextTick(()=>{
                $this.parent.confirm_input = '';
                $this.show_comments = true;                
              })
            },500)
          }
        } else {
          console.error("::studentAgree ERROR: student not found. student=", student);
        }
        $this.parent.show_confirm_input = false;
        $this.parent.confirm = false;
      };
    },
    agreeIDClear(student){
      let $this = this;
      $this.parent.do_confirm = () => {
        delete $this.degree360.agreeID[student.id];
        $this.parent.confirm = false;
        console.log(":: agreeIDClear success degree360=", $this.degree360);
      };
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm = this.message[this.lang].clear_agreeID +' '+ $this.degree360.agreeID[student.id].agree_name + '?';
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    studentsClear(students) {
      let $this = this;
      $this.parent.do_confirm = () => {
        $this.degree360.students = [];
        $this.parent.confirm = false;
        console.log(":: studentsClear degree360=", $this.degree360);
      };
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm = this.message[this.lang].stidents_clear;
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    /**
     * Если есть студенты
     * Если флаг самооценки установлен. добавим в список оценивающих самого сотрудника
     * Если нет - уберем
     */
    toggleEsteem(){
      console.log(':: toggleEsteem degree360=', this.degree360.flags.esteem);
      if(this.degree360.students){
        for(let ind in this.degree360.students){
          this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
          const index = this.degree360.students[ind].experts.findIndex((item) => item.id == this.degree360.students[ind].id);
          if(this.degree360?.flags?.esteem){
            if(index<0){
              this.degree360.students[ind].experts.splice(0, 0, 
                { 
                  id: this.degree360.students[ind].id, 
                  name: this.degree360.students[ind].name,
                  deps: this.degree360.students[ind].deps,
                  color: "accent",
                  type: "em" 
                });
            }

          }else{
             if(index>=0){
              this.degree360.students[ind].experts.splice(index, 1); 
             }
          }
          
        }
      }
    },
    expertsAdd(ind) {
      // console.log(":: expertsAdd ind=", ind, this.degree360.students);
      this.degree360.students[ind] = this.degree360.students[ind] || { experts: [] };
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = this.degree360.students[ind].experts;
      this.parent.usersAdd(experts).then((list) => {
        console.log(":: expertsAdd list=", list);
        // this.parent.getSubAccounts(list).then((res) => {
        //   console.log(":: expertsAdd res=", res);
          experts = experts.concat(list);
          for(let exp of experts){
            if(!exp.hasOwnProperty('type')){
              exp.type='em';
            }
          }
          
          this.degree360.students[ind].experts = experts;
         
          console.log("::expertsAdd experts=", this.degree360.students[ind].experts);
          if (this.isForApproved) {
            //Если это согласование, то нужно немедленное сохранение
            //что бы не пересечься с другими согласованиями или добавлениями согласующих
            const student = this.degree360.students[ind];
            this.savePartDegree360({
              student: student,
            });
          }
        // });
      });
    },
    // findObjectByValue(array, value) {
    //   return array.find(item => item.value == value);
    // },
    // getChain(array, startValue) {
    //   const result = [];
    //   let currentObject = this.findObjectByValue(array, startValue);

    //   while (currentObject) {
    //       result.push(currentObject);
    //       currentObject = this.findObjectByValue(array, currentObject.parent);
    //   }

    //   return result;
    // },
    chiefAddByHands(ind) {
       let $this = this;
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = this.degree360.students[ind].experts;
      this.parent.usersAdd(experts, false).then((res) => {
        console.log("::chiefAdd res=", res);
        for(let i in res){
          let user = res[i];
          let itemUser = {
            id: user.id,
            name: user.name,
            deps: user.dep,
            color: "secondary",
            type: "ch",
          };
          experts.push(itemUser);
        }
      });
    },
    chiefAdd(ind) {
      let $this = this;
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = this.degree360.students[ind].experts;
      let stud_id = this.degree360.students[ind].id;
      console.log("::chiefAdd stud_id, departments=", stud_id, $this.departments);
      
        this.parent.appOptionGet('struct').then((res) => {
          if(this.parent.isJSON(res.DETAIL_TEXT)){
            let tree = JSON.parse(res.DETAIL_TEXT);
            if(tree[0]){
              let stud_deps=[];
              this.parent.getUserDepartments(stud_id, tree[0], stud_deps)

              console.log("::chiefAdd stud_deps=", stud_deps);
              let arChiefs = [];
              for (let dep_id of stud_deps) {
                let item = $this.departments.find((el) => el.value == dep_id);
                console.log("::chiefAdd dep_id=", dep_id, item);
                if (item?.uf_head && item.uf_head != stud_id && item.uf_head != 0) {
                  arChiefs.push(item.uf_head);
                }else if(item && (!item.uf_head || item.uf_head == stud_id) ){
                  //Если юзер сам начальник в этом отделе, то поищем начальника в отделе выше по стркуктуре
                  const chain = this.parent.getChain($this.departments, item.value);
                  console.log(`id=${item.ID}, chain=`,chain);
                  for(let dep of chain){
                    if(dep.uf_head && dep.uf_head != stud_id){
                      arChiefs.push(dep.uf_head);
                      break;
                    }
                  }

                }
              }
              console.log("::chiefAdd arChiefs =", arChiefs);
              if (arChiefs.length > 0) {
                //for id получаем name начлаьников отделов юзера
                $this.parent.getUsersByIds(arChiefs).then( (users) => {
                    console.log("::chiefAdd Chiefs =", users);
                    for (let i in users) {
                      let user = users[i];
                      let foundUser = experts.find((elem) => elem.id == user.id);
                      if (!foundUser) {
                        let itemUser = {
                          id: user.id,
                          name: user.name,
                          deps: user.dep,
                          color: "secondary",
                          type: "ch",
                        };
                        experts.push(itemUser);
                      }
                    }
                    console.log("::chiefAdd experts =", experts);
                    if ($this.isForApproved) {
                      //Если это согласование, то нужно немедленное сохранение
                      //что бы не пересечься с другими согласованиями или добавлениями согласующих
                      const student = $this.degree360.students[ind];
                      $this.savePartDegree360({
                        student: student,
                      });
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }else{
                //если нет начальников, то предлагаем выбрать вручную
                this.parent.usersAdd([], true).then((res) => {
                    console.log("::chiefAdd res=", res);
                    let user = res[0];
                    let itemUser = {
                      id: user.id,
                      name: user.name,
                      deps: user.dep,
                      color: "primary",
                      type: "ch",
                    };
                    experts.push(itemUser);
                  });
              }
            }
          }
        });
      // }
    },
    slavesAddByHands(ind) {
      let $this = this;
      let experts = this.degree360.students[ind].experts || [];
      this.parent.usersAdd(experts, false).then((res) => {
        console.log("::slavesAddByHands res=", res);
        for(let i in res){
          let user = res[i];
          let itemUser = {
            id: user.id,
            name: user.name,
            deps: user.dep,
            color: "primary",
            type: "sl",
          };
          experts.push(itemUser);
        }
      });
    },
    slavesAdd(ind){
      let $this = this;
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = this.degree360.students[ind].experts;
      let stud_id = this.degree360.students[ind].id;
      console.log("::slavesAdd stud_id, departments=", stud_id, $this.departments);
      
      this.parent.appOptionGet('struct').then((res) => {
        if(this.parent.isJSON(res.DETAIL_TEXT)){
          let tree = JSON.parse(res.DETAIL_TEXT);
          if(tree[0]){
            let stud_deps=[];
            this.parent.getUserDepartments(stud_id, tree[0], stud_deps)
            console.log("::slavesAdd stud_deps=", stud_deps); //отделы юзера
            let deps_head_ids=[]; // массив id отделов где юзер начальник
            for(let dep of stud_deps){
              let item = $this.departments.find((el) => el.value == dep);
              if(item && item.uf_head==stud_id){
                //юзер начальник в этом отделе
                deps_head_ids.push(item.value);
              }
            }
            if(deps_head_ids.length>0){
               $this.parent.getUsers(true, deps_head_ids).then( (users) => {
                console.log("::slavesAdd users =", users);
                for (let i in users) {
                  let user = users[i];
                  if(!experts.find((elem) => elem.id == user.id)){
                    if (!user.id != stud_id){
                      let itemUser = {
                        id: user.id,
                        name: user.name,
                        deps: user.dep,
                        color: "primary",
                        type: "sl",
                      };
                      experts.push(itemUser);
                    }
                  }else{
                    $this.parent.notify_title = this.message[this.lang].attention;
                    $this.parent.notify_text = this.message[this.lang].such_user_exist;
                    $this.parent.notify = true;
                  }
                }
              });
            }

          }
        }
      });

    },
    // slavesAdd_OLD(ind) {
    //   let $this = this;
    //   let experts = this.degree360.students[ind].experts || [];
    //   let stud_deps = this.degree360.students[ind].dep;
    //   let stud_id = this.degree360.students[ind].id;

    //   if (stud_deps && stud_deps.length > 0) {
    //     let arBatch = {};
    //     for (let id of stud_deps) {
    //       arBatch["get_" + id] = ["department.get", { ID: id }];
    //     }
    //     $this.parent.callBatchIB(arBatch).then((result) => {
    //       console.log("::slavesAdd arBatch result=", result);
    //       for (let i in result.result) {
    //         console.log("::slavesAdd arBatch result=", i, result.result[i]);
    //         let item = result.result[i][0];
    //         if (item && item.UF_HEAD == stud_id) {
    //           //насяльника в своем отделе
    //           let allSubDeps = $this.parent.getChildren($this.departments, item.ID);
    //           console.log(":++++++: allSubDeps=", allSubDeps);
    //           //получаем юзеров этого отдела с подотделами
    //           $this.parent
    //             .getUsers(
    //               true,
    //               [...item.ID, ...allSubDeps.map((elem) => parseInt(item.value))],
    //               null
    //             )
    //             .then(
    //               (users) => {
    //                 for (let i in users) {
    //                   let user = users[i];
    //                   let foundUser = experts.find((elem) => elem.id == user.ID);
    //                   if (!foundUser) {
    //                     let itemUser = {
    //                       id: user.ID,
    //                       name: user.NAME + " " + user.LAST_NAME,
    //                       deps: user.UF_DEPARTMENT,
    //                       color: "primary",
    //                       type: "sl",
    //                     };
    //                     experts.push(itemUser);
    //                   }
    //                 }
    //                 // $this.parent.getSubAccounts(experts).then((res) => {
    //                 //   experts = experts.concat(res);
    //                   console.log("::slavesAdd experts=", experts);
    //                   this.degree360.students[ind].experts = experts;
    //                   if ($this.isForApproved) {
    //                     //Если это согласование, то нужно немедленное сохранение
    //                     //что бы не пересечься с другими согласованиями или добавлениями согласующих
    //                     const student = $this.degree360.students[ind];
    //                     $this.savePartDegree360({
    //                       student: student,
    //                     });
    //                   }
    //                 // });
    //               },
    //               (err) => {
    //                 console.log(err);
    //               }
    //             );
    //         }
    //       }
    //     });
    //   }
    // },

    usersAdd() {
      this.parent.usersAdd(this.degree360.students).then((res) => {
        console.log("::usersAdd res=", res);
        this.degree360.students = this.degree360.students.concat(res);
        this.toggleEsteem();
      });
    },
    departmentAdd() {
      let nn = 0;
      let $this = this;
      this.isDepsLoading = true;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list = [];
      this.parent.getDepartments().then(
        (deps) => {
          let parent = 0,
            sub_parent = 0,
            sub_parent2 = 0;
          for (let i in deps) {
            let item = deps[i];
            if (
              this.parent.isModer ||
              this.ImUser.value == item.uf_head ||
              parent == item.parent ||
              sub_parent == item.parent ||
              sub_parent2 == item.parent
            ) {
              $this.parent.modal_list.push(item);

              if (parent == item.parent) {
                sub_parent = item.value;
              }
              if (sub_parent == item.parent) {
                sub_parent2 = item.value;
              }
            }
          }
          this.isDepsLoading = false;
          $this.parent.model = null;
          $this.parent.modal_flt = "";
          $this.parent.modal_list_single = true;
          $this.parent.modal_list_label = $this.message[$this.lang].selectdeps_label;
          $this.parent.modal_title = $this.message[$this.lang].selectdep_title;
          $this.parent.modal_message = $this.message[$this.lang].selectdep_message;
          $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
          $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;
          $this.parent.modal_filterFn = null;
          $this.parent.okModal = () => {
            $this.degree360.students = $this.degree360.students || [];
            $this.parent.modal_loading = true;
            let allDeps = [parseInt($this.parent.modal_list_selected)];
            $this.parent.getAllSubDeps( $this.parent.modal_list, parseInt($this.parent.modal_list_selected), allDeps );
            setTimeout(() => {
              $this.parent.getUsers(true, allDeps).then( (users) => {
                console.log(':: users=',users)
                  let items = [];
                  for (let i in users) {
                    let user = users[i];
                    if ( user && !$this.degree360.students.find(el=>el.id==user.id) ) {
                      user.color = "secondary"
                      $this.degree360.students.push(user);
                    }
                  }
                  // $this.parent.getSubAccounts($this.degree360.students, null, $this.departments)
                  //   .then((res) => {
                  //     $this.degree360.students = $this.degree360.students.concat(res);
                      $this.toggleEsteem();
                      $this.parent.modal_loading = false;
                      $this.parent.hideModal();
                    // });
                },
                (err) => {
                  console.error(err);
                  $this.parent.modal_loading = false;
                  $this.parent.hideModal();
                }
              );
            }, 2000);
          };

          this.parent.showModal();
        },
        (err) => {
          this.isDepsLoading = false;
          console.log("::err=", err);
        }
      );
    },
    groupAdd() {
      let $this = this;
      this.isGroupLoading = true;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list = [];
      this.parent.getGroupList().then(
        (groups) => {
          $this.isGroupLoading = false;
          $this.parent.modal_list = groups;
          $this.parent.modal_flt = "";
          $this.parent.model = null;
          $this.parent.modal_list_single = true;
          $this.parent.modal_list_label = $this.message[$this.lang].selectgroups_label;
          $this.parent.modal_title = $this.message[$this.lang].selectgroup_title;
          $this.parent.modal_message = $this.message[$this.lang].selectgroup_message;
          $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
          $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;
          $this.parent.modal_filterFn = null;
          $this.parent.okModal = () => {
            let nn = 0;
            $this.parent.modal_loading = true;
            let grp_id = parseInt($this.parent.modal_list_selected);
            console.log("::grp_id=", grp_id);
            BX24.callMethod(
              "sonet_group.user.get",
              {
                ID: parseInt(grp_id),
              },
              (result) => {
                let groupUsers = result.data();
                console.log("::sonet_group.user.get=", groupUsers);
                let usersIds = [];
                for (let i in groupUsers) {
                  let item = groupUsers[i];
                  usersIds.push(parseInt(item.USER_ID));
                }
                if (usersIds.length > 0) {
                  $this.parent.getUsers(true, "", usersIds).then(
                    (users) => {
                      let items = [];
                      $this.degree360.students = $this.degree360.students ? $this.degree360.students : [];
                      for (let i in users) {
                        let user = users[i];
                        let user_id = parseInt(user.ID);

                        let itemUser = {
                          id: user.ID,
                          name: user.NAME + " " + user.LAST_NAME,
                          dep: [grp_id],
                          deps: user.UF_DEPARTMENT,
                          color: "primary",
                        };
                        if ( itemUser && !$this.degree360.students.find((element, index, array) => {
                            if (element.id == itemUser.id) return true;
                            else return false;
                          }, $this)
                        ) {
                          $this.degree360.students.push(itemUser);
                          // console.log('::sonet_group.user.get push itemUser=',itemUser)
                        }
                      }
                      $this.parent
                        // .getSubAccounts($this.degree360.students, null, $this.departments)
                        // .then((res) => {
                        //   $this.degree360.students = $this.degree360.students.concat(res);
                          console.log(":: selected_users=", $this.degree360.students);
                          $this.toggleEsteem();
                          $this.parent.modal_loading = false;
                          $this.parent.hideModal();
                        // });
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                }
                // more
                if (result.more() && nn++ < 100) result.next();
                else {
                  console.log(
                    "::sonet_group.user.get finish selected_users=",
                    $this.degree360.students
                  );
                }
              }
            );
            // $this.parent.hideModal();
          };
          this.parent.showModal();
        },
        (err) => {
          $this.isGroupLoading = false;
          console.log("::err=", err);
        }
      );
    },
    compRemove(item, index) {
      let $this = this;
      $this.parent.do_confirm = () => {
        $this.degree360.comps.splice(index, 1);
        $this.parent.confirm = false;
      };
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm =
        this.message[this.lang].del_competence + ' "' + item.name + '"?';
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    studentRemove(student, index) {
      let $this = this;
      $this.parent.do_confirm = () => {
        $this.degree360.students.splice(index, 1);
        $this.parent.confirm = false;
      };
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm =
        this.message[this.lang].del_student + ' "' + student.name + '"?';
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    addCompFromBlock() {
      console.log(":: addCompFromBlock block=", this.block);
      if (this.block && this.block.indicators) {
        for (let item_comp of this.block.indicators) {
          let itemComp = {
            id: item_comp.id,
            name: item_comp.name,
            two_levels: item_comp.two_levels,
            indicators: item_comp.indicators.map((el, index) => {
              return { id: index, name: el.value };
            }),
            
          };
          
          if (this.degree360.comps) {
            const index = this.degree360.comps.findIndex(
              (item) => item.id === itemComp.id
            );
            if (index >= 0) {
              this.degree360.comps[index] = itemComp;
            } else {
              this.degree360.comps.push(itemComp);
            }
          } else {
            this.degree360.comps = [itemComp];
          }
        }
        console.log(":: addCompFromBlock degree360=", this.degree360);
      }
    },
    
    addCompFromProfile() {
      console.log(":: setProfiles profile=", this.profile);
      if (this.profile && this.profile.comps) {
        for (let item_comp of this.profile.comps) {
          let itemComp = {
            id: item_comp.id,
            name: item_comp.name,
            indicators: item_comp.indicators.map((el, index) => {
              return { id: index, name: el.value };
            }),
            // scale: item_comp.scale.indicators.map((el, index) => {
            //   return { id: index, value: el.value, name: el.name, desc: el.desc };
            // }),
          };
          if (this.degree360.comps) {
            const index = this.degree360.comps.findIndex(
              (item) => item.id === itemComp.id
            );
            if (index >= 0) {
              this.degree360.comps[index] = itemComp;
            } else {
              this.degree360.comps.push(itemComp);
            }
          } else {
            this.degree360.comps = [itemComp];
          }
        }
        console.log(":: addCompFromProfile degree360=", this.degree360);
      }
    },
    /**
     * Составить компетенцию и оценку и добавить в список компетенций оценки360
     */
    addCompFromCompetence() {
      let $this = this;
      console.log("::addCompFromCompetence competence=", this.competence, this.scale);
      if (
        this.competence &&
        this.competence.indicators &&
        this.competence.indicators.length 
      ) {
        let itemComp = {
          id: this.competence.id,
          name: this.competence.name,
          two_levels: this.competence.two_levels,
          indicators: this.competence.indicators.map((el, index) => {
            return { id: index, name: el.value };
          }),
          // scale: this.scale.indicators.map((el, index) => {
          //   return { id: index, value: el.value, name: el.name, desc: el.desc };
          // }),
        };

        if (this.degree360.comps) {
          const index = this.degree360.comps.findIndex((item) => item.id === itemComp.id);
          if (index >= 0) {
            this.degree360.comps[index] = itemComp;
          } else {
            this.degree360.comps.push(itemComp);
          }
        } else {
          this.degree360.comps = [itemComp];
        }
        console.log(":: addCompFromCompetence degree360=", this.degree360);
      } else {
        console.error(":: Не полная компетенция, отсутсвуют индикаторы или шкала оценок");
        $this.parent.notify_title = this.message[this.lang].attention;
        $this.parent.notify_text = this.message[this.lang].incomplete_data;
        $this.parent.notify_warning = true;
        $this.parent.notify_persistent = false;
        $this.parent.notify_no_btn = false;
        $this.parent.notify = true;
      }
    },
    setIndicators(arg) {
      console.log("::setIndicators arg=", arg);
    },
    setScale(arg) {
      console.log("::setScale arg=", arg);
      if (arg.indicators && arg.indicators.length) {
        this.degree360.scale = arg.indicators.map((el, index) => {
          return {
            id: index,
            value: el.value,
            name: el.name,
            desc: el.desc,
            scale_id: arg.id,
          };
        });
        this.degree360.scale_saved = arg;
        this.degree360.scale_saved.name = this.parent.restoreQuotes(this.degree360.scale_saved.name)
      }
    },
    makeSchedules() {
      let $this = this;
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm = this.message[this.lang].do_schedules;
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      return new Promise(function (resolve, reject) {
        $this.parent.confirm = true;
        $this.parent.do_confirm = () => {
          // console.log('::makeSchedules degree360=', $this.degree360);
          $this.parent.confirm = false;
          resolve(true);
        };
      });
    },
    /**
     * Назначение оценок 360 юзерам
     *
     */

    saveNclose() {
      this.degree360.approved = this.approved ? "Y" : "N";
      this.degree360.owner_id = this.parent.user_current.ID;
      this.degree360.last_modify = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.degree360.name = this.parent.replaceQuotes(this.degree360.name);
      this.degree360.description = this.parent.replaceTags(this.degree360.description);
      if (this.approved) {
        this.makeSchedules().then((res) => {
          console.log("::makeSchedules res=", res);
          if (res) {
            this.$emit("competence_saved", {
              degree360: this.degree360,
              degree360_OLD: this.degree360_OLD,
              index: this.degree360Index,
              doSchedules: true, //Делаем назначения
            });
          } else {
            this.$emit("competence_saved", {
              degree360: this.degree360,
              degree360_OLD: this.degree360_OLD,
              index: this.degree360Index,
            });
          }
        });
      } else {
        this.$emit("competence_saved", {
          degree360: this.degree360,
          degree360_OLD: this.degree360_OLD,
          index: this.degree360Index,
        });
      }
    },
    close() {
      this.$emit("competence_close");
    },
    /**
     * Загружаем, правим и тут же записываем, что бы не пересечься с другими
     */
    savePartDegree360(part_names = []) {
      let $this = this;
      this.loading = true;
      const degree360_id = parseInt(this.degree360.id);
      console.log(":: savePartDegree360 degree360_id=", degree360_id);
      this.parent.loadPrograms("degree360", null, null, null, degree360_id).then((res) => {
          // console.log(":: savePartDegree360 get degree360 res=", res);
          if (res && res.length > 0) {
            let degree360 = res[0]; //свежая загруженная только что оценка
            let detail_text = this.parent.isJSON(degree360.DETAIL_TEXT) ? JSON.parse(degree360.DETAIL_TEXT) : null;
            //имена того что нужно измнеить находится в part_names
            //Оцениваемый
            if (part_names.hasOwnProperty("student")) {
              const student = part_names.student;
              const stud_index = detail_text.students.findIndex(
                (el) => el.id == student.id
              );
              if (stud_index >= 0) {
                detail_text.students[stud_index] = student;
              }
            }
            console.log(":: savePartDegree360 detail_text=", detail_text);
            //Записываем
            detail_text.name = this.parent.replaceQuotes(detail_text.name);
            detail_text.description = this.parent.replaceTags(detail_text.description);

            let method = "entityItemUpdate";
            let params = {
              ENTITY: "uni_degree360",
              ID: degree360_id,
              NAME: detail_text.name,
            };

            //Блок согласования
            if (part_names.hasOwnProperty("agreeID")) {
              const stud_id = part_names.agreeID.stud_id;
              let agreeID = detail_text.agreeID || {};
              agreeID[stud_id] = part_names.agreeID.obj;
              detail_text.agreeID = agreeID;
              params.PREVIEW_TEXT = JSON.stringify(agreeID);
            }

            console.log(":: savePartDegree360 params=", params);
            console.log(":: savePartDegree360 detail_text=", detail_text);
            var url = $this.parent.bx24Init({ AJAX_ACTION: method, params: params });
            $this.parent.bx24params.DETAIL_TEXT = JSON.stringify(detail_text);
            $this.parent.b24POST( url, $this.parent.bx24params, function (res) {
                console.log("::savePartDegree360 res=", res);
                $this.$emit("lk_load_list_degree360");
                $this.loading = false;
                if($this.checkAllAgreed(detail_text)){
                  //проверим, если все согласовали, то оотправим уведомление модераторам
                  let message = $this.message[$this.lang].degree360_all_agreed + ' "'+$this.parent.restoreQuotes(detail_text.name)+'"';
                  //ТОЛЬКО owner'y
                  // if(degree360.owner_id){
                  //    $this.parent.isNoteSet([degree360.owner_id], "e360").then((checks) => {
                  //       if (Object.keys(checks).length > 0) {
                  //         let message = $this.message[$this.lang].you_agree_360;
                  //         let arBatch2 = {};
                  //         for (let uid of checks) {
                  //           arBatch2["note_" + uid] = [
                  //             "im.notify",
                  //             { to: uid, message: message, type: "SYSTEM" },
                  //           ];
                  //         }
                  //         $this.parent.callBatch(arBatch2);
                  //       }
                  //     });
                  // }else{
                  //Всем модераторам
                    $this.parent.modersNotify(message, "e360");
                  // }
                }

              },
              "json"
            );
          }
        });
    },
    checkAllAgreed(degree360){
      if(!degree360?.flags?.is_self){
        return true; // это не саморегистрация оценки
      } else {
        if(degree360?.students?.length==0){
          console.log(":: Это задание на Оценку 360. Не заданы студенты=");
          return false;
        }
        for(let uid of degree360.students.map((item) => item.id)){
          if(!degree360.agreeID || !degree360.agreeID[uid] || !degree360.agreeID[uid].agreed){
            return false;
          }
        }
        return true;
      }

    },
    delExpert(student, sl_item) {
      let $this = this;
      this.parent.delExpert(student, sl_item);
      if (this.isForApproved) {
        this.$nextTick(() => {
          $this.savePartDegree360({
            student: student,
          });
        });
      }
    },
    async expertsClear(student, sl) {
      const stud_index = this.degree360.students.findIndex(
        el=>el.id == student.id
      )
      let $this = this;
      let student_new = await this.parent.expertsClear(student, sl);
      if(stud_index>=0){
        this.degree360.students[stud_index] = student_new;
      }

      if (this.isForApproved) {
        this.$nextTick(() => {
          $this.savePartDegree360({
            student: student,
          });
        });
      }
    },
  },
  components: {
    Comments
  },
};
</script>
