<template>
<div>

  <!-- Хидер -->
        <div v-show="showPDF" class="row items-center justify-between">
          <div class="col-1  text-positive h4">PDF</div>

          <div class="col-9 text-right">
            <q-btn size="md" flat round color="positive" icon="screen_rotation" @click="toggleRotation" />
            <q-btn size="md" flat round color="positive" icon="zoom_out" @click="setScale(0.75)" />
            <q-btn size="md" flat round color="positive" icon="zoom_in" @click="setScale(1.25)" />
            <q-btn  size="md" flat round color="positive" icon="navigate_before" @click="onPrevPage">
              <q-tooltip class="bg-primary h-tt">{{message[lang].previous}}</q-tooltip>
            </q-btn>
            <span class="q-my-sm text-positive h5">
               {{pageNum}}/{{numPages}}
            </span>
            <q-btn size="md" flat round color="positive" icon="navigate_next" @click="onNextPage">
              <q-tooltip class="bg-primary h-tt">{{message[lang].next}}</q-tooltip>
            </q-btn>
          </div>
        </div>

        <q-separator class="q-ma-md"/>

        <div class="row justify-center" v-show="showAlert">
            <div class="col-6 text-center text-warning">{{message[lang].wrongpdf}}</div>
        </div>
        <div class="row justify-center" v-show="!showAlert">
            <div class="col-10">
              <canvas width="100%" id="the-canvas"></canvas>
            </div>
        </div>

        <q-separator class="q-ma-md"/>
        <!-- Футер -->
        <div v-show="showPDF" class="row items-center justify-between">
          <div class="col-1 text-positive h4"></div>

          <div class="col-9 text-right h4">
            <q-btn size="md" flat round color="positive" icon="screen_rotation" @click="toggleRotation" />
            <q-btn size="md" flat round color="positive" icon="zoom_out" @click="setScale(0.75)" />
            <q-btn size="md" flat round color="positive" icon="zoom_in" @click="setScale(1.25)" />
            <q-btn  size="md" flat round color="positive" icon="navigate_before" @click="onPrevPage">
              <q-tooltip class="bg-primary h-tt">{{message[lang].previous}}</q-tooltip>
            </q-btn>
            <span class="q-my-sm text-positive h5">
               {{pageNum}}/{{numPages}}
            </span>
            <q-btn size="md" flat round color="positive" icon="navigate_next" @click="onNextPage">
              <q-tooltip class="bg-primary h-tt">{{message[lang].next}}</q-tooltip>
            </q-btn>
          </div>
        </div>

  <q-inner-loading :showing="isLoading">
    <q-spinner-oval size="5em" color="positive"/>
  </q-inner-loading>
</div>

</template>

<script>


export default {
    props:{
        lang:String,
        pdfScale:Number,
        pdfData:String,
        pdfUrl:String,
        lesson_id:String,
        parent:Object
    },
    data: function () {
        return {
          isLoading:false,
          rotate:0,
          scale:1,
            showAlert:false,
            showPDF:true,
            pdfjsLib:window['pdfjs-dist/build/pdf'], // pdfjsViewer:window['pdfjs-dist/web/pdf_viewer'],
            pdfDoc: null,
            pageNum: 1,
            numPages:0,
            pageRendering:false,
            pageNumPending:null,
            canvas:null,
            ctx: null,
            search:'',
            message:this.parent.dictionary,
            // message:{
            //   ru:{
            //         goback:"Вернуться",
            //         loading:"Загрузка",
            //         remove:"Удалить",
            //          previous:"Предыдущая",
            //         next:"Следующая",
            //         page:"Стр",
            //         search:"Поиск",
            //         wrongpdf:"В урок загружен поврежденный PDF-файл. Сообщите, пожалуйста администратору.",
            //     },
            //     en:{
            //           goback:"Back",
            //           loading:"Loading",
            //           remove:"Remove",
            //           previous:"Previous",
            //           next:"Next",
            //           page:"Pg",
            //           search:"Search",
            //           wrongpdf:"A damaged PDF file was uploaded to the lesson. Please inform your administrator.",
            //   },
            //   ua:{
            //     goback:"Повернутися",
            //     loading:"Завантаження",
            //     remove:"Видалити",
            //     previous:"Попередня",
            //     next:"Наступна",
            //     page:"Ст",
            //     search:"Пошук",
            //     wrongpdf:"В урок завантажений пошкоджений PDF-файл. Повідомте, будь ласка адміністратора.",
            //   },
            //   fr:{
            //     goback:"Retour",
            //     loading:"Chargement",
            //     remove:"Retirer",
            //     previous:"Précédent",
            //     next:"Suivant",
            //     page:"Pg",
            //     search:"Recherche",
            //     wrongpdf:"Le fichier PDF de la leçon est endommagé. Prenez contact avec votre administrateur.",
            //   },
            //   it:{
            //     goback:"Indietro",
            //     loading:"Caricamento",
            //     remove:"Rimuovi",
            //     previous:"Precedente",
            //     next:"Successivo",
            //     page:"Pg",
            //     search:"Ricerca",
            //     wrongpdf:"Un file PDF danneggiato è stato caricato nella lezione. Informa il tuo amministratore.",
            //   },
            //   tr:{
            //     goback:"Geri",
            //     loading:"Yükleniyor",
            //     remove:"Kaldırmak",
            //     previous:"Öncesi",
            //     next:"Sonraki",
            //     page:"Sf",
            //     search:"Arama",
            //     wrongpdf:"Derse hasarlı bir PDF dosyası yüklendi. Lütfen yöneticinizi bilgilendirin.",
            //   },
            //   de:{
            //     goback:"Zurück",
            //     loading:"Wird geladen",
            //     remove:"Entfernen",
            //     previous:"Vorherige",
            //     next:"Nächste",
            //     page:"Buch",
            //     search:"Suche",
            //     wrongpdf:"Eine beschädigte PDF-Datei wurde in die Lektion hochgeladen. Bitte informieren Sie Ihren Administrator.",
            //   },
            //   pl:{
            //     goback:"Plecy",
            //     loading:"Ładowanie",
            //     remove:"Usunąć",
            //     previous:"Poprzedni",
            //     next:"Następny",
            //     page:"Str",
            //     search:"Szukaj",
            //     wrongpdf:"Uszkodzony plik PDF został przesłany do lekcji. Poinformuj administratora.",
            //   },
            //   pt:{
            //     goback:"Voltar",
            //     loading:"Carregando",
            //     remove:"Retirar",
            //     previous:"Anterior",
            //     next:"Próximo",
            //     page:"Pg",
            //     search:"Procurar",
            //     wrongpdf:"Um arquivo PDF danificado foi carregado para a lição. Informe o seu administrador.",
            //   },
            //   es:{
            //     goback:"Atrás",
            //     loading:"Cargando",
            //     remove:"Eliminar",
            //     previous:"Previo",
            //     next:"Próximo",
            //     page:"Pg",
            //     search:"Buscar",
            //     wrongpdf:"Se cargó un archivo PDF dañado a la lección. Informe a su administrador.",
            //   },
            //   vn:{
            //     goback:"Mặt sau",
            //     loading:"Đang tải",
            //     remove:"Di dời",
            //     previous:"Trước",
            //     next:"Kế tiếp",
            //     page:"Tr",
            //     search:"Tìm kiếm",
            //     wrongpdf:"Một tệp PDF bị hỏng đã được tải lên bài học. Vui lòng thông báo cho quản trị viên của bạn.",
            //   },

            // },
        }
    },
    mounted: function(){
      this.scale=this.pdfScale;
      this.viewPDF();
    },
     watch:{
        pageNum: function(){
            window.localStorage.setItem('page_'+this.lesson_id, this.pageNum);
        },
    },
    methods: {
        doSearch(){
            var $this = this;
            if(this.search){

            }else{
                console.error('::doSearch nothinq to search');
            }

        },
        viewPDF(){
            //console.log('::viewPDF ',this.pdfUrl,this.pdfData);
            var $this = this;
            this.isLoading = true;
            var pnum = window.localStorage.getItem('page_'+this.lesson_id);
            if(pnum)
                this.pageNum = parseInt(pnum);
            // this.pdfjsLib.GlobalWorkerOptions.workerSrc ='/node_modules/pdfjs-dist/build/pdf.worker.js';
            this.pdfjsLib.GlobalWorkerOptions.workerSrc ='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.worker.js';
            //https://cdnjs.com/libraries/pdf.js
            this.canvas = document.getElementById('the-canvas'),
            this.ctx = this.canvas.getContext('2d');
          let loadingTask = null;
          if($this.pdfData){
            loadingTask = this.pdfjsLib.getDocument({data: $this.pdfData});
          }else if($this.pdfUrl){
            loadingTask = this.pdfjsLib.getDocument($this.pdfUrl);
          }
          //console.log('::viewPDF loadingTask=',loadingTask);
          loadingTask.promise.then((pdfDoc_) => {
                if (pdfDoc_) {
                  console.warn('::pdfDoc_', pdfDoc_);
                  $this.pdfDoc = pdfDoc_;
                  $this.numPages = $this.pdfDoc.numPages;
                  // Initial/first page rendering
                  $this.renderPage($this.pageNum);
                  $this.isLoading = false;
                }
            }).catch(e => {
                console.error(e);
                $this.showAlert = true;
                $this.showPDF = false;
                $this.isLoading = false;
            });
                    //this.pdfjsLib.getDocument({data: $this.pdfData}).promise.then(function (pdfDoc_) {
                    // if (pdfDoc_) {
                    //     console.warn('::pdfDoc_', pdfDoc_);
                    //     $this.pdfDoc = pdfDoc_;
                    //     $this.numPages = $this.pdfDoc.numPages;
                    //     // Initial/first page rendering
                    //     $this.renderPage($this.pageNum);
                    // } else {
                    //     $this.showAlert = true;
                    //     $this.showPDF = false;
                    // }

                //});

        },
        queueRenderPage(num) {
            if (this.pageRendering) {
                this.pageNumPending = num;
            } else {
                this.renderPage(num);
            }
        },
        onPrevPage() {
            if (this.pageNum <= 1) {
                return;
            }
            this.pageNum--;
            this.queueRenderPage(this.pageNum);
        },
        onNextPage() {
            if (!this.pdfDoc || this.pageNum >= this.pdfDoc.numPages) {
                return;
            }
            this.pageNum++;
            this.queueRenderPage(this.pageNum);
        },
        setScale(mode){
            //console.log('::setScale scale=', this.scale)
          this.scale *= mode;
          this.queueRenderPage(this.pageNum);
        },
        toggleRotation(){
          this.rotate = this.rotate >=270 ? 0 :   this.rotate + 90;
          this.renderPage(this.pageNum);
        },
        renderPage(num) {
            //console.log(':: renderPage ',num);
            var $this = this;
            this.pageRendering = true;
            // Using promise to fetch the page
            this.pdfDoc.getPage(num).then(function(page) {
                //console.log(':: page ',page);

                var viewport = page.getViewport({scale: $this.scale, rotation:$this.rotate});
                $this.canvas.height = viewport.height;
                $this.canvas.width = viewport.width;

                //console.log('::scale height width = ', $this.scale,$this.canvas.height, $this.canvas.width);
                // Render PDF page into canvas context
                var renderContext = {
                    canvasContext: $this.ctx,
                    viewport: viewport
                };
                var renderTask = page.render(renderContext);

                //console.log(':: Wait for rendering to finish renderTask=', renderTask);
                // Wait for rendering to finish
                renderTask.promise.then(function() {
                  $this.pageRendering = false;
                  //console.log(':: New page rendering is pending pageNumPending=',$this.pageNumPending);
                  if ($this.pageNumPending !== null) {
                    // New page rendering is pending

                    $this.renderPage($this.pageNumPending);
                    $this.pageNumPending = null;
                    // BX24.fitWindow();
                  }
                });
            });
        },
    },
    components: {

    }
}
</script>

<style scoped>


 </style>

