<template>
<div style="padding-left: 15px; padding-bottom: 100px;">
<div class="row items-center justify-between" style="margin-top: -10px">
    <div class="col-6 text-left q-mb-2">
<!--      <a href="#" class="body-2" @click="$emit('goback', goback);" >-->
<!--        <q-img src="~assets/Back/arrow/left.svg"  style="height: 24px; width: 24px"/> {{message[lang].goback}}-->
<!--      </a>-->
      <q-btn color="positive" no-caps flat rounded icon="transit_enterexit" :label="message[lang].goback" @click="$emit('goback', goback);"/>
      <div class="h1">
        {{message[lang].lessons}}
        <q-btn flat rounded color="secondary" icon="refresh" @click="getLessons()">
          <q-tooltip class="bg-primary h-tt">{{message[lang].refresh}}</q-tooltip>
        </q-btn>
        <q-btn flat round color="positive" :icon="isListViewLesson?'dashboard':'list'" @click="isListViewLesson=!isListViewLesson">
          <q-tooltip v-if="isListViewLesson" class="bg-primary h-tt">{{messageObj[lang].view_cards}}</q-tooltip>
          <q-tooltip v-else class="bg-primary h-tt">{{messageObj[lang].view_list}}</q-tooltip>
        </q-btn>
      </div>
    </div>
    <div v-if="parent.isModer || parent.isAdmin" class="col-6 text-right q-gutter-md" style="padding-right: 10px">
      <q-btn padding="xs" :loading="isLoading" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addNewLesson"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-btn>
      <q-btn padding="xs" :loading="isImportLoading" size="lg" flat round color="positive" icon="file_upload" @click="importLessonTogle"><q-tooltip class="bg-primary h-tt">{{message[lang].import}}</q-tooltip></q-btn>

      <!--      <q-img  v-if="!isLoading" style="height: 30px; width: 30px; cursor: pointer" @click="addNewLesson" width="32"  src="~assets/secondary/doc/add.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-img>-->
<!--      <q-img  v-else  width="32" style="height: 30px; width: 30px;" src="~assets/secondary/doc/add_gray.svg">{{message[lang].add}}</q-img>-->

<!--      <q-spinner-oval v-if="isImportLoading"  color="text-positive" size="22px"/>-->
<!--      <q-img v-else style="cursor: pointer; height: 30px; width: 30px;" @click="importLessonTogle" src="~assets/secondary/doc/import.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].import}}</q-tooltip></q-img>-->
<!--      <lesson-export v-if="parent.listLessons" v-bind:lang="lang"  v-bind:id="0" v-bind:list="[...fltListLessons]" v-bind:parent="parent"  @saveExpList="saveExpList" ></lesson-export>-->

      <!--  Линк для скачивания в десктопном приложении    -->
      <div v-if="file_download_loading">
        <q-spinner-oval color="positive" size="30px"/>
      </div>
      <div class="text-positive h4" v-else-if="file_download_link">
        {{message[lang].download}}: <span class="text-right text-primary"  v-html="file_download_link"/>
      </div>
  </div>
</div>

<div class="row justify-center" v-if="isShowImport">
  <div class="col-8">
    <lesson-import v-bind:lang="lang" v-bind:parent="parent"  @importLessons="importLessons" ></lesson-import>
  </div>
</div>
<!--Список уроков-->
  <div v-if="isListViewLesson">
    <q-list separator>
      <q-item clickable v-ripple v-for="(item,index) in fltListLessons" :key="item.ID">
        <q-item-section avatar v-if="item.DETAIL_PICTURE">
          <q-avatar rounded>
            <q-img
              class="rounded-borders"
              :src="item.DETAIL_PICTURE"
              spinner-size="2rem"
              spinner-color="positive"
            />
          </q-avatar>
        </q-item-section>

        <q-item-section @click="viewLesson(item)">
          <q-item-label class="text-positive h5">
            <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">{{item.ID}}</span>
            <span v-if="!razdel">{{parent.showCategoria(item.PREVIEW_TEXT)}}</span>
          </q-item-label>
          <q-item-label class="text-positive h4" lines="2">{{item.NAME}}</q-item-label>
        </q-item-section>

        <q-item-section  side top>
          <q-item-label class="text-positive h5">{{formatDate(item)}}</q-item-label>
          <div class="q-gutter-xs" v-if="parent.isModer" align="right">
            <q-icon :name="item.ACTIVE === 'Y'?'done_all':'remove_done'" :color="item.ACTIVE === 'Y'?'secondary':'warning'" size="sm">
              <q-tooltip v-if="item.ACTIVE === 'Y'" class="bg-primary h-tt">{{message[lang].published}}</q-tooltip>
              <q-tooltip v-else class="bg-warning h-tt">{{message[lang].notpublished}}</q-tooltip>
            </q-icon>
            <q-btn flat round color="positive" icon="edit" @click="editLesson(item)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].edit}}</q-tooltip>
            </q-btn>
            <q-btn flat round color="positive" icon="delete_outline" @click="showModalDel(item,index)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
            </q-btn>
            <lesson-export v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" @saveExpLesson="saveExpLesson" ></lesson-export>
            <lesson-export v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" v-bind:is-clone="true" @cloneExpLesson="cloneExpLesson" ></lesson-export>
          </div>
        </q-item-section>

      </q-item>
    </q-list>
  </div>
<!--Карточки урокв-->
  <div v-else  class="q-pa-md row items-start q-gutter-md">
    <q-card class="cours-card" v-for="(item,index) in fltListLessons" :key="item.ID">
      <q-card-section>
        <div class="row justify-between items-center">
          <div class="col-8 q-gutter-x-md">
            <q-btn unelevated color="secondary" @click="viewLesson(item)">
              {{message[lang].lesson}}
              <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">_{{item.ID}}</span>
            </q-btn>
            <q-img v-if="item.ACTIVE === 'Y'" style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/published.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].published}}</q-tooltip></q-img>
            <q-img v-else style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/draft.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].notpublished}}</q-tooltip></q-img>
          </div>
          <div class="col-4 text-right q-gutter-x-sm">
            <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/>
            {{formatDate(item)}}
          </div>
          <div class="col-12 text-positive h5 q-pt-xs">{{parent.showCategoria(item.PREVIEW_TEXT)}}</div>
        </div>
      </q-card-section>

      <q-card-section horizontal class="body-0 q-pa-sm cursor-pointer" @click="viewLesson(item)" style="cursor:pointer;">
        <q-img v-if="item.DETAIL_PICTURE"
               class="col-4"
               :src="item.DETAIL_PICTURE"
               spinner-size="2rem"
               spinner-color="positive"
        />
        <div class="q-ml-sm text-positive">{{item.NAME}}</div>
      </q-card-section>
      <q-separator v-if="parent.isModer || parent.isAdmin"/>
      <q-card-actions align="right" v-if="parent.isModer || parent.isAdmin">
        <q-btn size="sm" flat round color="positive" icon="visibility" @click="viewLesson(item)">
          <q-tooltip class="bg-primary h-tt">{{message[lang].lesson}}</q-tooltip>
        </q-btn>
        <q-btn v-if="parent.isModer || parent.isAdmin" flat round color="positive" icon="edit" @click="editLesson(item)">
          <q-tooltip class="bg-primary h-tt">{{message[lang].edit}}</q-tooltip>
        </q-btn>
        <q-btn v-if="parent.isModer || parent.isAdmin" flat round color="positive" icon="delete_outline" @click="showModalDel(item,index)">
          <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
        </q-btn>
        <lesson-export v-if="parent.isModer || parent.isAdmin"  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" @saveExpLesson="saveExpLesson" ></lesson-export>
        <lesson-export v-if="parent.isModer || parent.isAdmin"  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" v-bind:is-clone="true" @cloneExpLesson="cloneExpLesson" ></lesson-export>

      </q-card-actions>


    </q-card>
  </div>

  <div v-if="parent.justSearched && Object.keys(parent.listLessons).length === 0">
    {{message[lang].notfound}}
  </div>
  <div class="text-center" v-else-if="isMore">
    <q-btn v-if="isListViewLesson" @click="getLessons(true)" round flat color="positive" icon="keyboard_arrow_down" />
    <q-btn v-else @click="getLessons(true)" class="glossy" size="lg" round color="primary" icon="keyboard_arrow_down" />
  </div>
  <div v-if="isLoading" class="text-center"><q-spinner-oval color="accent" size="48px"/></div>
</div>

</template>
<script>
import { ref } from 'vue';
import dayjs from 'dayjs'
import lessonExport from "./lessonExport.vue";
import lessonImport from "./lessonImport.vue";
import {saveAs} from "file-saver";
import XLSX from 'xlsx';
// import {messageObj} from "src/helpers/message";

export default {
    props:{
        parent:Object,
        lang:String,
        categoria:String,
        back:String
    },
    data: function () {
        return {
          isListViewLesson:false,
          file_download_link:'',
          file_download_loading:false,
          start:0,
          total:0,
          count:15,

          maxCount:3,
            goback:'',
            isImportLoading:false,
            isShowImport:ref(false),
            isLoading:false,
            // listLessons:[],
            pics_lessons:[],
            lesson_id:0,
            lesson_idx:-1,
            lastCategoria:-1,
            expFormat_selected:'xlsx',
            message:this.parent.dictionary,
            messageObj:this.parent.dictionary
        }
    },
    mounted: function(){
      console.log(':: mounted list of lessons');
      let $this=this;
      this.parent.userOption('get', 'isListViewLesson').then(res=>{
        this.isListView = res;  
      });
      $this.parent.isLoadingModule=false;
      $this.parent.checkPulse('lessons list').then(res=>{
        this.goback = this.back ? this.back : 'studentProfile';
        if(!this.parent.listLessons || !this.parent.justSearched && Object.keys(this.parent.listLessons).length === 0) {
          this.maxCount=this.parent.getMaxCountByTarif('lessons');
          this.getLessons();
        }

        this.parent.userOption('get', 'expformat').then(expFormat_selected =>{
          this.expFormat_selected = expFormat_selected ? expFormat_selected : "xlsx";
        });
        this.lastCategoria = this.categoria;
      }, err=>{
        this.$emit('goback', 'studentProfile');
      });

    },
    watch:{
      isListViewLesson:function(arg){
        this.parent.userOption('set', 'isListViewLesson', arg);
      },
        categoria:function(){
          console.log(':watch: categoria ',this.categoria, this.parent.categoria, this.lastCategoria)
            // if(this.lastCategoria !== -1 && this.lastCategoria != this.categoria) {
                this.getLessons();
            // }
            // this.lastCategoria = this.categoria;
        },
    },
    computed:{
      razdel(){
        return parseInt(this.parent.categoria) ? this.parent.categoria : ''
      },
      isMore(){
        return this.parent.listLessons && this.total && !this.isLoading && Object.keys(this.parent.listLessons).length >= this.count;
      },
      fltListLessons(){
        // console.log('::fltListLessons ',this.parent.listLessons)
          let ret=false;
          if(!this.parent.listLessons){
              ret=false;
          }else{
              ret=this.parent.listLessons.filter((lesson, index) => {
                  return lesson.ACTIVE === 'Y' || this.parent.isModer || this.parent.isAdmin;
              });
          }
          return ret;
      }

    },
    methods: {
        importLessons(tests){
            // console.log('::importTests ',tests);
          this.isShowImport = false;
          window.localStorage.setItem('lessons', '');
          window.localStorage.setItem('listLessons', '');
          this.parent.clearCountCache('lessons');
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.listLessons = null;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isLoading=false;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isImportLoading=false;
          window.pics_lessons='';
          this.getLessons();
        },
        importLessonTogle(){
          // console.log(':1:importLessonTogle this.isShowImport=', this.isShowImport)
            // this.isShowImport=!this.isShowImport;
            this.isImportLoading = true;
            this.parent.tarifAlert('lessons').then(isOk=>{
                this.isImportLoading = false;
                if(!isOk) {
                    console.error(':: Lessons Exceeded the tariff limit');
                }else{
                    this.isShowImport = true;// !this.isShowImport;
                }
            });
        },

      cloneExpLesson(lesson, back, list) {
          console.log(':1:cloneExpLesson ',lesson);
          let $this=this;
        if(this.parent.isJSON(lesson.data)){
          let item = JSON.parse(lesson.data);
          console.log(':2:cloneExpLesson ',item);
          let method = 'entityItemAdd';
          let params = {
            'ENTITY': 'uni_lessons',
            'PREVIEW_TEXT': item.PREVIEW_TEXT,
            'NAME': item.NAME+'_'+this.message[this.lang].copy,
            // 'DETAIL_TEXT': item.DETAIL_TEXT,
            'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
            'ACTIVE':'N',
            'DATE_ACTIVE_FROM':'',
            'PROPERTY_VALUES': item.PROPERTY_VALUES
          };
          
          var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
          $this.parent.bx24params.DETAIL_TEXT = item.DETAIL_TEXT;
          $this.parent.bx24params.DETAIL_PICTURE = item.DETAIL_PICTURE;
          $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
              $this.getLessons();
              // if (res.answer.result) {
              //   let lesson_id = res.answer.result;
              // }
            // }
          });
        }

      },
      saveExpLesson(lesson, back, list){
          // console.log('::saveExpLesson ',lesson);

            if(this.expFormat_selected === 'udata') {
                let fname = 'lesson_' + lesson.id + '.udata';
              // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
              //   let b64Data = btoa(unescape(encodeURIComponent(lesson.data)));
              //   this.file_download_loading=true;
              //   this.parent.getFileLinkPureB64(fname, btoa(b64Data)).then(link=>{
              //     console.log(':1: file_download_link=', this.file_download_link);
              //     this.file_download_link = link;
              //     this.file_download_loading=false;
              //   });
              // }else {
                let b64Data = btoa(unescape(encodeURIComponent(lesson.data)));
                saveAs(new Blob([this.parent.s2ab(b64Data)],
                  {type: "application/octet-stream"}), fname);
              // }
                if(back){
                    back(list);
                }
            }else{
                this.saveLessonAsXlsx(lesson, back, list);
            }
        },
        saveExpList(list){
            // console.log('::saveExpList ',list);
            if(list && Object.keys(list).length > 0){
                let lesson = list.pop();
              // console.log('::saveExpList lesson=',lesson);
                this.saveExpLesson(lesson, this.saveExpList, list);
            }
        },
        saveLessonAsXlsx(test, back, list){
          let forXlsx=[];
          let test_id = test.id;
          let data = JSON.parse(test.data);
          // console.log(':: saveTestAsXlsx test=', data);

          let header={
            A:'LESSON',
            B:this.message[this.lang].NAME,
            C:this.message[this.lang].longread,
            D:this.message[this.lang].less_logo,
          };
          forXlsx.push(header);

          let row = {
            A:'',
            B:data.NAME,
            C:data.DETAIL_TEXT,
            D:data.DETAIL_PICTURE
          };
          forXlsx.push(row);

          for(let key of Object.keys(data.PROPERTY_VALUES)){
            let item = data.PROPERTY_VALUES[key]

            let row = {
              A: key,
              B: item,
              C:"",
              D:""
            };
            if(key.startsWith('link') && this.parent.isJSON(item)){
              item=JSON.parse(item);
              row.B=item.label.trim();
              row.C=item.href.trim();
            }else if(key === 'youtube'){
              item=JSON.parse(item);
              row.B=item.id;
              row.C=item.provider;
            }
            if(row.B) {
              forXlsx.push(row);
            }
          }


          console.log(':: saveTestAsXlsx forXlsx=', forXlsx);

          let Head =[];
            Head.push([[]]);

            let ws = XLSX.utils.aoa_to_sheet(Head);
            let wscols = [
              {wch:10},
              {wch:40},
              {wch:40},
              {wch:40},
            ];
            ws['!cols'] = wscols;

            XLSX.utils.sheet_add_json(ws, forXlsx, {
                skipHeader:true,
                origin:0
            });

            let fname = 'lesson_'+test_id+'.xlsx';
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'lesson');
          // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
          //   let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'base64'});
          //   this.file_download_loading=true;
          //   this.parent.getFileLinkPureB64(fname, wbout).then(link=>{
          //     console.log(':2: file_download_link=', this.file_download_link);
          //     this.file_download_link = link;
          //     this.file_download_loading=false;
          //   });
          // }else {
            let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
            saveAs(new Blob([this.parent.s2ab(wbout)],
              {type: "application/octet-stream"}), fname);
          // }
            if(back){
                back(list);
            }
        },
        
        viewLesson(lesson){
          // eslint-disable-next-line vue/no-mutating-props
            this.parent.backScreen=this.parent.currentScreen;
            this.$emit('lessonView', lesson);
        },
        editLesson(lesson){
            window.pics_update_cache=true;
            this.$emit('lessonEdit', lesson);
        },
        // editCourse(course_id){
        //     console.log(':: editCourse: course_id=',course_id);
        //     this.$emit('courseEdit', {ID:course_id});
        // },
        showModalDel(lesson, index) {
          let $this=this;
            // Надо проверить все курсы и если в них есть этот урок вывести предупреждение со  списком курсов
            this.isLoading=true;
            this.parent.checkCourses(lesson.ID, 'lesson').then(res=>{
                this.isLoading=false;
                // console.log(':: courses: ',res);
                if(!res || Object.keys(res).length === 0){
                    this.lesson_id = lesson.ID;
                    this.lesson_idx = index;
                    // this.$refs['del-modal'].show();
                  $this.parent.modal_ok = null;
                  $this.parent.modal_cancel = null;
                  $this.parent.do_confirm = this.delLesson;
                  $this.parent.title_confirm = this.message[this.lang].remove+' '+this.message[this.lang].lesson;
                  $this.parent.message_confirm = this.message[this.lang].confirm_del +': '+
                        this.message[this.lang].lesson +'№'+ this.lesson_id+' '+ lesson.NAME;
                  $this.parent.confirm=true;

                }else{
                  $this.parent.notify_text = '';
                  for(let i in res){
                    $this.parent.notify_text += res[i].course_id+' '+res[i].course_name;
                  }
                  $this.parent.notify_title = this.message[this.lang].attention;
                  $this.parent.notify_ok = this.message[this.lang].alert_understood;
                  $this.parent.notify=true;

                }

            }, err=>console.error(err));
            //

        },
        delLesson(){
          let $this = this;
          window.localStorage.setItem('lessons', '');
          window.localStorage.setItem('listLessons', '');
          this.parent.clearCountCache('lessons');
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.confirm=false;
          console.log('::delLesson ',this.lesson_id, this.lesson_idx)
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.listLessons.splice(this.lesson_idx,1);
          // var $this = this;
          // this.isLoading=true;
          let params = {
            ENTITY: 'uni_lessons',
            ID: this.lesson_id
          }
            
          let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });//.then(params=> {
          $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                
            });
            // this.$emit('lessonEdit', lesson);
        },
        hideModalDel(){
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.confirm=false;
        },
        formatDate(item){
            let d = item.DATE_ACTIVE_FROM ? item.DATE_ACTIVE_FROM : item.DATE_CREATE;
            let fd = dayjs(d).format('DD.MM.YYYY');
            return fd;
        },
        addNewLesson(){
            window.pics_update_cache=true;
            this.$emit('lessonsList', 'add');
        },
        // fGoback(){
        //     this.$emit('lessonsList', 'back');
        // },
        getLessons(more){
          const $this = this;
          let cat = parseInt(this.parent.categoria) ? this.parent.categoria : '';
          this.maxCount=this.parent.getMaxCountByTarif('lessons');
          let filter = {};
          if(cat) {
            filter = {
              '1': {
                LOGIC: "AND",
                PREVIEW_TEXT: '' + cat
              }
            };
          }
          if(more===true){
            let last_id = 9999999;
            if($this.parent.listLessons && Object.keys($this.parent.listLessons).length > 0){
              last_id = $this.parent.listLessons[Object.keys($this.parent.listLessons).length-1].ID;
            }
            if(!filter['1']){
              filter['1']={};
            }
            filter['1']['<ID'] = last_id;
          }else{
            // eslint-disable-next-line vue/no-mutating-props
            $this.parent.listLessons=[];
          }
          if(!this.parent.isModer && !this.parent.isAdmin){
            filter['1'].ACTIVE = 'Y';
          }
          // console.log('::getLessons more,filter=',more, filter);
          this.isLoading=true;
          let params= {
            ENTITY: 'uni_lessons',
            SORT: {ID: 'DESC'},
            FILTER: filter
          };
          let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
          $this.parent.b24POST(url, $this.parent.bx24params, function (result) {
              $this.isLoading=false;
              let arResult = result.result;//.data();
              // console.log($this.count+'::getLessons arResult=', arResult);
              let isQuota = false;
              $this.total = false;
              if(arResult) {
                for (let i in arResult) {
                  let lesson = arResult[i];

                  $this.total = true;
                  isQuota = $this.maxCount > Object.keys($this.parent.listLessons).length;

                  // console.log(parseInt(i), $this.count, $this.maxCount, Object.keys($this.parent.listLessons).length);

                  if(isQuota && parseInt(i)<$this.count) {
                    // console.log(parseInt(i)+') getLessons lesson=', lesson);
                    // eslint-disable-next-line vue/no-mutating-props
                      $this.parent.listLessons.push({
                        ID: lesson.ID,
                        PREVIEW_TEXT: lesson.PREVIEW_TEXT,
                        NAME: lesson.NAME,
                        ACTIVE: lesson.ACTIVE,
                        PROPERTY_VALUES: lesson.PROPERTY_VALUES,
                        DETAIL_TEXT: lesson.DETAIL_TEXT,
                        DETAIL_PICTURE: lesson.DETAIL_PICTURE,
                        DATE_ACTIVE_FROM: lesson.DATE_ACTIVE_FROM,
                        DATE_CREATE: lesson.DATE_CREATE
                      });
                  }else{
                      break;
                  }
                }
                // console.log('::getLessons ', $this.parent.listLessons)
              }

          });
        },
    },
    components: {
        lessonExport,
        lessonImport
    }
}
</script>

<style scoped>


 </style>

