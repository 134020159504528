<template>
<div>
  <div class="row items-center text-positive q-my-md">
    <div class="col-md-2 col-xs-12 h4 ">{{message[lang].ipr_courses}}</div>
    <div class="col-md-8 col-xs-12">
    <q-select
      multiple
      v-model="model"
      use-input
      hide-selected
      input-debounce="1000"
      :label="message[lang].course_name"
      :options="options"
      option-value="value"
      option-label="label"
      map-options
      @filter="filterFn"
      @filter-abort="abortFilterFn"
      :hint="message[lang].enter_course_name"
      :disable="!access || access=='R'"
      virtual-scroll-slice-size="10"
      @update:model-value="save(model)"

    >
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-positive">
            {{message[lang].no_results}}
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
  </div>
  <!-- Статусы курсов по аналогии с РМ -->
  <div v-if="model && model.length>0">
    <div v-if="!parent.isMobile" class="row items-center text-positive h4">
      <div class="col-5">{{message[lang].name}}</div>
      <div class="col-3">{{message[lang].status}}</div>
      <div class="col-3">{{message[lang].rm_deadline}}</div>
    </div>
    <div class="row items-center" v-for="(course,ind_course) in model" :key="ind_course">
      <div class="col-md-5 col-xs-12 text-left text-accent cursor-pointer underline-on-hover" @click="goCourse(course.value)">
        {{course.label}}
        <q-tooltip class="bg-transparent text-primary" :offset="[1, 1]">
          {{message[lang].go_course}}
        </q-tooltip>
      </div>
      <div class="col-md-3 col-xs-12">
        <q-btn-dropdown size="sm" dense flat rounded color="primary" :label="showStatusCourse(course)" :disable="!access || access=='R'">
          <q-list>
            <q-item clickable v-close-popup @click="statusCourse(index, course, 'new')">
              <q-item-section>
                <q-item-label>{{message[lang].rm_new}}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="statusCourse(index, course, 'started')">
              <q-item-section>
                <q-item-label>{{message[lang].rm_started}}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="statusCourse(index, course, 'compleeted')">
              <q-item-section>
                <q-item-label>{{message[lang].rm_compleeted}}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
      <div class="col-md-3 col-xs-11">
        <!-- Крайний срок  -->
        <q-input dense
                 v-model="course.dln"
                 @update:model-value="save(model)"
                 :hint="message[lang].rm_deadline"
                 :readonly="!access || access=='R'"
        >
          <template v-slot:prepend>
            <q-icon name="event" :class="access && access=='W'?'cursor-pointer':'text-positive'">
              <q-popup-proxy v-if="access && access=='W'" cover transition-show="scale" transition-hide="scale">
                <q-date
                  v-model="course.dln"
                  mask="YYYY-MM-DD HH:mm"
                  :locale="parent.appRegion === 'ru' ? parent.appLocale.ru : ''"
                  @update:model-value="save(model)"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup :label="message[lang].em_close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>

          <template v-slot:append v-if="access && access=='W'">
            <q-icon name="access_time" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-time v-model="course.dln" mask="YYYY-MM-DD HH:mm" format24h :locale="parent.appRegion === 'ru' ? parent.appLocale.ru : ''"
                        @update:model-value="save(model)"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup :label="message[lang].em_close" color="primary" flat />
                  </div>
                </q-time>
              </q-popup-proxy>
            </q-icon>
          </template>
          <q-badge v-if="isRmdln(course.dln)"
                   rounded floating
                   color="warning"
                   :label="message[lang].rm_dln"/>
        </q-input>

      </div>
      <div class="col-1">
        <q-btn size="sm"  flat round color="accent" @click="delCourse(ind_course)" icon="clear" :disable="!access || access=='R'">
          <q-tooltip class="bg-transparent text-warning" :offset="[5, 5]">
            {{message[lang].del}}
          </q-tooltip>
        </q-btn>
      </div>

      </div>
  </div>

</div>
</template>

<script>
import { ref } from 'vue';
// import {messageObj} from "src/helpers/message";
import dayjs from "dayjs";

export default {
  name: "CourseSelect",
  props:{
    parent:Object,
    lang:String,
    index:Number,
    c_index:Number,
    ind_index:Number,
    courses:Object,
    access:String,
  },
  data: function () {
    return {
      options: ref([]),
      model: ref(null),
      message: this.parent.dictionary//messageObj,
    }
  },
  watch:{
    // model(arg){
    //   this.save(arg)
    // }
  },
  mounted() {
    // console.log('::mounted this.courses=',this.courses)
    if(this.courses && this.courses.length>0){
      this.model = this.courses;
      // console.log('::mounted this.options=',this.options)
    }
  },
  methods: {
    isRmdln(deadline){
      if(!deadline) return false;
      let dln = dayjs(deadline).unix();
      let now = dayjs().unix();
      // console.log('*** ',now,'>',dln, now>dln);
      return now>dln;
    },
    save(arg){
      // console.log(':: save arg=',arg)
      this.$emit('course_saved', {index: this.index, c_index:this.c_index, ind_index:this.ind_index, courses:arg});
    },
    delCourse(ind_course){
      let $this=this;
      $this.parent.do_confirm = ()=>{
        // console.log(':: delCourse ind_course=',this.model[ind_course])
        $this.model.splice(ind_course,1);
        $this.save($this.model);
        $this.parent.confirm=false;
      };
      $this.parent.title_confirm = this.message[this.lang].remove;
      $this.parent.message_confirm = this.message[this.lang].remove +' "'+this.model[ind_course].label+'"';
      $this.parent.confirm=true;
    },
    showStatusCourse(course){
      let ret=this.message[this.lang].rm_status;
      if(course.status){
        return this.message[this.lang]['rm_'+course.status];
      }
      return ret;
    },
    statusCourse(index, course, status){
      course.status=status;
      // console.log('::statusRm course=',course);
      this.save(this.model);
    },
    goCourse(id){
      let $this=this;
      // console.log('::goCourse id=',id);
      this.parent.getCourseById(id).then(course=>{
        // console.log('::goCourse course=',course);
        $this.parent.backScreen=this.parent.currentScreen;
        let arg={course:course, back:'ipr'}
        this.$emit('courseView', arg);
      });
    },
    filterFn (val, update, abort) {
      let $this=this;
      // call abort() at any time if you can't retrieve data somehow
      // console.log('::filterFn val=',val)
      if(val) {
        let params = {
          'ENTITY': 'uni_courses',
          'SORT': {},
          'FILTER': {
            '1':{
              LOGIC:"AND",
              '%NAME': `%${val}%`
            }
          }
        };
        // console.log('::filterFn params=',params)

        // BX24.callMethod('entity.item.get', {
        //   'ENTITY': 'uni_courses',
        //   'SORT': {},
        //   'FILTER': {
        //     'NAME': `%${val}%`
        //   }
        //
        // }, function (result) {
        //   // console.log('::get courses res=', result)
        //   let list=[];
        //   if(result.error()){
        //     console.error(result.error());
        //   }else{

        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          let list=[];
          let arResult = res.result;
            for(let item of arResult){
              list.push(
                {
                  value: item.ID,
                  label: item.NAME
                });
            }
          // }

          update(() => {
            console.log('::update list=',list)
            $this.options = list;
          })
        });
      }
    },
    abortFilterFn () {
      console.log('::abortFilterFn')
    }
  }
}
</script>

<style scoped>
.underline-on-hover:hover {
  text-decoration: underline;
}
</style>
