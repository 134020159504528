<template>
  <div class="component_padding">
      <div class="row items-center justify-between q-mb-md">
        <div class="col-3 text-left q-mb-2">
          <div class="h3 text-positive">
              {{message[lang].sub_accounts}}
              <span v-if="tarif_valid!==null" :class="'h4 '+(tarif_valid?'text-primary':'text-warning')">{{total}}/{{limit}}</span>
              <q-btn  icon="add" flat round color="positive" @click="nativeAccountAdd">
                <q-tooltip class="bg-primary h-tt">
                    {{message[lang].native_add}}
                </q-tooltip>
              </q-btn>
          </div>
        </div>
        <div  class="col-8 text-center">
          <span v-if="!tarif_valid && tarif_valid!==null" class="text-warning">{{message[lang].sub_limit_exceeded}}</span>
        </div>
      </div>

    <div class="q-pr-md">
      <q-card class="cat-card">
        <q-card-section>
          <q-expansion-item default-opened icon="info" :label="message[lang].sub_important_info">
            <div v-html="message[lang].sub_info"/>
            </q-expansion-item>
        </q-card-section>
        <q-card-actions align="left">
          <!-- Саморегистрация -->
          <q-toggle
            v-model="flags.self_sign_up"
            color="primary"
            :label="message[lang].self_sign_up"
            @update:model-value="saveAccountList"
            false-value="N"
            true-value="Y"
          />
          <q-space/>
          <!-- <div class="q-ml-md h5 text-accent" style="max-width: 800px;"><div v-html="message[lang].sub_info"></div></div> -->

        </q-card-actions>
        <q-card-section>
          <q-list separator v-if="account_list && account_list.length">
              <q-expansion-item v-for="(item,index) in account_list"
                  group="somegroup"
                  :key="index"
                  clickable
                  >
                  <template v-slot:header>
                    <q-item-section @click="loadSubAccaounts(item)">
                        <span :class="item.ACTIVE==='Y' ? 'text-primary' : 'text-primary'">{{item.text}}</span>
                    </q-item-section>
                    <q-item-section side v-if="parent.isAdmin">
                        <q-btn  flat round icon="clear" color="positive" @click="accountRemove(item, index)"/>
                    </q-item-section>
                  </template>
                  <template v-slot:default>
                    <div class="text-subtitle2">
                      {{message[lang].sub_accounts}}
                      <q-btn :loading="item.loading" :disabled="tarif_valid!==null && !tarif_valid" icon="add" flat round color="positive" @click="subAccountAdd(item)">
                        <q-tooltip class="bg-primary h-tt">
                            <span v-if="!tarif_valid && tarif_valid!==null">{{message[lang].sub_limit_exceeded}}}</span>
                            <span>{{message[lang].sub_add}}</span>
                        </q-tooltip>
                      </q-btn>
                       <span class="text-primary h5">
                          <q-icon name="info" />
                          {{ message[lang].subac_validate_info }}
                        </span>
                    </div>
                    <div v-if="item.subs">
                      <div class="row items-center">
                        <div class="col-2 text-accent">
                          {{message[lang].sub_acc_login}}
                        </div>
                        <div class="col-2 text-accent">
                            {{message[lang].sub_acc_first_name}}
                        </div>
                        <div class="col-2 text-accent">
                            {{message[lang].sub_acc_middle_name}}
                        </div>
                        <div class="col-2 text-accent">
                            {{message[lang].sub_acc_last_name}}
                        </div>
                        <div class="col-2 text-accent">
                            {{message[lang].sub_acc_position}}
                        </div>
                      </div>
                      <q-separator />
                      <div class="row items-center" v-for="(subItem,subIndex) in item.subs" :key="subIndex">
                        <div class="col-2">
                          <q-input v-model="subItem.login" dense :filled="!subItem.login"/>
                        </div>
                        <div class="col-2">
                            <q-input v-model="subItem.first_name" dense :filled="!subItem.login"/>
                        </div>
                        <div class="col-2">
                            <q-input v-model="subItem.middle_name" dense :filled="!subItem.login"/>
                        </div>
                        <div class="col-2">
                            <q-input v-model="subItem.last_name" dense :filled="!subItem.login"/>
                        </div>
                        <div class="col-2">
                            <q-input v-model="subItem.position" dense :filled="!subItem.login"/>
                        </div>
                        <div class="col-2">
                          <q-btn :disabled="!isSubacLoginValid(subItem.login)" :loading="subItem.loading" round flat icon="done" color="accent" @click="saveSubAccount(subItem)">
                              <q-tooltip class="bg-primary h-tt">{{ message[lang].sub_acc_save }}</q-tooltip>
                            </q-btn>
                            <q-btn :disabled="!isSubacLoginValid(subItem.login) || !subItem.ID" :loading="subItem.loading" round flat icon="password" color="positive" @click="clearPassword(subItem)">
                              <q-tooltip class="bg-primary h-tt">{{ message[lang].del_subpassw_message }}</q-tooltip>
                            </q-btn>
                            <q-btn  round flat icon="clear" color="positive" @click="delSubAccount(subItem, subIndex, item)">
                              <q-tooltip class="bg-primary h-tt">{{ message[lang].del_subac_title }}</q-tooltip>
                            </q-btn>
                        </div>
                      </div>
                    </div>


                  </template>

              </q-expansion-item>
          </q-list>
        </q-card-section>
      </q-card>
    </div>

    <q-inner-loading :showing="isLoading">
      <q-spinner-oval size="5em" color="positive" />
    </q-inner-loading>
  </div>
</template>

<script>
import { ref } from 'vue';
// import {messageObj} from "src/helpers/message";

export default {
  name: "SubAccounts",
  props:{
    parent:Object,
    lang:String,
  },

  data() {
    return {
      account_list: [],
      flags: {self_sign_up:'N'},
      isLoading: true,
      limit: 3,
      total: null,
      message: this.parent.dictionary,
    }
  },
  computed: {
    tarif_valid(){
      console.log('::tarif_valid =', this.limit, this.total);
      return this.total===null? null : this.total < this.limit;
    }
  },
  mounted() {
    // this.parent.getSubacTarif().then(res=>{
      this.parent.isSubacTarifValid().then(res=>{
        if(res){
          this.limit = res.limit;
          this.total = res.total;
        }
        console.log('::isSubacTarifValid res=', res);
        this.loadAccountList();
      })
    // })
  },
  methods: {
    isSubacLoginValid(str){
      // Регулярное выражение для проверки латинских букв и цифр
      const regex = /^[a-zA-Z0-9]+$/;
      return regex.test(str);
    },

    loadSubAccaounts(item){
      const $this = this;
      item.loading = true;
      let params = {
            ENTITY: 'uni_subaccounts',
            FILTER: {STUFF: item.value},
        };
        let url =  this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });
        this.parent.b24POST(url, this.parent.bx24params, function (res) {
          // console.log(':loadSubAccaounts =',res);
          item.subs = [];
          if(res && res.result){
            for(let i in res.result){
              let acc = res.result[i];
              let subItem =   $this.parent.isJSON(acc.DETAIL_TEXT) ? JSON.parse(acc.DETAIL_TEXT) : {};
              subItem.login = acc.NAME;
              subItem.native_id = acc.STUFF;
              subItem.ID = acc.ID;
              //
              item.subs.push(subItem);
            }
            item.loading = false;
          }
          console.log(':loadSubAccaounts item.subs=',item.subs);
        });
    },
    delSubAccount(subItem, subIndex, item){
      let $this=this;

      $this.parent.title_confirm = this.message[this.lang].del_subac_title;
      $this.parent.message_confirm = this.message[this.lang].del_subac_message;

      $this.parent.do_confirm = function () {
        $this.doDelSubAccount(subItem, subIndex);
        item.subs.splice(subIndex, 1);
        $this.total --;
        $this.parent.confirm=false;
      };

      $this.parent.confirm=true;

    },
    doDelSubAccount(subItem, subIndex){
      let params = {
          'ENTITY': 'uni_subaccounts',
          'ID': subItem.ID,
      };
      let url = this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });
      this.parent.b24POST(url, this.parent.bx24params, function (res) {
        console.log('::dodelSubAccount res=', res);
      });
    },
    getLogin(login){
      const $this = this;
      return new Promise(function (resolve, reject) {
        let params = {
            ENTITY: 'uni_subaccounts',
            FILTER: {NAME: login},
        };
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          resolve(res)
        });

      });

    },
    clearPassword(subItem){
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].del_subpassw_title;
      $this.parent.message_confirm = this.message[this.lang].del_subpassw_message;
      $this.parent.confirm=true;
      $this.parent.do_confirm = function () {
          let params = {
              'ENTITY': 'uni_subaccounts',
              'NAME': subItem.login,
              'PREVIEW_TEXT':'',
              'ID': subItem.ID
          };
          let url = $this.parent.bx24Init({ AJAX_ACTION: 'entityItemUpdate', params:params });
          $this.parent.POST(url, $this.parent.bx24params, function (res) {
            console.log('::doSaveAccount res=', res);
            $this.parent.confirm=false;
          });
      };
    },
    saveSubAccount(subItem){
      console.log(':saveSubAccount =',subItem);
      let $this = this;
      subItem.loading = true;
      this.getLogin(subItem.login).then((res)=>{
        if(res && res.result && res.result[0] && subItem.ID!=res.result[0].ID){
          subItem.loading = false;
          //Есть такой логин
          $this.parent.notify_title = this.message[this.lang].attention;
          $this.parent.notify_text=this.message[this.lang].sub_login_already;
          $this.parent.notify = true;
          setTimeout(() => {
            subItem.login = '';
            $this.parent.notify = false;
          }, 5000);
        }else{
          let recvisits = {
            'first_name':this.parent.replaceQuotes(subItem.first_name),
            'middle_name':this.parent.replaceQuotes(subItem.middle_name),
            'last_name':this.parent.replaceQuotes(subItem.last_name),
            'position':this.parent.replaceQuotes(subItem.position),
          };

          let method = subItem.ID?'entityItemUpdate':'entityItemAdd';
          let params = {
              'ENTITY': 'uni_subaccounts',
              'NAME': subItem.login,
              'DETAIL_TEXT': JSON.stringify(recvisits),
              'ACTIVE': 'Y',
              'STUFF': subItem.native_id
          };
          if(subItem.ID){
              params['ID'] = subItem.ID;
          }

          let url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
          $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
            console.log('::doSaveAccount res=', res);
            if(!subItem.ID){
                $this.total++;
                subItem.ID = parseInt(res.result);
            }
            subItem.loading = false;
          });
        }
      });
    },
    subAccountAdd(item){
      item.subs = item.subs?item.subs:[];
      item.subs.splice(0,0,{login:'', first_name:'', last_name:'', middle_name:'', position:'', native_id:item.value});
    },
    saveAccountList(){
      console.log(':saveAccountList =',this.account_list, this.flags);
      let arIds=this.account_list.map(item => item.value);
      this.parent.appOptionSet('subaccounts', JSON.stringify({ids:arIds, flags:this.flags}));
    },
    loadAccountList(){
      let subaccounts=this.parent.appOptionGet('subaccounts').then(data=>{
        let subaccounts = '';
        if(this.parent.isJSON(data.DETAIL_TEXT)){
          subaccounts = JSON.parse(data.DETAIL_TEXT)
        }
        console.log(':loadAccountList subaccounts=',subaccounts);
        if(subaccounts){
          // subaccounts = JSON.parse(subaccounts);
          let arIds = subaccounts.ids;

          this.flags = subaccounts.flags ? subaccounts.flags : {self_sign_up:'N'};
          if(arIds && arIds.length){
            this.account_list=[];
            let arBatch = {};
            for(let id of arIds){
              arBatch['user_' + id] = ['entityItemGet', 
                    {ENTITY: 'uni_profile', "NAME": id}];
            }
            this.parent.callBatchIB(arBatch).then(res=>{
              console.log(':callBatch: res=',res) 
              for(let i in res.result){
                let item=res.result[i][0];
                if(item)
                  this.account_list.push({value:item.ID, text:`${item.NAME} ${item.LAST_NAME||''}`, ACTIVE:item.ACTIVE});
              }
              this.isLoading = false;
            });

          }else{
            this.isLoading = false;
          }
        }else{
          this.isLoading = false;
        }
      });
    },  

    // loadAccountList(){
    //   let subaccounts=this.parent.appOptionGet('subaccounts').then(data=>{
    //     let subaccounts = '';
    //     if(this.parent.isJSON(data.DETAIL_TEXT)){
    //       subaccounts = JSON.parse(data.DETAIL_TEXT)
    //     }
    //     console.log(':loadAccountList subaccounts=',subaccounts);
    //     if(subaccounts){
    //       // subaccounts = JSON.parse(subaccounts);
    //       let arIds = subaccounts.ids;

    //       this.flags = subaccounts.flags ? subaccounts.flags : {self_sign_up:'N'};
    //       if(arIds && arIds.length){
    //         this.account_list=[];
    //         let arBatch = {};
    //         for(let id of arIds){
    //           arBatch['user_' + id] = ['entityItemGet', 
    //                 {ENTITY: 'uni_subaccounts', "ID": id}];
    //         }
    //         this.parent.callBatchIB(arBatch).then(res=>{
    //           console.log(':callBatch: res=',res) 
    //           for(let i in res.result){
    //             let item=res.result[i][0];
    //             if(item)
    //               this.account_list.push({value:item.ID, text:`${item.NAME} ${item.LAST_NAME||''}`, ACTIVE:item.ACTIVE});
    //           }
    //           this.isLoading = false;
    //         });
    //       }else{
    //         this.isLoading = false;
    //       }
    //     }else{
    //       this.isLoading = false;
    //     }
    //   });
      
    // },

    nativeAccountAdd(){
      let $this = this;
      $this.parent.modal_list=[];
      $this.isStudentsLoading=true;
      $this.parent.model=null;
      this.parent.getUsersExtra('activeOnly').then(users=>{
        $this.isStudentsLoading=false;
        $this.parent.modal_list=[];
        for(let i in users) {
          let item = users[i];
          if(item.name){
            $this.parent.modal_list.push(
                {text:item.name, value: item.id}
            );
          }
        }
        $this.parent.model=null;
        $this.parent.modal_flt='';
        $this.parent.modal_list_single=false;
        $this.parent.modal_list_label = $this.message[$this.lang].selectusers_label;
        $this.parent.modal_title = $this.message[$this.lang].selectusers_title;
        $this.parent.modal_message = $this.message[$this.lang].selectuser_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;
        //Поиск юзера по порталу
        $this.parent.modal_filterFn=$this.parent.extraFilterFn
        // $this.parent.modal_filterFn= function (needle){
        //   return new Promise(resolve => {
        //       let filter ={
        //       "%PROPERTY_last_name":`%${needle}%`,
        //       ACTIVE:'Y'
        //       };
        //       $this.parent.getUsersExtra('activeOnly', filter).then(users=>{
        //       let list = [];
        //       if(users && users.length>0) {
        //           for (let i in users) {
        //           let item = users[i];
        //           list.push({text:item.name, value: item.id});
        //           }
        //       }else{
        //           list.push({text: $this.parent.message[$this.lang].not_found, value:0, disable:true});
        //       }
        //       console.log(':: list=',list)
        //       resolve(list);
        //       })
        //   });
        // };
        $this.parent.okModal =  ()=> {
          console.log('::okModal', $this.parent.modal_list_selected)
          for(let i in $this.parent.modal_list_selected ) {
              let user_id = parseInt($this.parent.modal_list_selected[i]);
              let itemUser = $this.parent.modal_list.find(el => { return el.value == user_id});

              if (itemUser && !$this.account_list.find(el => {return el.value == user_id})) {
                $this.account_list.push(itemUser);
              }
          }
          $this.saveAccountList();
          $this.parent.hideModal();
        };
        this.parent.showModal();

    });
  },
    accountRemove(account, index){
      let $this = this;
      console.log('::accountRemove index,account=',index,account);
      
      $this.parent.title_confirm = this.message[this.lang].del_sub_title;
      $this.parent.message_confirm = this.message[this.lang].del_sub_message;
      $this.parent.confirm=true;
      $this.parent.do_confirm = function () {
        if(account.subs && account.subs.length){
          //удаляем субаккаунты
          let arBatch = {};
          for(let item of account.subs){
            arBatch['sub_' + item.ID] = ['entityItemDel',
                {ENTITY: 'uni_subaccounts', "ID": item.ID}];
          }
          if(Object.keys(arBatch).length>0) {
            $this.parent.callBatchIB(arBatch).then(result => {
              console.log('::accountRemove subs result=',result);
                //удаляем аккаунт
                $this.account_list.splice(index,1);
                $this.saveAccountList();
                $this.parent.confirm=false;
            });
          }
        }else{
            //удаляем аккаунт
            $this.account_list.splice(index,1);
            $this.saveAccountList();
            $this.parent.confirm=false;
          }
      };
    },


  },
  components: {

  }

}
</script>

<style scoped>
</style>
