<template>
  <div>
    <q-file
      clearable
      v-model="listFiles"
      :label="message[lang].uploadsubtitle"
      :hint="message[lang].uploadtitle"
      @update:model-value="fileListChange"
      accept=".udata, .xls, .xlsx, application/vnd.ms-excel"
      borderless
      multiple
      use-chips
      class="bg-negative"
      style="padding-left:15px; border-radius: 25px !important;"
    >
      <template v-slot:prepend>
        <q-img src="img/secondary/doc/file.svg" style="height: 40px; width: 40px;"/>
      </template>
      <template v-slot:after v-if="listFiles && Object.keys(listFiles).length>0">
        <q-btn
          color="primary"
          dense
          icon="cloud_upload"
          round
          @click="upload"
          :disable="!listFiles||Object.keys(listFiles).length==0"
          :loading="isLoading"
        />
      </template>
    </q-file>
    <div class="row justify-between">
      <div class="col-4 positive h5">
        <q-toggle
          v-model="set_published"
          color="primary"
          :label="message[lang].set_published"
        />
      </div>
      <div class="col-6 text-right">
        <a class="text-positive body-2" target="_blank" :href="'./assets/test_import_example_'+lang+'.xlsx'">{{message[lang].obrazec_xlsx}}</a>
      </div>
    </div>

    <q-dialog v-model="maxSizePropsAlert">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{message[lang].attention}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          {{message[lang].tooo_many_quests}}
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="message[lang].my_understud" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>

</template>
<script>
// import fileUpload from './FileUpload.vue';
import XLSX from 'xlsx';
import dayjs from "dayjs";

export default {
    props:{
      lang:String,
      parent:Object
    },
    data: function () {
        return {
          set_published:false,
          countLoaded:0,
          maxCount:100000,
          alertMessage:'',
          isLoading:false,
          listFiles:'',
          maxSizePropsAlert:false,
          message:this.parent.dictionary,
        }
    },
    mounted: function(){
      // console.log(':testimport: mounted props=', this.lang, this.uploadtitle, this.uploadsubtitle)
      this.countLoaded=0;
        // this.maxCount=this.parent.parent.getMaxCountByCache('tests');
        this.maxCount=this.parent.getMaxCountByTarif();
    },
     watch:{
        // lang: function(){
        //     console.log(':: lang',lang);
        // },
    },
    methods: {
      onRejectedOne (rejectedEntries) {
        console.log('::onRejectedOne ', rejectedEntries)
        if(rejectedEntries[0]) {

          // eslint-disable-next-line vue/no-mutating-props
          this.parent.notify_text='';
          for(let item of rejectedEntries){
            if(item.failedPropValidation == 'max-file-size') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].max_file_size
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify = true;
            }else if(item.failedPropValidation == 'accept') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].accept
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify = true;
            }
          }


        }
      },
        fileListChange(listFiles){
            // console.log(':: fileListChange', listFiles);
            this.listFiles = listFiles;
        },
        upload(){
            // console.log(':: upload', this.listFiles);
          // eslint-disable-next-line vue/no-mutating-props
          this.isLoading=true;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isShowImport=false;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isImportLoading=true;

          if(Object.keys(this.listFiles).length > 0){
            let item=this.listFiles.pop();
            this.parent.readFileFromDisk(item).then(data=> {
                // console.log(':: item=',item);
                let b64data = data.data.substr(data.data.indexOf('base64,') + 7);
                let b64data2 = atob(b64data);
                if(item.name.indexOf('.xls') !== -1){
                    let workbook = XLSX.read(b64data2, {type:"binary"});
                    // console.log(':: workbook=', workbook);
                    let first_sheet_name = workbook.SheetNames[0];
                    /* Get worksheet */
                    let worksheet = workbook.Sheets[first_sheet_name];
                    let test={DETAIL_TEXT:'', PROPERTY_VALUES:{}};
                    let test_blocks=[];
                    let item_blocks={};
                    let tests=[];
                    let questions=[];
                    let quest_id=0;
                    let answ_id=0;
                    let answers={};
                    let row_type='';
                    let last_value='';
                  let last_type='';
                  let last_weight='';
                  let last_block='';
                  let isEnd=false;
                    let emptyNums=0;
                    let columns=["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
                    for(let j=1; j<10010; j++){
                        if(isEnd){
                            break;
                        }
                        for (let i in columns){
                            let col=columns[i];
                            let row=j;
                            let address_of_cell = col+row;
                            let cell = worksheet[address_of_cell];
                            /* Get the value */
                            let value = (cell ? cell.v : undefined);
                            // console.log(':'+address_of_cell+': value=|%s|', value);

                            if(col === 'A' && value && value.startsWith('#')){
                                //это комментарий
                                break;
                            }else if(col === 'A' && value==='TEST'){
                                if(test.NAME) {
                                    // test.PROPERTY_VALUES.questions = JSON.stringify(questions);
                                    // test.PROPERTY_VALUES.answers = JSON.stringify(answers);
                                  test.DETAIL_TEXT = JSON.stringify({questions:questions, answers:answers});
                                    //
                                    if(this.countLoaded < this.maxCount) {
                                        tests.push(test);
                                        this.countLoaded++;
                                    }else{
                                        isEnd=true;
                                        break;
                                    }
                                }
                                row_type='test';
                                test={DETAIL_TEXT:'', PROPERTY_VALUES:{}};
                                questions=[];
                                answers={};
                                emptyNums = 0;
                                break;
                            }else if(col === 'A' && value==='QUEST'){
                              row_type='qusetion';
                              quest_id ++;
                              emptyNums = 0;
                              break;
                            }else if(col === 'A' && value==='BLOCKS'){
                              row_type='blocks';
                              emptyNums = 0;
                              break;
                            }else if(col === 'A' && value==='ANSW'){
                                row_type='answers';
                                answ_id = 0;
                                answers[quest_id]=[];
                                break;
                            }
                            else if(col === 'B' && !value){
                                emptyNums ++;
                                if(emptyNums > 5 || row >10000) {
                                    isEnd = true;
                                    if(test.NAME) {
                                        // test.PROPERTY_VALUES.questions = JSON.stringify(questions);
                                        // test.PROPERTY_VALUES.answers = JSON.stringify(answers);
                                      test.DETAIL_TEXT = JSON.stringify({questions:questions, answers:answers});

                                      if(this.countLoaded < this.maxCount) {
                                            tests.push(test);
                                            this.countLoaded++;
                                        }else{
                                            isEnd=true;
                                            break;
                                        }
                                    }

                                }
                                break;
                            }
                            // console.log(':'+address_of_cell+': row_type=', row_type);
                            if(row_type === 'blocks'){
                              // console.log(':'+address_of_cell+': value=', value, test_blocks);
                              if(col === 'B'){
                                item_blocks ={name:value};
                              }else if(col === 'C'){
                                item_blocks.id = value;
                              }else if(col === 'D'){
                                item_blocks.count = value;
                              }else if(col === 'E'){
                                item_blocks.rand = value === true || value === 'Y';
                                test_blocks.push(JSON.parse(JSON.stringify(item_blocks)));
                              }
                            }
                            else if(row_type === 'test'){
                                if(col === 'B'){
                                    test.NAME = value;
                                }else if(col === 'C'){
                                    test.PROPERTY_VALUES.thematics = value;
                                }else if(col === 'D'){
                                    test.PROPERTY_VALUES.description = value;
                                }else if(col === 'E'){
                                    test.PROPERTY_VALUES.interval = value?parseInt(value):0;
                                }else if(col === 'F'){
                                    test.PROPERTY_VALUES.todisplay = value;
                                }else if(col === 'G'){
                                    let level=this.message[this.lang].level_options.find((element, index, array)=>{ if(element.text.toLowerCase() === value.toLowerCase()) return true; else return false }, this);
                                    level = level ? level : value;
                                    test.PROPERTY_VALUES.level = level.value;
                                }else if(col === 'H'){
                                    test.PROPERTY_VALUES.score = value;
                                }else if(col === 'I'){
                                    test.PROPERTY_VALUES.time = value;
                                }else if(col === 'J'){
                                    let type=this.message[this.lang].typeTest_options.find((element, index, array)=>{ if(element.text.toLowerCase() === value.toLowerCase()) return true; else return false }, this);
                                    type = type ? type : value;
                                    test.PROPERTY_VALUES.type = type.value;
                                }else if(col === 'K'){
                                  let show_results = this.parent.message[this.lang].show_results_opt.find((element, index, array) => {
                                    if (element.text == value) { return true; } else { return false; }
                                  }, this);
                                  test.PROPERTY_VALUES.show_results = show_results?show_results.value:'0';
                                }else if(col === 'L'){
                                  let no_prev = this.message[this.lang].YesNo.find((element, index, array) => {
                                    if (element.text == value) { return true; } else { return false; }
                                  }, this);
                                  test.PROPERTY_VALUES.no_prev = no_prev?no_prev.value:'N';
                                }else if(col === 'M') {
                                  test.DETAIL_PICTURE = value;
                                }else if(col === 'N') {
                                  test.PROPERTY_VALUES.flags = value;
                                }

                            }else if(row_type === 'qusetion') {
                                if (col === 'B' && value) {
                                    last_value=value;
                                }else if (col === 'C' && last_value) {
                                    value = value ? value : 'Выбор';
                                    let type = this.message[this.lang].typeQuest_options.find((element, index, array) => {
                                        if (element.text.toLowerCase() === value.toLowerCase()) return true; else return false
                                    }, this);
                                    if(!type){
                                        this.alertMessage += this.message[this.lang].no_type_quest+' ';
                                    }
                                    type = type ? type.value : value;
                                    // questions.push({value: quest_id, text: last_value, type: type});
                                    last_type=type;
                                }else if (col === 'D') {
                                  last_weight=value ? value : 0;
                                  // questions.push({value: quest_id, text: last_value, type: last_type, weight:last_weight});
                                  // break;
                                }else if (col === 'E') {
                                  last_block=value ? value : '';
                                  questions.push({value: quest_id, text: last_value, type: last_type, weight:last_weight, block:last_block});
                                  if(test_blocks && test_blocks.length>0) {
                                    test.PROPERTY_VALUES.blocks = JSON.stringify(test_blocks);
                                  }
                                  break;
                                }
                            }else if(row_type === 'answers') {
                                if (col === 'B'  && value) {
                                    answ_id ++;
                                    last_value=value;
                                }else if (col === 'C' && last_value && last_type) {
                                    let item={value: answ_id, text: last_value};
                                    if(last_type === 'qa') {
                                        let isRight = value && (value.toLowerCase() === 'правильный' || value.toLowerCase() === 'right' || value.toLowerCase() === 'correct' || value.toLowerCase() === 'true');
                                        item.right = isRight;
                                    }else if(last_type === 'eqw') {
                                        item.text2 = value;
                                    }
                                    answers[quest_id].push(item);
                                    break;
                                }
                            }
                        }

                    }
                    this.doSaveTest(tests);

                }else {

                    let json = decodeURIComponent(escape(atob(b64data2)));
                    let test = JSON.parse(json);
                    // console.log(':: test=', test);
                    //загрузим файлы теста
                    if (test.files) {
                        // console.log(':: test.files=', test.files);
                        let count = Object.keys(test.files).length;
                        for (let j in test.files) {
                            let item = test.files[j];
                            // console.log(':: item=', item);
                            this.parent.parent.saveFile(item).then(file_id => {
                                // console.log(':: ------ count=%i old_id=%i new_id=%i', count, item.ID, file_id);
                                if (test.PROPERTY_VALUES.answers) {
                                    test.PROPERTY_VALUES.answers = test.PROPERTY_VALUES.answers.replace('"file":' + item.ID, '"file":' + file_id);
                                }
                                count--;
                                if (count <= 0) {
                                    // выполнились все
                                    test.files = null;
                                    if(this.countLoaded < this.maxCount) {
                                        this.doSaveTest([test]);
                                        this.countLoaded++;
                                    }else{
                                        this.listFiles=[];
                                        this.upload();
                                    }
                                }

                            }, err => {
                                // console.log(err)
                            });

                        }
                    } else {
                        if(this.countLoaded < this.maxCount) {
                            this.doSaveTest([test]);
                            this.countLoaded++;
                        }else{
                            this.parent.parent.tarifAlert('tests').then(isOk=>{});
                            this.listFiles=[];
                            this.upload();
                        }
                    }
                }
              });
            }else{
              this.isLoading=false;
              this.$emit('importTests', true)
            }

        },
        doSaveTest(tests){
            // console.log(':: doSaveTest tests=', tests);
            let $this = this;
            if(tests && Object.keys(tests).length > 0) {
                let test = tests.pop();
              // console.log(':: doSaveTest test=', test);
                this.parent.parent.getTestByName(test.NAME).then(res=>{
                    // console.log('::getTestByName res=',res);

                    let method = 'entityItemAdd';
                    let id=0;
                    if(res.result && res.result[0] && res.result[0].ID){
                        id = res.result[0].ID;
                        method = 'entityItemUpdate';
                    }
                    let params = {
                        'ENTITY': 'uni_tests',
                        'PREVIEW_TEXT': $this.parent.parent.categoria,
                        'NAME': test.NAME,
                        // 'DETAIL_TEXT': test.DETAIL_TEXT,
                        // 'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                        'ACTIVE':$this.set_published?'Y':'N',
                        'DATE_ACTIVE_FROM':'',
                        'PROPERTY_VALUES': test.PROPERTY_VALUES
                    };
                    if(id){
                        params.ID=id;
                    }
                    if(JSON.stringify(params.PROPERTY_VALUES).length >63990) {
                      console.error('The number of questions exceeds the allowed number. The total volume should be less than 63941, and now ', JSON.stringify(params.PROPERTY_VALUES).length);
                      $this.maxSizePropsAlert=true;
                      return;
                    }
                  var url = this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                  const DETAIL_TEXT = $this.parent.isBase64Encoded(test.DETAIL_TEXT)?test.DETAIL_TEXT:$this.parent.utf8_to_b64(test.DETAIL_TEXT);
                  $this.parent.bx24params.DETAIL_TEXT = DETAIL_TEXT;
                  // $this.parent.bx24params.DETAIL_TEXT = test.DETAIL_TEXT;
                  // $this.parent.bx24params.DETAIL_TEXT = $this.parent.utf8_to_b64(test.DETAIL_TEXT);
                  $this.parent.bx24params.DETAIL_PICTURE = test.DETAIL_PICTURE;
                  $this.parent.b24POST(url, this.parent.bx24params, function (res) {

                    if (res.result) {
                                let test_id = parseInt(res.result);
                                // if(id){
                                //     console.log('Test updated! id=', id);
                                // }else {
                                //     console.log('New test added! id=', test_id);
                                // }
                            }
                        // }
                        $this.doSaveTest(tests)
                    }, "json");

                });
            }else{
                $this.upload();
            }
        },
    },
    components: {
     // fileUpload
    }
}
</script>

<style scoped>


 </style>

