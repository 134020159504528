<template>
  <div style="padding-left: 5px; padding-bottom: 100px;">
    <div v-if="parent.isMobile" class="h4 q-ml-md text-positive">
      {{message[lang].events}}
      <q-btn flat round color="secondary" icon="refresh" @click="loadEvents"></q-btn>
      <q-btn flat round color="positive" :icon="isListViewEvents?'dashboard':'list'" @click="isListViewEvents=!isListViewEvents"></q-btn>
    </div>
    <div v-else class="row items-center justify-between" >
      <div class="col-7 text-left q-mb-2">
        <div class="row items-center justify-between">
          <div class="col-5 h4 text-positive">
            {{message[lang].eve_nts}}
            <q-btn flat rounded color="secondary" icon="refresh" @click="loadEvents">
              <q-tooltip class="bg-primary h-tt">{{message[lang].refresh}}</q-tooltip>
            </q-btn>
            <q-btn flat round color="positive" :icon="isListViewEvents?'dashboard':'list'" @click="isListViewEvents=!isListViewEvents">
              <q-tooltip v-if="isListViewEvents" class="bg-primary h-tt">{{message[lang].view_cards}}</q-tooltip>
              <q-tooltip v-else class="bg-primary h-tt">{{message[lang].view_list}}</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
      <div v-if="mode !== 'programs'" class="col-5 text-right q-mt-md" style="padding-right: 10px">
        <!-- <q-btn v-if="parent.isModer && mode !== 'LK'" padding="xs" :loading="isLoading" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addNewEvent"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-btn> -->
        <q-fab  v-if="parent.isModer && mode !== 'LK'"
          v-model="fab1"
          vertical-actions-align="right"
          color="secondary"
          icon="add"
          direction="down"
        >
          <q-fab-action color="secondary" @click="addNewEvent" :label="message[lang].ev_add" />
          <q-fab-action color="primary" @click="addQuiz=!addQuiz" :label="message[lang].ev_add_quiz" />
        </q-fab>

        <q-btn v-else padding="xs" :loading="isLoading" size="lg" flat round color="secondary" icon="add_circle_outline" @click="editEvent(null, true)"><q-tooltip class="bg-primary h-tt">{{message[lang].eve_title}}</q-tooltip></q-btn>
      </div>
    </div>

    <div v-if="addQuiz" :class="parent.isMobile?'q-ma-xs':'q-ma-lg'">
      <quiz :parent="parent" :lang="lang" :modeItem="'add'" :place="'event'" @quiz_save="saveQuiz" @quiz_cancel="addQuiz = !addQuiz" @quiz_delete="deleteQuiz" />
    </div>

    <div class="text-accent">
      <q-checkbox v-model="isEventsOrQuizList.e" :label="message[lang].events" />
      <q-checkbox v-model="isEventsOrQuizList.q" :label="message[lang].selectquiz_title" />
    </div>
    
    <div v-if="isListViewEvents">
      <q-list separator>
        <div v-for="(item,index) in fltListEvents" :key="item.ID">
          <!-- Опросы -->
          <div v-if="item.type=='quiz'"  class="parent.isMobile?'q-ma-xs':'q-ma-lg'" style="max-width: 800px">
              <quiz
                :place="'event'"
                :parent="parent"
                :lang="lang"
                :modeItem="'view'"
                :quizItem="item"
                :index="index"
                @quiz_save="saveQuiz"
                @quiz_cancel="addQuiz = false"
                @quiz_delete="deleteQuiz"
                @quiz_send="sendQuiz"
                @quiz_rate="sendQuiz"
                @quiz_res_del="resQuizDel"
              />
          </div>
          <!-- Мероприятия -->
          <q-expansion-item group="somegroup" @click="loadBids(item)"
             v-else-if="item.ACTIVE === 'Y' || parent.isModer">

            <template v-slot:header>
              <q-item-section>
                <q-item-label class="text-positive h4">{{item.NAME}}</q-item-label>
                <q-item-label class="text-positive h5" lines="2">{{parent.showBase64Text(item.DETAIL_TEXT)}}</q-item-label>
              </q-item-section>
              <q-item-section  side>
                <q-item-label class="text-positive h5">
                  {{parent.customFormatter(item.DATE_ACTIVE_FROM, '_._._',true)}} - {{parent.customFormatter(item.DATE_ACTIVE_TO, '_._._',true)}}
                </q-item-label>
                <div class="q-gutter-xs"  align="right">
                  <!-- <q-btn v-if="item.ACTIVE === 'Y'" flat round color="text-positive" icon="visibility" @click="viewEvent(item)">
                    <q-tooltip class="bg-primary h-tt">{{message[lang].eve_calend}}</q-tooltip>
                  </q-btn> -->
                  <q-icon v-if="parent.isModer" :name="item.ACTIVE === 'Y'?'done_all':'remove_done'" :color="item.ACTIVE === 'Y'?'secondary':'warning'" size="sm">
                    <q-tooltip v-if="item.ACTIVE === 'Y'" class="bg-primary h-tt">{{message[lang].published}}</q-tooltip>
                    <q-tooltip v-else class="bg-warning h-tt">{{message[lang].notpublished}}</q-tooltip>
                  </q-icon>
                  <q-btn v-if="mode!=='LK'" :loading="item.loading_bids" flat round color="primary" icon="playlist_add" @click="editEvent(item, true)">
                    <q-tooltip class="bg-primary h-tt">{{message[lang].eve_title}}</q-tooltip>
                  </q-btn>
                  <q-btn v-if="parent.isModer" flat round color="secondary" icon="edit" @click="editEvent(item)">
                    <q-tooltip class="bg-primary h-tt">{{message[lang].eve_edit}}</q-tooltip>
                  </q-btn>
                  <q-btn v-if="parent.isModer" flat round color="positive" icon="clear" @click="eventDel(item,index)">
                    <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
                  </q-btn>
                  <q-btn v-if="parent.isModer" :loading="item.loading_exp" flat round color="positive" icon="file_download" @click="exportEvent(item)">
                    <q-tooltip class="bg-primary h-tt">{{message[lang].ipr_export}}</q-tooltip>
                  </q-btn>

                </div>
              </q-item-section>
            </template>

            <q-card>
                <q-card-section>

                  <div class="row">
                    <div class="col-8">
                      <div class="q-ml-md text-accent">
                        <span class="text-positive h4">{{message[lang].em_location}}:</span>
                        {{item.PROPERTY_VALUES.location}}
                      </div>
                      <div class="q-ml-md text-accent">
                        <span class="text-positive h4">{{message[lang].em_vid}}:</span>
                        {{item.PROPERTY_VALUES.kind}}
                      </div>
                      <div class="q-ml-md text-accent">
                        <span class="text-positive h4">{{message[lang].em_org}}:</span>
                        {{item.PROPERTY_VALUES.organizer}}
                      </div>
                      <!-- Сертификаты -->
                      <div v-if="ext_certs">
                        <q-btn color="primary" flat no-caps rounded :label="message[lang].cert_add" @click="certAdd(index)"/>
                      </div>
                      <q-scroll-area class="bg-negative q-pa-md" style="padding-left:10px;  border-radius: 25px !important; max-height: 300px;">
                        <div class="q-ml-md q-gutter-x-md" v-if="item.PROPERTY_VALUES.flags.certs">
                        <span v-for="(cert,ind) in item.PROPERTY_VALUES.flags.certs" :key="ind">
                          <q-avatar rounded >
                            <img v-if="cert.H"
                                 :src="cert.H"
                                 @click="popup_data=cert.H; popup_cert=true; popup_uid=cert.uid"
                                 class="cursor-pointer q-ma-xs"
                            />
                            <q-icon v-else size="lg" color="positive" name="no_photography" >
                              <q-tooltip class="bg-primary h-tt">{{message[lang].del_certs}}</q-tooltip>
                            </q-icon>
                            <q-badge v-if="cert.uid == parent.user_current.ID || parent.isModer"  rounded floating color="negative" class="cursor-pointer" @click="certDel(item, ind)">
                              <q-icon name="clear" color="warning" />
                            </q-badge>
                          </q-avatar>
                        </span>
                      </div>
                      </q-scroll-area>
                    </div>
                    <div class="col-4">
                      <!-- АОС  -->
                      <div class="text-right">
                        <q-btn :disabled="item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.aos && listAOS[item.ID]" flat no-caps padding="xs" rounded v-if="item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.aos" :color="listAOS[item.ID]?'secondary':'primary'" :label="message[lang].aos" :icon-right="listAOS[item.ID]?'done':''" @click="showAOS(item)"/>
                        <span v-if="item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.aos && listAOS[item.ID]" class="text-secondary">{{message[lang].aos_filled}} {{showTime(listAOS[item.ID])}}</span>
                      </div>
                      <!-- Заявки -->
                      <div v-if="item.bids">
                        <q-scroll-area class="bg-negative q-pa-md" style="padding-left:10px;  border-radius: 25px !important; max-height: 300px;">
                          <q-list padding v-if="parent.isModer">
                            <q-item-label header>
                              <q-btn flat round icon="arrow_back" color="primary" @click="addAllFromBid(item)">
                                <q-tooltip class="bg-primary h-tt">{{message[lang].eve_bids_all}}</q-tooltip>
                              </q-btn>
                              {{message[lang].em_bids}}
                            </q-item-label>
                            <div v-for="(bidItem,bidIndex) in item.bids" :key="bidIndex" >
                              <q-item v-if="eventUsers[bidItem.PREVIEW_TEXT]">
                                <q-item-section>
                                  <q-item-label  class="text-accent h5">{{eventUsers[bidItem.PREVIEW_TEXT].name}}</q-item-label>
                                  <q-item-label class="text-positive h5">{{bidItem.DETAIL_TEXT}}</q-item-label>
                                </q-item-section>

                                <q-item-section top side v-if="isInAttendees(item, bidItem)">
                                  <q-icon size="sm" name="done" color="secondary" >
                                    <q-tooltip class="bg-primary h-tt">{{message[lang].bid_accepted}}</q-tooltip>
                                  </q-icon>
                                </q-item-section>
                                <q-item-section top side v-else-if="bidItem.ACTIVE==='Y'">
                                  <div class="q-gutter-xs">
                                    <q-btn padding="xs" size="sm" flat round icon="arrow_back" color="primary" @click="addFromBid(item, bidItem, true)">
                                      <q-tooltip class="bg-primary h-tt">{{message[lang].bid_accept}}</q-tooltip>
                                    </q-btn>
                                    <q-btn padding="xs" size="sm" flat round icon="clear" color="positive" @click="declineBid(item, bidItem)">
                                      <q-tooltip class="bg-primary h-tt">{{message[lang].bid_decline_title}}</q-tooltip>
                                    </q-btn>
                                    <q-btn padding="xs" size="sm" flat round icon="delete_forever" color="positive" @click="delBid(item, bidItem, bidIndex)">
                                      <q-tooltip class="bg-primary h-tt">{{message[lang].bid_del_message}}</q-tooltip>
                                    </q-btn>
                                  </div>
                                </q-item-section>
                                <q-item-section top side v-else>
                                  <q-btn size="sm" flat round icon="hide_source" color="warning" @click="restoreBid(item, bidItem)">
                                    <q-tooltip class="bg-primary h-tt">{{message[lang].bid_declined}}</q-tooltip>
                                  </q-btn>
                                </q-item-section>
                              </q-item>
                              <q-separator inset />
                            </div>
                          </q-list>
                          <div v-else-if="!!isMyBid(item)" class="text-accent">
                            {{isMyBid(item)}}
                          </div>
                          <q-inner-loading :showing="item.loading_bids">
                            <q-spinner-oval size="5em" color="positive" />
                          </q-inner-loading>
                        </q-scroll-area>
                      </div>
                    </div>
                  </div>

                </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator />
        </div>
      </q-list>
    </div>
    <!-- Карточки -->
    <div v-else class="q-pa-md row items-start q-gutter-md">
      <div v-for="(item,index) in fltListEvents" :key="item.ID">
      <!-- Опросы -->
      <div v-if="item.type=='quiz'"  class="parent.isMobile?'q-ma-xs':'q-ma-lg'" style="max-width: 800px">
          <quiz
            :place="'event'"
            :parent="parent"
            :lang="lang"
            :modeItem="'view'"
            :quizItem="item"
            :index="index"
            @quiz_save="saveQuiz"
            @quiz_cancel="addQuiz = false"
            @quiz_delete="deleteQuiz"
            @quiz_send="sendQuiz"
            @quiz_rate="sendQuiz"
            @quiz_res_del="resQuizDel"
          />
      </div>
    <!-- Мероприятия -->
      <q-card v-else-if="item.ACTIVE === 'Y' || parent.isModer" class="event-card" >
        <q-card-section class="q-pa-xs text-accent">
          {{parent.customFormatter(item.DATE_ACTIVE_FROM, '_._._',true)}} - {{parent.customFormatter(item.DATE_ACTIVE_TO, '_._._',true)}}
        </q-card-section>

        <q-card-section class="q-pa-xs text-accent">
          {{item.NAME}}
        </q-card-section>
        <q-card-section class="q-pa-xs text-positive h5">
          {{parent.showBase64Text(item.DETAIL_TEXT)}}

          <q-expansion-item :label="message[lang].em_details" group="somegroup" @click="loadBids(item)"
                            v-if="item.ACTIVE === 'Y' || parent.isModer">
          <div class="q-ml-md text-accent">
            <span class="text-positive h4">{{message[lang].em_location}}:</span>
            {{item.PROPERTY_VALUES.location}}
          </div>
          <div class="q-ml-md text-accent">
            <span class="text-positive h4">{{message[lang].em_vid}}:</span>
            {{item.PROPERTY_VALUES.kind}}
          </div>
          <div class="q-ml-md text-accent">
            <span class="text-positive h4">{{message[lang].em_org}}:</span>
            {{item.PROPERTY_VALUES.organizer}}
          </div>
          <!-- Сертификаты -->
            <div v-if="ext_certs">
              <q-btn color="primary" flat no-caps rounded :label="message[lang].cert_add" @click="certAdd(index)"/>
            </div>
            <q-scroll-area class="bg-negative q-pa-md" style="padding-left:10px;  border-radius: 25px !important; max-height: 300px;">
              <div class="q-ml-md q-gutter-x-md" v-if="item.PROPERTY_VALUES.flags.certs">
                <span v-for="(cert,ind) in item.PROPERTY_VALUES.flags.certs" :key="ind">
                  <q-avatar rounded >
                    <img v-if="cert.H"
                         :src="cert.H"
                         @click="popup_data=cert.H; popup_cert=true; popup_uid=cert.uid"
                         class="cursor-pointer q-ma-xs"
                    />
                    <q-icon v-else size="lg" color="positive" name="no_photography" >
                      <q-tooltip class="bg-primary h-tt">{{message[lang].del_certs}}</q-tooltip>
                    </q-icon>
                    <q-badge v-if="cert.uid == parent.user_current.ID || parent.isModer"  rounded floating color="negative" class="cursor-pointer" @click="certDel(item, ind)">
                      <q-icon name="clear" color="warning" />
                    </q-badge>
                  </q-avatar>
                </span>
            </div>
            </q-scroll-area>
            <!-- АОС  -->
            <div class="text-right">
              <q-btn :disabled="item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.aos && listAOS[item.ID]" flat no-caps padding="xs" rounded v-if="item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.aos" :color="listAOS[item.ID]?'secondary':'primary'" :label="message[lang].aos" :icon-right="listAOS[item.ID]?'done':''" @click="showAOS(item)"/>
              <span v-if="item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.aos && listAOS[item.ID]" class="text-secondary">{{message[lang].aos_filled}} {{showTime(listAOS[item.ID])}}</span>
            </div>
            <!-- Заявки -->
            <div v-if="item.bids">
              <q-scroll-area class="bg-negative q-pa-md" style="padding-left:10px;  border-radius: 25px !important; max-height: 300px;">
              <q-list padding v-if="parent.isModer">
                  <q-item-label header>
                    <q-btn flat round icon="arrow_back" color="primary" @click="addAllFromBid(item)">
                      <q-tooltip class="bg-primary h-tt">{{message[lang].eve_bids_all}}</q-tooltip>
                    </q-btn>
                    {{message[lang].em_bids}}
                  </q-item-label>
                  <div v-for="(bidItem,bidIndex) in item.bids" :key="bidIndex" >
                    <q-item v-if="eventUsers[bidItem.PREVIEW_TEXT]">
                      <q-item-section>
                        <q-item-label  class="text-accent h5">>{{eventUsers[bidItem.PREVIEW_TEXT].name}}</q-item-label>
                        <q-item-label class="text-positive h5">{{bidItem.DETAIL_TEXT}}</q-item-label>
                      </q-item-section>

                      <q-item-section top side v-if="isInAttendees(item, bidItem)">
                        <q-icon size="sm" name="done" color="secondary" >
                          <q-tooltip class="bg-primary h-tt">{{message[lang].bid_accepted}}</q-tooltip>
                        </q-icon>
                      </q-item-section>
                      <q-item-section top side v-else-if="bidItem.ACTIVE==='Y'">
                        <div class="q-gutter-xs">
                          <q-btn padding="xs" size="sm" flat round icon="arrow_back" color="primary" @click="addFromBid(item, bidItem, true)">
                            <q-tooltip class="bg-primary h-tt">{{message[lang].bid_accept}}</q-tooltip>
                          </q-btn>
                          <q-btn padding="xs" size="sm" flat round icon="clear" color="positive" @click="declineBid(item, bidItem)">
                            <q-tooltip class="bg-primary h-tt">{{message[lang].bid_decline_title}}</q-tooltip>
                          </q-btn>
                          <q-btn padding="xs" size="sm" flat round icon="delete_forever" color="positive" @click="delBid(item, bidItem, bidIndex)">
                            <q-tooltip class="bg-primary h-tt">{{message[lang].bid_del_message}}</q-tooltip>
                          </q-btn>
                        </div>
                      </q-item-section>
                      <q-item-section top side v-else>
                        <q-btn size="sm" flat round icon="hide_source" color="warning" @click="restoreBid(item, bidItem)">
                          <q-tooltip class="bg-primary h-tt">{{message[lang].bid_declined}}</q-tooltip>
                        </q-btn>
                      </q-item-section>
                    </q-item>
                    <q-separator inset />
                  </div>
                </q-list>
                <div v-else-if="!!isMyBid(item)" class="text-accent">
                  {{isMyBid(item)}}
                </div>
                <q-inner-loading :showing="item.loading_bids">
                  <q-spinner-oval size="5em" color="positive" />
                </q-inner-loading>
              </q-scroll-area>
            </div>
          </q-expansion-item>
        </q-card-section>

        <q-separator/>
        <q-card-section>
          <div class="row justify-end items-center">
            <!-- <q-btn v-if="item.ACTIVE === 'Y'" flat round color="positive" icon="visibility" @click="viewEvent(item)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].eve_calend}}</q-tooltip>
            </q-btn> -->
            <q-icon v-if="parent.isModer" :name="item.ACTIVE === 'Y'?'done_all':'remove_done'" :color="item.ACTIVE === 'Y'?'secondary':'warning'" size="sm">
              <q-tooltip v-if="item.ACTIVE === 'Y'" class="bg-primary h-tt">{{message[lang].published}}</q-tooltip>
              <q-tooltip v-else class="bg-warning h-tt">{{message[lang].not_published}}</q-tooltip>
            </q-icon>
            <q-btn v-if="mode!=='LK'" :loading="item.loading_bids" flat round color="primary" icon="playlist_add" @click="editEvent(item, true)">
            <q-tooltip class="bg-primary h-tt">{{message[lang].eve_title}}</q-tooltip>
          </q-btn>
            <q-btn v-if="parent.isModer" flat round color="secondary" icon="edit" @click="editEvent(item)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].eve_edit}}</q-tooltip>
            </q-btn>
            <q-btn v-if="parent.isModer" flat round color="positive" icon="clear" @click="eventDel(item,index)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
            </q-btn>
            <q-btn v-if="parent.isModer" :loading="item.loading_exp" flat round color="positive" icon="file_download" @click="exportEvent(item)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].ipr_export}}</q-tooltip>
            </q-btn>
          </div>
        </q-card-section>


      </q-card>
      </div>
    </div>

<!--    -->
    <q-inner-loading :showing="isLoading">
      <q-spinner-oval size="5em" color="primary" />
    </q-inner-loading>

    <q-dialog v-model="popup_cert">
      <q-card class="column q-pa-none q-ma-none bg-negative" style="width: 700px; max-width: 80vw; box-shadow: none">
        <q-img v-if="popup_data" :src="popup_data" :fit="'cover'"/>
        <q-card-actions align="right" class="text-primary">
          <span v-if="eventUsers[popup_uid]" class="q-pa-sm">{{eventUsers[popup_uid].name}}</span>
          <q-space/>
          <q-btn color="primary" round icon="clear" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="certs_dialog" transition-show="rotate" transition-hide="rotate">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">{{message[lang].event_certs}}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>


        <q-card-section class="q-pt-none" v-if="ext_certs && ext_certs.length>0">
          <q-list>
            <q-item clickable v-ripple v-for="item in ext_certs" :key="item.I">
              <q-item-section avatar>
                <q-avatar rounded @click="certSelect(item)">
                  <img
                    :src="item.H?item.H:item.data"
                  />
                </q-avatar>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-section class="q-pt-none text-warning" v-else>
          {{message[lang].no_certs}}
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>

</template>

<script>
import dayjs from 'dayjs'
// import {messageObj} from "src/helpers/message";
import {saveAs} from "file-saver";
import XLSX from 'xlsx';
import quiz from 'src/components/Quiz.vue';

export default {
  props:{
    parent:Object,
    lang:String,
    back:String,
    mode:String,
    eventItem:Object,

  },
  data: function () {
    return {
      isEventsOrQuizList:{q:true, e:true},
      fab1:false,
      addQuiz:false,
      mergedList:[],
      listAOS:'',
      eventUsers:{},
      currentEventIndex:null,
      certs_dialog:false,
      popup_cert:false,
      isLoading:false,
      isListViewEvents:false,
      ext_certs:null,
      message:this.parent.dictionary,
    }
  },
  mounted() {
    let $this=this;
    this.parent.userOption('get','isListViewEvents').then(res=>{
      this.isListViewEvents = res === "false" ? false : Boolean(res);
      console.log(':: mounted isListViewEvents =',res, this.isListViewEvents)
    });
    $this.loadEvents();
  },
  computed:{
    fltListEvents(){
      let uid = this.parent.user_current.ID;
      let jsonuid = `"${uid}"`;

      return this.mergedList?this.mergedList.filter(el => {
        const ret = 
          el.type==='quiz' && this.isEventsOrQuizList.q 
          || 
          el.type!=='quiz' && this.isEventsOrQuizList.e 
          && 
            (
              this.mode==='LK' && (    
                el.PROPERTY_VALUES.attendees && (el.PROPERTY_VALUES.attendees.indexOf(jsonuid)>0 ||
                el.PROPERTY_VALUES.responsible == uid ) 
              )
              || 
          this.mode==='programs' && el.ID == this.eventItem.ID
              || 
          this.mode!=='LK' && this.mode!=='programs'
            )

        // console.log(ret, el)
        return ret;
      }):[];

    },
  },
  watch:{
    isListViewEvents:function(arg){
      console.log(':: isListViewEvents ',arg)
      this.parent.userOption('set','isListViewEvents', arg);
    },
  },
  methods:{
    loadEvents(){
      let $this=this;
      this.isLoading = true;
      this.loadMyCerts().then(res => {
        $this.ext_certs = res;
        this.parent.eventsGet().then(res => {
          
          $this.parent.listEvents = res;
          console.log(':: eventsGet res=', $this.parent.listEvents);
          if ($this.parent.listEvents.length > 0) {
            for (let item of $this.parent.listEvents){

              if(item.PROPERTY_VALUES.flags){
                item.PROPERTY_VALUES.flags = this.parent.isJSON(item.PROPERTY_VALUES.flags)?JSON.parse(item.PROPERTY_VALUES.flags):item.PROPERTY_VALUES.flags;
              }else{
                item.PROPERTY_VALUES.flags = {aos:false, certs:[]};
              }
            }
          }
          console.log(':: eventsGet fltListEvents=', this.fltListEvents);
          this.parent.loadQizList(false, 'uni_quizevent').then((res_quiz) => {
            this.isLoading = false;
            res_quiz.forEach(item => {
              item.type = 'quiz';
            });
            // console.log(':: loadQizList res_quiz=',res_quiz)
            this.mergedList = this.parent.mergeArrays(res_quiz, this.parent.listEvents);
            // console.log(':: loadQizList this.mergedList=',this.mergedList)

            this.loadEventAOSlist().then(res=> {
              this.loadAOSAnswered().then(res2=>{
                this.listAOS=res2;
                console.log(':: this.listAOS=', this.listAOS);
              }, err=>console.errror(err));
            });
          });
        });
      });
    },
    isMyBid(eventItem){
      if(eventItem.bids) {
        let bidItem = eventItem.bids.find(el => {
          return el.PREVIEW_TEXT == this.parent.user_current.ID
        })
        let ret=false;
        if(bidItem && bidItem.ACTIVE==='N'){
          ret = 'bid_decl';
        }else if(bidItem && this.isInAttendees(eventItem, bidItem)){
          ret = 'bid_accepted';
        } else if(bidItem && bidItem.ACTIVE==='Y'){
          ret = 'bid_exist';
        }
        if(ret){
          ret = this.message[this.lang][ret];
          if(bidItem.DETAIL_TEXT) {
            ret += ': ' + bidItem.DETAIL_TEXT;
          }
        }
        console.log('::isMyBid eventItem=',ret, eventItem);
        return ret;
      }else{
        return false
      }
    },
    isInAttendees(eventItem, bidItem){
      let ret = eventItem.PROPERTY_VALUES && eventItem.PROPERTY_VALUES.attendees.indexOf(`"${bidItem.PREVIEW_TEXT}"`)>=0;
      // console.log('::isInAttendees ret=',ret, item, this.event.attendees);
      return ret;
    },
    addFromBid(eventItem, bidItem, isSave){
      let uid = this.eventUsers[bidItem.PREVIEW_TEXT].id;
      if(!this.isInAttendees(eventItem, bidItem)){
        this.alertCountAttendees(eventItem);
        let attendees = this.parent.isJSON(eventItem.PROPERTY_VALUES.attendees)?JSON.parse(eventItem.PROPERTY_VALUES.attendees):[];
        attendees.push(uid);
        //Баллы за прием заявки
        this.parent.savePoints('E', eventItem.ID, null, uid);
        eventItem.PROPERTY_VALUES.attendees = JSON.stringify(attendees);
        if(isSave) {
          this.updateEventAttendees(eventItem,'attendees');
        }
      }
    },
    alertCountAttendees(eventItem){
      console.log('::alertCountAttendees count=',eventItem.PROPERTY_VALUES.flags.count )
      let $this=this;
      let ret=true;
      if(eventItem.PROPERTY_VALUES.flags.count > 0 && eventItem.PROPERTY_VALUES.attendees) {
        let attendees = this.parent.isJSON(eventItem.PROPERTY_VALUES.attendees)?JSON.parse(eventItem.PROPERTY_VALUES.attendees):[];
        console.log('::alertCountAttendees attendees.length=',attendees.length )
        if(attendees.length >= eventItem.PROPERTY_VALUES.flags.count){
          $this.parent.notify_text = this.message[this.lang].em_count_limit + ' ' + eventItem.PROPERTY_VALUES.flags.count;
          $this.parent.notify_title = this.message[this.lang].attention;
          $this.parent.notify_ok = this.message[this.lang].alert_understood;
          $this.parent.notify_warning = true;
          $this.parent.notify = true;

          ret=false;
        }
      }
      return ret;
    },
    addAllFromBid(eventItem){
      if(eventItem.bids && eventItem.bids.length>0) {
        let isSave=false;
        for (let bidItem of eventItem.bids.filter(el => {return el.ACTIVE === 'Y'})) {
          this.addFromBid(eventItem, bidItem, false);
          isSave=true;
        }
        if(isSave) {
          this.updateEventAttendees(eventItem, 'attendees');
        }
      }
    },
    delBid(eventItem, bidItem, bidIndex){
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm = this.message[this.lang].bid_del_message;
      $this.parent.confirm_input = '';
      $this.parent.confirm = true;
      $this.parent.do_confirm = ()=>{
        eventItem.loading_bids = true;
        $this.parent.delBid(bidItem.ID).then(res=>{
          eventItem.loading_bids = false;
          // $this.loadBids(eventItem);
          eventItem.bids.splice(bidIndex,1);
        });
        $this.parent.confirm = false;
      };
    },
    declineBid(eventItem, bidItem){
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].bid_decline_title;
      $this.parent.message_confirm = this.message[this.lang].bid_decline_message;
      $this.parent.confirm_input_label = this.message[this.lang].eve_bid_lbl;
      $this.parent.show_confirm_input = true;
      $this.parent.confirm_input = '';
      $this.parent.confirm = true;
      $this.parent.do_confirm = ()=>{
        console.log('::declineBid do_confirm ',$this.parent.confirm_input);
        $this.parent.show_confirm_input = false;
        $this.parent.confirm_input = bidItem.DETAIL_TEXT+' -> '+$this.parent.confirm_input;
        eventItem.loading_bids = true;
        $this.parent.saveBid(eventItem.ID, bidItem.PREVIEW_TEXT, bidItem.ID,false).then(res=>{
          eventItem.loading_bids = false;
          $this.loadBids(eventItem);
        });
        $this.parent.confirm = false;

      };
    },
    restoreBid(eventItem, bidItem){
      console.log('::restoreBid bidItem ', bidItem);
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].bid_restore_title;
      $this.parent.message_confirm = this.message[this.lang].bid_restore_message;
      $this.parent.confirm_input_label = this.message[this.lang].eve_bid_lbl;
      $this.parent.show_confirm_input = true;
      $this.parent.confirm_input = '';
      $this.parent.confirm = true;
      $this.parent.do_confirm = ()=> {
        console.log('::restoreBid do_confirm ', $this.parent.confirm_input);
        $this.parent.show_confirm_input = false;
        $this.parent.confirm_input = bidItem.DETAIL_TEXT + ' -> ' + $this.parent.confirm_input;
        eventItem.loading_bids = true;
        $this.parent.saveBid(eventItem.ID, bidItem.PREVIEW_TEXT, bidItem.ID, true).then(res => {
          eventItem.loading_bids = false;
          $this.loadBids(eventItem);
        });
        $this.parent.confirm = false;
      };

    },
    updateEventAttendees(eventItem, key, notSaveToCalendar){
      console.log(':1:updateEventAttendees ', eventItem);
      let $this = this;
      let method = 'entityItemUpdate';
      let params = {
        ENTITY: 'uni_events',
        ID: eventItem.ID,
        PROPERTY_VALUES: {}
      }
      if(key==='flags'){
        params['PROPERTY_VALUES'].flags = JSON.stringify(eventItem.PROPERTY_VALUES.flags);
      }else {
        params['PROPERTY_VALUES'][key] = eventItem.PROPERTY_VALUES[key];
      }
      console.log(':2:updateEventAttendees params=', params)
      eventItem.loading_bids = true;
      var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
      $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
        console.log(':3:updateEventAttendees res=', res)
        if(!notSaveToCalendar) {
          $this.calendarEventUpdate(eventItem);
        }
        eventItem.loading_bids = false;
        $this.loadBids(eventItem);
      }, "json");
    },
    calendarEventUpdate(eventItem){
      console.log('::calendarEventUpdate eventItem=',eventItem)
      let $this=this;
      BX24.callMethod("calendar.section.get",
        {
          type: 'company_calendar',
          ownerId: '0'
        }, function (res) {
          if(res.answer && res.answer.result && res.answer.result.length>0) {
            console.log('::addEvent section=', res.answer.result)
            let section = res.answer.result[0].ID;
            let attendees = $this.parent.isJSON(eventItem.PROPERTY_VALUES.attendees)?JSON.parse(eventItem.PROPERTY_VALUES.attendees):'';
            if(attendees){
              attendees = attendees.map(el=>parseInt(el));
            }
            let method = "calendar.event.update";
            let params = {
              id: parseInt(eventItem.PROPERTY_VALUES.id),
              ownerId: '0',
              section: section,
              type: 'company_calendar',
              name: eventItem.NAME + (eventItem.PROPERTY_VALUES.organizer ? '. ' + $this.message[$this.lang].em_org + ': ' + eventItem.PROPERTY_VALUES.organizer : ''),
              attendees: attendees,
              from: eventItem.DATE_ACTIVE_FROM,
              to: eventItem.DATE_ACTIVE_TO,
              meeting: JSON.parse(eventItem.PROPERTY_VALUES.meeting),
              host: eventItem.PROPERTY_VALUES.responsible,
              location: eventItem.PROPERTY_VALUES.location,
              color: eventItem.PROPERTY_VALUES.color,
              text_color: eventItem.PROPERTY_VALUES.text_color,
              description: $this.parent.showBase64Text(eventItem.DETAIL_TEXT),
              //
              is_meeting: "Y",
              accessibility:'absent',
              importance: 'normal',
              skipTime: 'N',
              private_event: 'N',
              remind: [{type: 'min', count: 20}]
            }
            console.log('::calendarEventUpdate params=', params)
            BX24.callMethod(method, params, (res) => {
              console.log(`::calendarEventUpdate res=`, res);
            });
          }
      });
    },
    loadBids(item){
      let $this=this;
      console.log('::loadBids item=', item);
      return new Promise(resolve => {
        item.loading_bids=true;
        $this.parent.getBids(item.ID).then(res=> {
          console.log('::loadBids res=', res);
          if(res && res.length>0) {
            item.bids = [...res];

            $this.eventUsers=$this.eventUsers?$this.eventUsers:[];
            let ids = res.map(el => el.PREVIEW_TEXT)
            ids = ids.filter(el=>{return !$this.eventUsers[el]})
            if(ids.length>0){
              $this.parent.getUsersByIds(ids).then(users => {
                // console.log('::parent.getUsersByIds res=',users)
                for(let i in users){
                  if(!$this.eventUsers[users[i].id]){
                    $this.eventUsers[users[i].id]=users[i];
                  }
                }
                // console.log('::parent.getUsersByIds eventUsers=',$this.eventUsers)
                item.loading_bids=false;
              })
            }else{
              item.loading_bids=false;
            }
          }else{
            item.loading_bids=false;
          }
          resolve(true)
        });
      });

    },
    showTime(dt){
      let format = this.parent.appRegion  == 'ru' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';
      return dayjs(dt).format(format);
    },
    //АОС
    showAOS(item){
      this.parent.showAOS({props_aos:item.PROPERTY_VALUES.flags.aos, id:item.ID, name:item.NAME, type:'event'});
      setTimeout(()=>{
        this.listAOS = this.listAOS ? this.listAOS : {};
        this.listAOS[`${item.ID}`]=dayjs().format('YYYY-MM-DD HH:mm');
      }, 3000);
    },
    loadAOSAnswered(){
      // console.log('::loadAOSAnswered ');
      let $this=this;
      let listAOS = {};
      let date_from = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      let date_to = dayjs().add(6, 'month').format('YYYY-MM-DD');
      // console.log('::loadAOSAnswered ',date_from, date_to);
      return new Promise(function (resolve) {
        $this.parent.getAosList(date_from, date_to, 'event').then((list) => {
          // console.log('::loadAOSAnswered list=',list);
          for(let i in list){
            let item = list[i];
            // console.log('::loadAOSAnswered item=',item);
            if(item.user.ID==$this.parent.user_current.ID){
              listAOS[item.ent.id] = item.date;
            }
          }
          resolve(listAOS);
        });
      });
    },
    loadEventAOSlist(){
      let $this=this;
      this.eventAOSlist = [];
      return new Promise(function (resolve) {
        $this.parent.appOptionGet('event_aos').then((data) => {
          // console.log(':: loadEventAOSlist data=',data)
          if ($this.parent.isJSON(data.DETAIL_TEXT)) {
            let list = JSON.parse(data.DETAIL_TEXT);
            let preMonth = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
            // console.log(':: ', preMonth, list);
            $this.eventAOSlist=[];
            for(let i in list){
              if(list[i].date > preMonth){
                $this.eventAOSlist.push(list[i]);
              }
            }
          }
          resolve('ok');
        });
      });
    },
    //Мероприятия
    addNewEvent(){
      this.$emit('addNewEvent')
    },
    editEvent(item, isBid){
      let $this=this;
      if(isBid){
        $this.parent.title_confirm = this.message[this.lang].eve_title;
        $this.parent.confirm_input_label = this.message[this.lang].eve_bid_lbl;
        $this.parent.show_confirm_input = true;
        $this.parent.confirm_input = '';
        $this.parent.notify_ok = false;

        if(item) {

          $this.loadBids(item).then(ok=>{
            if(item.bids && item.bids.findIndex(el=>{return el.PREVIEW_TEXT==this.parent.user_current.ID && el.ACTIVE=='Y'})>=0){
              console.log('::Заявка уже подана');
              $this.parent.notify_text = this.message[this.lang].eve_bid_done;
              $this.parent.notify_title = this.message[this.lang].attention;
              $this.parent.notify_ok = this.message[this.lang].alert_understood;
              $this.parent.notify_warning = true;
              $this.parent.notify = true;
            }else {
              $this.alertCountAttendees(item);
              //Не модератор может только записаться на событие
              $this.parent.message_confirm = this.message[this.lang].eve_bid_add;
              $this.parent.message_confirm += ' "' + item.NAME + '"';
              $this.parent.confirm = true;
              $this.parent.do_confirm = () => {
                // console.log('::editEvent do_confirm ', $this.parent.confirm_input)
                item.loading_bids = true;
                $this.parent.saveBid(item.ID, null, null, true).then(res => {
                  item.loading_bids = false;
                  $this.loadBids(item);
                  //Сообщение что заявка приянта
                  $this.parent.notify_text = this.message[this.lang].eve_bid_done;
                  $this.parent.notify_title = this.message[this.lang].attention;
                  $this.parent.notify_ok = this.message[this.lang].alert_understood;
                  $this.parent.notify_warning = false;
                  $this.parent.notify = true;
                  //Сообщение модераторам
                  let message = this.message[this.lang].eve_add_bid_moder;
                  message += ' '+$this.parent.user_current.NAME + ' ' + this.parent.user_current.LAST_NAME;
                  message += ': "' + $this.parent.confirm_input + '"';
                  $this.parent.modersNotify(message, "event");
                });
                $this.parent.confirm = false;
                $this.parent.show_confirm_input = false;
              };
            }
          })
        }else{
          //Запись на несуществующее мероприятие
          $this.parent.message_confirm = this.message[this.lang].eve_new_bid_add;
          $this.parent.confirm = true;
          $this.parent.do_confirm = () => {
            // console.log('::editEvent do_confirm ', $this.parent.confirm_input)
            $this.isLoading = true;
            //Создаем пустое мероприятие
            $this.addEmptyEvent($this.parent.confirm_input).then(res=>{
              if(res.id){
                $this.parent.saveBid(res.id, null, null, true).then(res => {
                  $this.isLoading = false;
                  $this.parent.confirm = false;
                  $this.parent.show_confirm_input = false;
                  //Сообщение что заявка приянта
                  $this.parent.notify_text = this.message[this.lang].eve_new_bid_done;
                  $this.parent.notify_title = this.message[this.lang].attention;
                  $this.parent.notify_ok = this.message[this.lang].alert_understood;
                  $this.parent.notify_warning = false;
                  $this.parent.notify=true;
                  //Сообщение модераторам
                  let message = this.message[this.lang].eve_new_bid_moder;
                  message += ' '+$this.parent.user_current.NAME + ' ' + this.parent.user_current.LAST_NAME;
                  message += ': "' + $this.parent.confirm_input + '"';
                  $this.parent.modersNotify(message, "event");
                });

              }else{
                console.error(res.error);
                $this.isLoading = false;
                $this.parent.confirm = false;
                $this.parent.show_confirm_input = false;
              }
            })

          };
        }
      }else{
        this.$emit('editEvent', item)
      }
    },
    addEmptyEvent(description){
      console.log(':1:addEmptyEvent ')
      let $this = this;
      let method = 'entityItemAdd';
      let today = dayjs().format('YYYY-MM-DD HH:mm');
      let params = {
        'ENTITY': 'uni_events',
        'PREVIEW_TEXT': this.parent.user_current.ID,
        'NAME': this.message[this.lang].eve_new_bid_title,
        'DETAIL_TEXT': this.parent.utf8_to_b64(description),
        'ACTIVE': 'N',
        'DATE_ACTIVE_FROM': today,
        'DATE_ACTIVE_TO': today,
        'PROPERTY_VALUES': {
          type: null,
          kind: null,
          organizer:'',
          responsible:'',
          attendees:'',
          meeting:null,
          color:null,
          text_color:null,
          location:'',
          flags:JSON.stringify({aos:false, certs:[]}),
        }
      };
      console.log(':2:saveEvent params=', params)
      return new Promise(function (resolve) {
        $this.isLoading = true;
        var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          console.log(':3:saveEvent res=', res)
          $this.isLoading = false;
          if (res.result) {
            let id = res.result;
            resolve({id:id});
          }else{
            resolve({id:null, error:res});
          }

        }, "json")
      });
    },
    eventDel(item,index){
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].remove;
      $this.parent.message_confirm = this.message[this.lang].em_del;
      $this.parent.message_confirm += ' : '+item.NAME;
      $this.parent.do_confirm = ()=>{
        $this.doEventDel(item.ID).then(res=>{
          $this.parent.listEvents.splice(index,1);
          BX24.callMethod("calendar.event.delete",
            {
              id: item.PROPERTY_VALUES.id,
              type: 'company_calendar',
              ownerId: '0'
            }
          );
          $this.parent.confirm = false;
        })
      }
      $this.parent.confirm = true;
    },
    doEventDel(id) {
      let $this = this;
      return new Promise(function (resolve, reject) {
        let params = {
          ENTITY: 'uni_events',
          ID: id,
        };
        let method = 'entityItemDel';
        var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          resolve(res);
          // }
        }, "json");
      });
    },
    viewEvent(item){
      let href = `https://${this.parent.portal}/company/personal/user/${this.parent.user_current.ID}/calendar/?EVENT_ID=${item.PROPERTY_VALUES.id}`;
      window.open(href, '_blank'); // <- This is what makes it open in a new window.
    },
    //Сертификаты
    certAdd(index){
      this.certs_dialog = true;
      this.currentEventIndex=index;
    },
    certSelect(item){
      let $this=this;
      let eventItem = $this.parent.listEvents[this.currentEventIndex];
      if(!eventItem.PROPERTY_VALUES.flags.certs){
        eventItem.PROPERTY_VALUES.flags.certs = [];
      }
      if(!eventItem.PROPERTY_VALUES.flags.certs.find(el=>{return el.I==item.I})) {
        let uid = this.parent.user_current.ID;
        item.uid=uid;
        eventItem.PROPERTY_VALUES.flags.certs.push(item);
        $this.updateEventAttendees(eventItem,'flags', true);
      }
    },
    loadMyCerts(){
      let $this=this;
      return new Promise(function (resolve, reject) {
        $this.parent.getProfileByUserId($this.parent.user_current.ID).then((profile) => {
          if($this.parent.isJSON(profile.PROPERTY_VALUES.certs)){
            resolve(JSON.parse(profile.PROPERTY_VALUES.certs));
          }else{
            resolve([]);
          }
        }, error => {
          console.log(error);
          resolve([]);
        });
      });
    },
    certDel(eventItem, ind) {
      let $this=this;
      $this.parent.title_confirm = this.message[this.lang].remove;
      $this.parent.message_confirm = this.message[this.lang].cert_del;
      $this.parent.do_confirm = ()=>{
        eventItem.PROPERTY_VALUES.flags.certs.splice(ind,1);
        $this.updateEventAttendees(eventItem,'flags', true);
        $this.parent.confirm=false;
      };
      $this.parent.confirm=true;
    },
    exportEvent(eventItem) {
      this.loadBids(eventItem).then(()=>{
        let attendees = this.parent.isJSON(eventItem.PROPERTY_VALUES.attendees) ? JSON.parse(eventItem.PROPERTY_VALUES.attendees) : [];
        let ids_bids = eventItem.bids.map(el=>{return el.PREVIEW_TEXT})
        let ids = [...attendees, ...ids_bids, eventItem.PREVIEW_TEXT]
        ids = Array.from(new Set(ids)); //уберем возможные повторения
        ids = ids.filter(el=>{return !this.eventUsers[el]})
        eventItem.loading_exp=true;
        if(ids.length>0){
          this.parent.getUsersByIds(ids).then(users => {
            // console.log('::parent.getUsersByIds res=',users)
            for(let i in users){
              if(!this.eventUsers[users[i].id]){
                this.eventUsers[users[i].id]=users[i];
              }
            }
            this.doExportEvent(eventItem, attendees);
          })
        }else{
          this.doExportEvent(eventItem, attendees);
        }
      });

    },
    doExportEvent(eventItem, attendees){
      console.log('::exportEvent ', eventItem, this.eventUsers)
      let forXlsx=[];
      let header={
        A:eventItem.NAME,
        B:eventItem.DATE_ACTIVE_FROM,
        C:eventItem.DATE_ACTIVE_TO
      };
      forXlsx.push(header);

      for(let user_id of attendees){
        user_id = parseInt(user_id);
        let row={
          A:'',
          B:this.message[this.lang].eve_attende,
          C: this.eventUsers[user_id].LAST_NAME,
          D: this.eventUsers[user_id].NAME,
          E: this.eventUsers[user_id].SECOND_NAME,
        };
        forXlsx.push(row);
      }

      forXlsx.push({A:'', B:'', C:'', D:'', E:''});

      for(let bid of eventItem.bids){
        let user_id = parseInt(bid.PREVIEW_TEXT);
        let row={
          A:'',
          B:this.message[this.lang].eve_bid,
          C: this.eventUsers[user_id].LAST_NAME,
          D: this.eventUsers[user_id].NAME,
          E: this.eventUsers[user_id].SECOND_NAME,
        };
        forXlsx.push(row);
      }


      let Head =[];
      Head.push([[]]);

      let ws = XLSX.utils.aoa_to_sheet(Head);
      let wscols = [
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
      ];
      ws['!cols'] = wscols;

      XLSX.utils.sheet_add_json(ws, forXlsx, {
        skipHeader:true,
        origin:0
      });

      let fname = 'event_'+eventItem.ID+'.xlsx';
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'lesson');

      let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
      saveAs(new Blob([this.parent.s2ab(wbout)],
        {type: "application/octet-stream"}), fname);

      eventItem.loading_exp=false;
    },
    /**
   * Опросы
   */
  resQuizDel(arg) {
      console.log('::resQuizDel ', arg);
      let $this = this;
      $this.mergedList[arg.index].loading = true;
      const index = $this.mergedList.findIndex((el) => {
        return el.ID == arg.id;
      });
      if (index >= 0) {
        $this.mergedList[index].STUFF = '';
        $this.mergedList[index].ID = null;
        $this.$nextTick(() => {
          $this.mergedList[index].ID = arg.id;
        });
      }
      $this.mergedList[arg.index].loading = false;

    },
    sendQuiz(arg) {
      console.log(':-3-:sendQuiz arg=', arg);
      let $this = this;
      $this.mergedList[arg.index].loading = true;
      $this.mergedList[arg.index].loading = false;
      const index = $this.mergedList.findIndex((el) => {
        return el.ID == arg.id;
      });
      if (index >= 0) {
        $this.mergedList[index].STUFF = arg.results;
        $this.mergedList[index].ID = null;
        $this.$nextTick(() => {
          $this.mergedList[index].ID = arg.id;
        });
      }
    },
    saveQuiz(res) {
      console.log('::saveQuiz res=', res);
      let $this = this;
      this.addQuiz = false;
      this.loadEvents();
      
    },
    deleteQuiz(arg) {
      let $this = this;
      console.log('::deleteQuiz ', arg);
      this.addQuiz = false;
      if (arg.index === 0 || arg.index > 0) {
        $this.mergedList.splice(arg.index, 1);
      }
      this.addQuiz = false;
    },

  },
  components: {
    quiz,
  }
  


}
</script>

<style scoped>

</style>
