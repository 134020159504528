<template>
    <div>
        <div class="q-pa-md" >
            <q-list v-if="list && list.length">
                <q-item>
                    <q-item-section avatar>
                        <q-btn icon="check"
                            color="primary" flat no-caps rounded :label="message[lang].degree_checkall_btn" @click="checkAll()" >
                        </q-btn>
                    </q-item-section>
                    <q-item-section>  </q-item-section>

                    <q-item-section side top>
                        <q-btn :disable="!isChecked" icon="send"
                            color="primary" flat no-caps rounded :label="message[lang].degree_status_btn" @click="sendMessages" >
                            <q-tooltip class="bg-primary h-tt">{{message[lang].degree_status_btn}}: {{message[lang].degree_status_msg}}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </q-item>
                
                <q-item v-for="item in list" :key="item.id"  v-ripple>
                    <q-item-section>
                        <q-list bordered padding>
                            <div class="h4 q-pa-sm text-accent">
                                {{message[lang].the_360}}:
                                <span class="text-primary q-mx-sm"> {{item.name}} </span>
                                <span v-if="item.dates">
                                    {{item.dates}}
                                </span>    
                            </div> 
                            <q-item v-for="stud in item.students" :key="stud.id"  v-ripple>
                                <q-item-section side top>
                                    <q-checkbox :disable="showStatus(item.id, stud.id, stud.experts, item.agreeID)==0" v-model="stud.check" />
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label class="h4 text-accent">
                                    {{message[lang].persona}}: {{stud.name}}</q-item-label>
                                    <q-item-label caption class="q-gutter-sm" v-if="stud.experts">
                                        {{message[lang].experts}}:
                                        <span class="h5 text-positive" v-for="exp in stud.experts" :key="exp.id">{{exp.name}},</span>
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side top>      
                                    <span :class="showStatus(item.id, stud.id, stud.experts, item.agreeID)>0?'text-warning':'text-secondary'" >
                                        {{ message[lang].degree_status[showStatus(item.id, stud.id, stud.experts, item.agreeID)]}}
                                    </span>
                                </q-item-section>

                            </q-item>
                        </q-list>
                    </q-item-section>
                </q-item>
            </q-list>
        </div>
        <q-inner-loading :showing="loading">
            <q-spinner-oval size="5em" color="primary" />
        </q-inner-loading>
         <q-inner-loading :showing="loading_send">
            <q-spinner-comment size="5em" color="primary" />
        </q-inner-loading>
    </div>
</template>

<script>
// import { messageObj } from "src/helpers/message";
    export default {
        name: "Degree360StatusList",
        props: {
            parent: Object,
            lang: String,
        },
        data() {
            return {
                loading: false,
                loading_send: false,
                list:[],

                message: this.parent.dictionary //messageObj,
            }
        },
        mounted() {
          this.loadSelfDegrees();  
        },
        computed: {
          getChecked(){
             let ret = this.list.flatMap(item => item.students.filter(student => student.check));
             return ret;
          },
          isChecked(){
              return this.getChecked.length > 0;
          } 
        },
        methods: {
            checkAll(){
                let check=!this.isChecked;
                for(let item of this.list){
                    for(let stud of item.students){
                        if(this.showStatus(item.id, stud.id, stud.experts, item.agreeID)>0){
                            stud.check = check;
                        }
                    }
                    
                }
                
            },
            sendMessages(){
                let $this=this;
                this.loading_send = true;
                let checked = this.getChecked;
                for(let item of checked){
                    console.log(':: sendMessages item=', item.id, this.message[this.lang].degree_status_msg, this.parent.user_current);
                    this.parent.notifyLK(item.id, this.message[this.lang].degree_status_msg, this.parent.user_current);
                    item.check = false;
                }
                setTimeout(() => {
                    this.loading_send = false;               
                    $this.parent.notify_title = this.message[this.lang].attention;
                    $this.parent.notify_text = this.message[this.lang].degree_msg_sended;
                    $this.parent.notify = true;
                }, 3000);

            },
            loadSelfDegrees(){
                this.loading = true;
                let filter = {
                    1: { LOGIC: "AND", '>DETAIL_PICTURE': '0', },
                };
                this.parent.loadPrograms("degree360",500,null,null,null,null,null,filter).then((res) => {
                    // console.log("::loadPrograms degree360 res=", res);
                    if(res){
                        for(let item of res){
                            // console.log("::loadPrograms degree360 item=", item);
                            let DETAIL_TEXT = this.parent.isJSON(item.DETAIL_TEXT) ? JSON.parse(item.DETAIL_TEXT) : item.DETAIL_TEXT;
                            // console.log("::loadPrograms degree360 DETAIL_TEXT=", DETAIL_TEXT);

                            let degreeName = this.parent.restoreQuotes(DETAIL_TEXT.name);
                            let agreeID = DETAIL_TEXT.agreeID;
                            let students = DETAIL_TEXT.students;
                            let dates = item.DATE_ACTIVE_FROM ? this.parent.customFormatter(item.DATE_ACTIVE_FROM)+' - '+this.parent.customFormatter(item.DATE_ACTIVE_TO) : "";
                            
                            students.forEach(item => {
                                item.check = false;
                            });

                            this.list.push({id:item.ID, name:degreeName, dates:dates, agreeID:agreeID, students:students});


                        }
                    }
                    
                    this.loading = false;
                });
            },
            showStatus(degree_id, stud_id, experts, item_agreeID){
                let status = 0;
                if(!experts || experts.length == 0){
                    //нет назначенных экспертов
                    status = 1;
                }else if(!item_agreeID || !item_agreeID[stud_id]){
                    //не назначен согласующий
                    return 2;
                }else if(!item_agreeID[stud_id].agreed){
                    //не согласовано
                    return 3;
                }
                // console.log('::showStatus ', status, degree_id, stud_id, experts, item_agreeID);
                return status;
            }

        }
    }
</script>