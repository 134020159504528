<template>
  <span>
<!--  <q-spinner-oval v-if="isLoading || parent.file_download_loading"  color="positive" size="30px"/>-->
  <q-btn v-if="id"
         flat
         round
         color="positive"
         :icon="isClone?'file_copy':'file_download'"
         @click="exportCourses"
         :loading="isLoading || parent.file_download_loading"
  >
    <q-tooltip class="bg-primary h-tt">{{isClone?message[lang].clone:message[lang].export}}</q-tooltip>
  </q-btn>
<!--    <q-spinner v-else-if="list && isLoading"-->
<!--               color="positive"-->
<!--               class="q-mt-md"-->
<!--               size="2em"-->
<!--    />-->
<!--  <q-img v-else-if="list" style="cursor: pointer; height: 30px; width: 30px; margin-top:16px" @click="exportCourses" src="~assets/secondary/doc/export.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].export}}</q-tooltip></q-img>-->
    <q-btn padding="xs" v-else-if="list && isLoading" size="lg" flat round color="positive" icon="file_download" @click="exportCourses"><q-tooltip class="bg-primary h-tt">{{message[lang].export}}</q-tooltip></q-btn>
</span>
</template>
<script>

    export default {
        props:{
          lang:String,
          id:String,
          list:Object,
          parent:Object,
          isClone:Boolean
        },
        data: function () {
            return {
                isLoading:false,
                files:[],
                courseslist:[],
                course:'',
                message:this.parent.dictionary,
                // message:{
                //     ru:{
                //       clone:"Создать копию",
                //         export:"Экспорт",
                //         modal_title:"Экспортировать",
                //         modal_message:"Вы хотите экспортировать весь раздел? Это может занять некоторое время.",
                //     },
                //     en:{
                //       clone: "Create a copy",
                //         export:"Export",
                //         modal_title:"Export",
                //         modal_message:"Do you want to export the whole section? It might take some time.",
                //     },
                //   ua:{
                //     clone: "створити копію",
                //     export:"Експорт",
                //     modal_title:"Експортувати",
                //     modal_message:"Ви хочете експортувати весь розділ? Це може зайняти деякий час.",
                //   },
                //   fr:{
                //     export:"Exporter",
                //     modal_title:"Exporter",
                //     modal_message:"Voulez-vous exporter toute la section? Cela peut prendre du temps.",
                //   },
                //   it:{
                //     clone: "crea una copia",
                //     export:"Esporta",
                //     modal_title:"Esporta",
                //     modal_message:"Vuoi esportare l’intera sessione? Potrebbe volerci del tempo.",
                //   },
                //   tr:{
                //     export:"İhracat",
                //     modal_title:"İhracat",
                //     modal_message:"Tüm bölümü dışa aktarmak istiyor musunuz? Biraz zaman alabilir.",
                //   },
                //   de:{
                //     clone:"Kopie erstellen",
                //     export:"Export",
                //     modal_title:"Export",
                //     modal_message:" Möchten Sie den gesamten Abschnitt exportieren? Es kann einige Zeit dauern.",
                //   },
                //   pl:{
                //     clone: "Utwórz kopię",
                //     export:"Eksport",
                //     modal_title:"Eksport",
                //     modal_message:"Czy chcesz wyeksportować całą sekcję? To może trochę potrwać.",
                //   },
                //   pt:{
                //     clone: "criar uma cópia",
                //     export:"Exportar",
                //     modal_title:"Exportar",
                //     modal_message:"Quer exportar toda a seção? Isso pode levar algum tempo.",
                //   },
                //   es:{
                //     clone: "Crear una copia",
                //     export:"Exportar",
                //     modal_title:"Exportar",
                //     modal_message:"¿Quieres exportar toda la sección? Puede que lleve algún tiempo.",
                //   },
                //   vn:{
                //     clone: "Tạo một bản sao",
                //     export:"Xuất khẩu",
                //     modal_title:"Xuất khẩu",
                //     modal_message:"Bạn có muốn xuất toàn bộ phần này không? Nó có thể mất một thời gian.",
                //   },

                // },
            }
        },
        mounted: function(){
            // console.log('::mounted id=',this.id, ' this.list=',this.list);
            this.initList();
        },
        methods: {
            initList(){
                if(this.id){
                    this.courseslist = [{ID:this.id}];
                }else{
                    this.courseslist = this.list;
                }
            },
            exportCourses() {
              if(this.courseslist && Object.keys(this.courseslist).length > 1){

                // eslint-disable-next-line vue/no-mutating-props
                  this.parent.modal_title=this.message[this.lang].modal_title;
                // eslint-disable-next-line vue/no-mutating-props
                  this.parent.modal_message= this.message[this.lang].modal_message;
                // eslint-disable-next-line vue/no-mutating-props
                  this.parent.modal_filterFn = null;
                // eslint-disable-next-line vue/no-mutating-props
                  this.parent.okModal = ()=>{ this.exportCourse(null);}
                  this.parent.showModal();

              }else{
                  this.exportCourse(null);
              }

            },

            exportCourse(jsonList) {
                this.parent.hideModal();
                let $this = this;
                console.log('::exportCourse ',this.courseslist,jsonList);
                this.isLoading = true;
                let course_id =  0;
                if (this.courseslist && Object.keys(this.courseslist).length > 0) {
                    let item = this.courseslist.pop();
                    course_id = parseInt(item.ID);
                }
                //
                jsonList = jsonList ? jsonList : [];
                if(course_id){
                    this.parent.getCourseById(course_id).then(course => {
                        console.log('::getCourseById course=', course);
                        let program = course.PROPERTY_VALUES.course;
                        if(program){
                            program = JSON.parse(program);
                            if(course.PROPERTY_VALUES.flags.tree){
                                program = $this.parent.flattenArray(program);
                            }
                            console.log('::program=', program);
                            let lessons = [];
                            let tests = [];
                            for(let item of program){
                                // let item = program[i];
                                console.log('::item.type=', item.type, (item.type === 'lesson'), item);
                                if(item.type && item.type === 'lesson'){
                                  lessons.push(item);
                                  console.log('::lessons++', lessons);
                                }else if(item.type && item.type === 'test'){
                                    tests.push(item);
                                  console.log('::tests++', tests);
                                }

                            }

                            console.log('::lessons=', lessons, ' tests=',tests);

                            if(lessons.length > 0){
                                $this.exportLesson([...lessons]).then(res=>{
                                    console.log('::exportLesson res=',res);
                                    course.lessons_exp = res

                                    if(tests.length > 0){
                                        $this.exportTest([...tests]).then(res=>{
                                            console.log('::exportTest res=',res, jsonList);
                                            course.tests_exp = res;
                                            jsonList.push({id: course_id, data: course});
                                            $this.exportCourse(jsonList);
                                        });
                                    }else{
                                        jsonList.push({id: course_id, data: course});
                                        $this.exportCourse(jsonList);
                                    }

                                });
                            }else{
                                // jsonList.push({id: course_id, data: course});
                                // $this.exportCourse(jsonList);
                              if(tests.length > 0){
                                $this.exportTest([...tests]).then(res=>{
                                  console.log('::exportTest res=',res, jsonList);
                                  course.tests_exp = res;
                                  jsonList.push({id: course_id, data: course});
                                  $this.exportCourse(jsonList);
                                });
                              }else{
                                jsonList.push({id: course_id, data: course});
                                $this.exportCourse(jsonList);
                              }
                            }
                        }


                    }, err => {
                        console.error(':: err=', err);
                    });
                }else{
                    this.isLoading = false;
                    console.log(':: end jsonList=',jsonList);
                  if(this.isClone){
                    this.$emit('cloneExpList', jsonList);
                  }else {
                    this.$emit('saveExpList', jsonList);
                  }
                  this.initList();
                }
            },
            ////////////
            // lessons
            ////////////
            exportLesson(list, jsonListLessons) {
                let $this = this;
                jsonListLessons = jsonListLessons ? jsonListLessons : [];
                console.log('::exportLesson ', [...list], jsonListLessons);
                return new Promise(function(resolve, reject) {
                    let lesson_id = 0;
                    if (list && Object.keys(list).length > 0) {
                        let item = list.pop();
                        lesson_id = parseInt(item.id);
                    }
                    //
                    console.log('::exportLesson lesson_id=',lesson_id);
                    if (lesson_id) {
                        $this.parent.getLessonById(lesson_id).then(lesson => {
                            console.log('::exportLesson lesson_id=',lesson_id, lesson);
                            //проверим на наличие файлов в ответах
                            let files_id = [];
                            let files = [];
                            if (lesson?.PROPERTY_VALUES?.files) {
                                let files = JSON.parse(lesson.PROPERTY_VALUES.files);
                                // console.log(':: files=', files);
                                for (let i in files) {
                                    let file = files[i];
                                    if (file.I) {
                                        files_id.push(file.I);
                                    }
                                }
                            }

                            if (Object.keys(files_id).length > 0) {
                                $this.loadFiles(lesson_id, files_id, lesson, files,jsonListLessons).then(res => {
                                    $this.exportLesson(list, res).then(res=>{
                                        resolve(res);
                                    });
                                });
                            } else {
                                let json = JSON.stringify(lesson);

                                jsonListLessons.push({id: lesson_id, data: json});
                                $this.exportLesson(list, jsonListLessons).then(res=>{
                                    resolve(res);
                                });
                                // }
                            }
                        }, err => {
                            console.error(':: err=', err);
                        });
                    } else {
                        console.log('::exportLesson конец jsonListLessons=',jsonListLessons);
                        resolve(jsonListLessons);
                    }
                });
            },
            loadFiles(lesson_id, files_id, lesson, files, jsonListLessons){
                console.log('::loadFiles lesson_id=',lesson_id, ' files_id=',files_id,' lesson=', lesson);
                let $this = this;
                return new Promise(function(resolve, reject) {
                    if (Object.keys(files_id).length > 0) {
                        let file_id = files_id.pop();
                        $this.parent.loadFile(file_id).then(file => {
                            // console.log(':: lesson_id=%s file_id=%s', lesson_id, file_id);
                          if(file) {
                            files.push({ID: file.ID, NAME: file.NAME, DETAIL_TEXT: file.DETAIL_TEXT});
                          }
                          $this.loadFiles(lesson_id, files_id, lesson, files, jsonListLessons).then(res => {
                                  resolve(res);
                              });
                        }, err => console.error(err));
                    } else {
                        lesson.files = files;
                        // console.log(':end-1: files loaded lesson_id=', lesson_id, ' lesson=', lesson);
                        let json = JSON.stringify(lesson);
                        jsonListLessons.push({id: lesson_id, data: json});
                        resolve(jsonListLessons);
                    }
                });
            },
            ////////////
            // tests
            ///////////
            exportTest(list, jsonListTests) {
                let $this = this;
                jsonListTests = jsonListTests ? jsonListTests : [];
                console.log('::exportTest ', [...list]);
                return new Promise(function(resolve, reject) {
                    let test_id = 0;
                    if (list && Object.keys(list).length > 0) {
                        let item = list.pop();
                        test_id = parseInt(item.id);
                    }
                    //
                  console.log('::test_id=', test_id);
                    if (test_id) {
                        $this.parent.getTestById(test_id).then(test => {
                            console.log('::test=', test);
                            //проверим на наличие файлов в ответах
                            let files_id = [];
                            let files = [];
                            if (test && test.PROPERTY_VALUES.answers) {
                                let answers = JSON.parse(test.PROPERTY_VALUES.answers);
                                // console.log(':: answers=', answers);
                                for (let i in answers) {
                                    let answer = answers[i];
                                    for (let j in answer) {
                                        let question = answer[j];
                                        if (question.file) {
                                            files_id.push(question.file);
                                        }
                                    }
                                }
                            }

                            if (Object.keys(files_id).length > 0) {
                                $this.loadTestFiles(test_id, files_id, test, files, jsonListTests).then(res => {
                                    $this.exportTest(list, res).then(res=>{
                                        resolve(res);
                                    });
                                },err=>{console.log(err)});
                            } else {
                                let json = JSON.stringify(test);
                                jsonListTests.push({id: test_id, data: json});
                                $this.exportTest(list, jsonListTests).then(res=>{
                                    resolve(res);
                                });

                            }
                        }, err => {
                            console.error(':: err=', err);
                            resolve(false)
                            
                        });
                    } else {
                        resolve(jsonListTests);
                    }
                });
            },
            loadTestFiles(test_id, files_id, test, files, jsonListTests){
                // console.log('::loadFiles test_id=',test_id);
                let $this = this;
                return new Promise(function(resolve, reject) {
                    if (Object.keys(files_id).length > 0) {
                        let file_id = files_id.pop();
                        $this.parent.loadFile(file_id).then(file => {
                            // console.log(':: test_id=%s file_id=%s', test_id, file_id);
                            files.push({ID: file.ID, NAME: file.NAME, DETAIL_TEXT: file.DETAIL_TEXT});
                            $this.loadTestFiles(test_id, files_id, test, files, jsonListTests).then(res => {
                                resolve(res);
                            });
                        }, err => console.error(err));
                    } else {
                        test.files = files;
                        let json = JSON.stringify(test);
                        // console.log(':end: files loaded test_id=', test_id);

                        jsonListTests.push({id: test_id, data: json});
                        resolve(jsonListTests);
                    }
                });
            },


        },
        components: {

        }
    }
</script>

<style scoped>


</style>

