<template>
<!--<div style="height: 90px">-->
<div style="height: 90px; display: flex; justify-content: center; align-items: center;">
  <q-btn v-if="!loading"
         icon="backup"
         :size="dense?'md':'lg'"
         :loading="loading"
         :rounded="label!==null"
         :round="label===null"
         no-caps
         color="primary"
         class="q-pa-xs"
         @click="uploadFile"
         :label="label===null?'':(label?label:message[lang].upload)"/>

  <q-circular-progress v-else
                       show-value
                       :font-size="dense?'10px':'14px'"
                       class="text-primary q-ma-md"
                       :value="progressValue"
                       :size="dense?'50px':'85px'"
                       :thickness="dense?0.12:0.19"
                       color="primary"
                       track-color="positive"
  >
    <q-spinner-hourglass v-if="progressValue==0 || progressValue==100"
                         color="primary"
                         :size="dense?'1em':'2em'"
                         class="q-mr-xs"
    />
    <span v-else>{{progressValue}}%</span>
  </q-circular-progress>
</div>
</template>

<script>
export default {
  props:{
    parent:Object,
    lang:String,
    url:String,
    accept:String,
    label:String,
    dense:Boolean
  },
  data: function () {
    return {
      loading:false,
      progressValue: 0,
      message:this.parent.dictionary,
      // message: {
      //   ru: {
      //     upload: "Загрузить",
      //   },
      //   en: {
      //     upload: "Upload",
      //   },
      //   ua: {
      //     upload: "Завантажити",
      //   },
      //   fr: {
      //     upload: "Charger",
      //   },
      //   it: {
      //     upload: "Caricare",
      //   },
      //   es: {
      //     upload: "Cargar",
      //   },
      //   de: {
      //     upload: "Hochladen",
      //   },
      //   pt: {
      //     upload: "Carregar",
      //   },
      //   tr: {
      //     upload: "Yükle",
      //   },
      //   pl: {
      //     upload: "Ładowanie",
      //   },
      //   vn: {
      //     upload: "Tải",
      //   },


      // }
    }
  },
  methods:{
    uploadFile() {
      // console.log('::uploadFile',this.parent.access_token)
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = this.accept; // Только файлы с расширением .zip
      fileInput.addEventListener('change', async () => {
        const file = fileInput.files[0];
        const filename = file.name;
        const uri = `${this.parent.app_server_url}api/${this.url}&filename=${filename}&portal=${this.parent.portal}&client_key=${this.parent.member_id}&access_token=${this.parent.access_token}`;
        // console.log('::uploadFile uri=',uri)
        this.loading=true;
        const reader = new FileReader();

        reader.onload = async () => {
          const data = reader.result;

          // Отправка данных на сервер
          const xhr = new XMLHttpRequest();
          xhr.open('POST', uri, true); // false для синхронного запроса
          xhr.setRequestHeader('Content-Type', 'application/octet-stream');

          xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              const percentComplete = (event.loaded / event.total) * 100;
              // console.log('Прогресс передачи данных: ' + percentComplete + '%');
              this.progressValue = parseInt(percentComplete.toFixed(0));
            }
          });

          xhr.onreadystatechange = () => {
            console.log('xhr.readyState=', xhr.readyState);
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                this.$emit('streamingSuccess', xhr.response);
              } else {
                this.$emit('streamingFailed', xhr.response);
              }
              this.loading=false;
            }
          };

          xhr.send(data);
        };

        reader.readAsArrayBuffer(file);

      });

      fileInput.click();
    },
  }
}
</script>

<style scoped>

</style>
