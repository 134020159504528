/**
    * MONDAY
      client_id: ID клиента
      user_id: ID пользователя (70020447)
      account_id: ID аккаунта (26166859)
      slug: слаг аккаунта (brusnika-solutions)
      app_id: ID приложения (10274382)
      app_version_id: ID версии приложения (10505283)
      install_id: ID установки (-2)
      is_admin: пользователь является администратором (true)
      is_view_only: пользователь имеет права только на просмотр (false)
      is_guest: пользователь является гостем (false)
      user_kind: тип пользователя (admin)
    */
    export function mondaySetToken($this){
        return new Promise(async resolve=>{
          const url=`${$this.apiurl}api/monday-sessionToken`;
          $this.POST(url, {}, async function (res) {
            console.log('::monday-sessionToken res=',res);
            if(res){
              $this.monday = mondaySdk();
              $this.monday.setApiVersion("2023-10");
              const user = await $this.mondayGetUserCurrent();
              resolve(true)
            }else{
              console.log(':: monday not selected')
              resolve(false);
            }
          });
           
  
        });
  
    };

    export function mondayGetUserCurrent($this){
        return new Promise(async resolve=>{
         try {
              const response = await $this.monday.api(`query {
                me {
                  account {
                    id
                    show_timeline_weekends
                    tier 
                    slug
                    plan {
                      period
                    }
                  }
                  id
                  name
                  email
                  photo_thumb_small
                  title
                  birthday
                  country_code
                  current_language
                  join_date
                  enabled
                  is_admin
                  is_guest
                  is_pending
                  is_view_only
                  last_activity
                  location
                  mobile_phone
                  phone
                  time_zone_identifier
                  created_at
                }
              }`);
              const user = response.data.me;
              console.log('::mondayGetUserCurrent user=',user);
              //
              let user_current = {};
              user_current.ID = user.id;
              user_current.email =user.email;
              member_id = 'monday-'+user.account.id;
              portal = 'monday-'+user.account.slug;
              isAdmin = user.is_admin;
              const [name, last_name] = user.name.split(' ');
              user_current.NAME = name;
              user_current.LAST_NAME = last_name;
              user_current.PERSONAL_PHOTO = user.photo_thumb_small;
              lang = user.current_language;
  
              resolve(user_current);
            } catch (error) {
              console.error(error);
              resolve(false)
            }
            
        });
      };
      
      export function getUsersMonday($this, isActive, setFilter){
        console.log('::getUsersMonday ', isActive, setFilter)
        let users = [];
        return new Promise(async (resolve, reject) => {
          let query = `query {
                  users (limit: 5000) {
                  id
                  name
                  email
                  photo_thumb_small
                }
              }`;
            if(setFilter){
              if(setFilter["%PROPERTY_last_name"] || setFilter["%PROPERTY_name"] ){
                let str = setFilter["%PROPERTY_last_name"] || setFilter["%PROPERTY_name"];
                str = str.substr(1, str.length-2);
                query = `query {
                  users (limit: 100, name:"${str}") {
                  id
                  name
                  email
                  photo_thumb_small
                }
              }`;
              }
  
            }
  
           try {
              console.log('::getUsersMonday query=',query);
              const response = await $this.monday.api(query);
              console.log('::getUsersMonday response=',response);
  
              if(response?.data?.users?.length>0){
                for(let item of response.data.users){
                  let user = {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    deps:[],
                    photo: item.photo_thumb_small,
                    type:'',
                    pos:''
                  }
  
                  users.push(user);
                  // if(setFilter){
                  //   $this.modal_list.push({text:user.name, value: user.id})
                  // }
                }
                $this.richUsersByDeps(users).then(res=>{
                  console.log('::getUsersMonday users=',users)
                  console.log('::getUsersMonday res=',res)
                  resolve(res);
                });
              }else{
                resolve([]);
              }
            } catch (error) {
              console.error(error);
              resolve([])
            }
        });
  
      };