<template>
  <div>
    <div class="text-warning h4" v-if="degree360 && degree360.readonly">
      <q-icon name="info" />
      {{ message[lang].degree_readonly_info }}
    </div>

    <div class="text-accent h5">
      <q-icon name="info" />
      {{ message[lang].degree_self_info }}
    </div>
    <q-card class="bg-info text-accent" v-if="degree360">
      <q-card-section>
        <div class="text-positive h4">
          {{ message[lang].add_experts }}
          <div class="text-accent h5">
            <q-icon name="info" />
            {{ message[lang].degree_add_experts_info }}
          </div>
        </div>
        <div class="row q-my-sm q-gutter-x-sm">
          <div>
            <q-btn
              size="xs"
              outline
              rounded
              color="secondary"
              :label="message[lang].new"
              @click="expertsAdd(my_uid_index)"
            />
          </div>
          <div>
            <q-btn
              size="xs"
              outline
              rounded
              color="secondary"
              :label="message[lang].slaves"
              @click="slavesAdd(my_uid_index)"
            />
          </div>
          <div>
            <q-btn
              size="xs"
              outline
              rounded
              color="secondary"
              :label="message[lang].chief"
              @click="chiefAdd(my_uid_index)"
            />
          </div>
        </div>
        <div class="text-positive h4" v-if="degree360.students[my_uid_index].experts && degree360.students[my_uid_index].experts.length">
          <!-- Начальники -->
          <div class="row justify-between" v-if="degree360.students[my_uid_index].experts.filter(
                (el) => el.type === 'ch'
              ).length">
            <div class="col-3">
              {{ message[lang].chief }}
            </div>
          </div>
          <div class="q-gutter-x-sm text-negative">
            <q-chip
              v-for="(ch_item, ch_index) in degree360.students[my_uid_index].experts.filter(
                (el) => el.type === 'ch'
              )"
              :key="ch_index"
              :color="ch_item.color"
              outline
              :removable="!degree360.readonly"
              @remove="parent.delExpert(degree360.students[my_uid_index], ch_item)"
              size="md"
              icon="account_circle"
            >
              {{ ch_item.name }}
            </q-chip>
          </div>
          <!-- Коллеги -->
          <div class="row justify-between" v-if="degree360.students[my_uid_index].experts.filter(
                (el) => el.type === 'em'
              ).length">
            <div class="col-3">
              {{ message[lang].selectusers_label }}
            </div>
            <div class="col-3">
              <q-btn
                :disable="degree360.readonly"
                flat
                rounded
                icon="clear"
                color="accent"
                :label="message[lang].em_clear"
                @click="parent.expertsClear(degree360.students[my_uid_index], 'em')"
              />
            </div>
          </div>
          <div class="q-gutter-x-sm text-negative">
            <q-chip
              v-for="(exp_item, exp_index) in degree360.students[my_uid_index].experts.filter(
                (el) => el.type === 'em'
              )"
              :key="exp_index"
              :color="exp_item.color"
              outline
              :removable="!degree360.readonly"
              @remove="parent.delExpert(degree360.students[my_uid_index], exp_item)"
              size="md"
              icon="account_circle"
            >
              {{ exp_item.name }}
            </q-chip>
          </div>
          <!-- Подчиненные -->
          <div class="row justify-between" v-if="degree360.students[my_uid_index].experts.filter(
                (el) => el.type === 'sl'
              ).length">
            <div class="col-3">
              {{ message[lang].slaves }}
            </div>
            <div class="col-3">
              <q-btn
                :disable="degree360.readonly"
                flat
                rounded
                icon="clear"
                color="accent"
                :label="message[lang].em_clear"
                @click="parent.expertsClear(degree360.students[my_uid_index], 'sl')"
              />
            </div>
          </div>
          <div class="q-gutter-x-sm text-negative">
            <q-chip
              v-for="(sl_item, slave_index) in degree360.students[my_uid_index].experts.filter(
                (el) => el.type === 'sl'
              )"
              :key="slave_index"
              :color="sl_item.color"
              outline
              :removable="!degree360.readonly"
              @remove="parent.delExpert(degree360.students[my_uid_index], sl_item)"
              size="md"
              icon="account_circle"
            >
              {{ sl_item.name }}
            </q-chip>
          </div>
        </div>
        <!-- Согласующий -->
        <div class="row items-center justify-between">
            <div class="col-4 h4 text-positive text-left" size="sm">
              <q-btn
                  size="xs"
                  outline
                  rounded
                  icon-right="add"
                  color="secondary"
                  :label="message[lang].agree_user"
                  @click="agreeAdd"
              />
            </div>
            <div class="col-12 h4 text-positive text-left q-my-sm">
              <div v-if="degree360?.agreeID?.[parent.user_current.ID]">
                <div>{{message[lang].agree_user}}</div>
                {{ degree360?.agreeID?.[parent.user_current.ID].agree_name }}
              </div>
            </div>
            <div class="col-12 text-accent h6">
                <q-icon name="info" />
                {{ message[lang].degree_agree_user_info }}
            </div>
        </div>
      </q-card-section>
      <q-card-actions align="right">
         <!-- Сохранить -->
            <q-btn
                icon="save"
                color="secondary"
                no-caps
                unelevated
                rounded
                :label="message[lang].save_comp"
                @click="saveNclose"
            />
        </q-card-actions>
    </q-card>

    <q-inner-loading :showing="loading">
      <q-spinner-oval size="5em" color="positive" />
    </q-inner-loading>
  </div>
</template>
<script>
// import { messageObj } from "src/helpers/message";
import dayjs from "dayjs";
export default {
  name: "degree360Self",
  props: {
    degree360Item: Object,
    degree360Index: Number,
    taskItem: Object,
    parent: Object,
    lang: String,
    back: String,
  },
  data() {
    return {
      my_uid_index: 0,
      loading: false,
      degree360: null,
      departments: [],
      message: this.parent.dictionary //messageObj,
    };
  },
  mounted() {
    console.log("::mounted self=", this.taskItem, this.degree360Item);
    if (this.taskItem) {
      this.degree360 = this.parent.isJSON(this.taskItem.DETAIL_TEXT) ? JSON.parse(this.taskItem.DETAIL_TEXT) : this.taskItem;
    }else if (this.degree360Item) {
      this.degree360 = this.parent.isJSON(this.degree360Item.DETAIL_TEXT) ? JSON.parse(this.degree360Item.DETAIL_TEXT) : this.degree360Item;
    } else{
      console.error(':: нет черновика')
    }
    this.my_uid_index = this.degree360.students.findIndex((el) => el.id == this.parent.user_current.ID);
    console.log("::mounted degree360=",this.my_uid_index, this.degree360);

    // else {
    //     let student = {
    //         id: this.parent.user_current.ID,
    //         value: this.parent.user_current.ID,
    //         dep: this.parent.user_current.UF_DEPARTMENT,
    //         name: this.parent.user_current.NAME + ' ' + this.parent.user_current.LAST_NAME,
    //         text: this.parent.user_current.NAME + ' ' + this.parent.user_current.LAST_NAME,
    //         experts: [],
    //     };
    //     let title = this.message[this.lang].degree360_self+': '+student.name;
    //     if(this.taskItem){
    //       title += ' ('+this.taskItem.name+')';
    //     }
    //     this.degree360 = {name: title, students: [], approved:'N'};
    //     this.degree360.students = [ student ];
    // }
    this.parent.getDepartments().then((departments) => {
      this.departments = departments;
      this.loading = false;
    });
  },
  methods: {
    /**
     * Загружаем, правим и тут же записываем, что бы не пересечься с другими оцениваемыми,
     * которые тоже могут добавлять себе оценивающих в этот момент
     */
     saveNclose() {
      let $this = this;
      const degree360_id = parseInt(this.degree360.ID);
      const stud_id = this.parent.user_current.ID;
      console.log(":: saveNclose degree360_id=", degree360_id);
      this.parent.loadPrograms( "degree360", null, null,null,degree360_id).then((res) => {
        console.log(":: saveNclose get degree360 res=", res);
        if(res && res.length > 0){
          let degree360 = res[0];
          let detail_text = this.parent.isJSON(degree360.DETAIL_TEXT) ? JSON.parse(degree360.DETAIL_TEXT) : null;
          if(detail_text){
            const stud_index = detail_text.students.findIndex(el => el.id == stud_id);
            const stud_index_new = this.degree360.students.findIndex(el => el.id == stud_id);
            console.log(`:: old index=${stud_index}, new index=${stud_index_new}`);
            if(stud_index_new >= 0){
              detail_text.students[stud_index] = this.degree360.students[stud_index_new];
              let agreeID = detail_text.agreeID || {};
              agreeID[stud_id] = this.degree360.agreeID[stud_id];
              detail_text.agreeID = agreeID;

              detail_text.name = this.parent.replaceQuotes(detail_text.name);
              detail_text.description = this.parent.replaceTags(detail_text.description);

              let method = "entityItemUpdate";
              let params = {
                ENTITY: "uni_degree360",
                ID: degree360_id,
                PREVIEW_TEXT: JSON.stringify(agreeID), //согласующие {uiser_id:agree_id, ...}
                NAME: detail_text.name,
              };
              console.log(":: saveNclose params=", params);
              console.log(":: saveNclose detail_text=", detail_text);
              var url = $this.parent.bx24Init({ AJAX_ACTION: method, params: params });
              $this.parent.bx24params.DETAIL_TEXT = JSON.stringify(detail_text);
              $this.parent.b24POST( url, $this.parent.bx24params, function (res) {
                  console.log("::saveNclose res=", res);
                  //Нужно отправить уведомление согласующему
                  // if(agreeID?.[stud_id]?.agree_id){
                  //   $this.parent.isNoteSet([agreeID[stud_id].agree_id], "e360").then((checks) => {
                  //     if (Object.keys(checks).length > 0) {
                  //       let message = $this.message[$this.lang].you_agree_360;
                  //       let arBatch2 = {};
                  //       for (let uid of checks) {
                  //         arBatch2["note_" + uid] = [
                  //           "im.notify",
                  //           { to: uid, message: message, type: "SYSTEM" },
                  //         ];
                  //       }
                  //       $this.parent.callBatch(arBatch2);
                  //     }
                  //   });
                  // }
                  //закрываем модалку
                  $this.$emit("self_close");
              }, "json" );
            }
          }
        }
              
      });
    },
    
    agreeAdd(ind) {
      let experts = [];
      this.parent.usersAdd(experts, "single").then((list) => {
        if (list && list.length > 0) {
          const agree_id = list[0].id;
          const agree_name = list[0].name;
          const uid = this.parent.user_current.ID;
          this.degree360.agreeID = this.degree360.agreeID || {};
          this.degree360.agreeID[uid] = { agree_id: agree_id, agree_name: agree_name };
        }
      });
    },
    expertsAdd() {
      const stud_id = this.parent.user_current.ID;
      const ind = this.degree360.students.findIndex(el => el.id == stud_id);
      console.log(":: expertsAdd ind=", ind, this.degree360.students);
      if(ind < 0){
        return;
      }
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = [];
      this.parent.usersAdd(experts).then((list) => {
        // this.parent.getSubAccounts(list).then((res) => {
          experts = experts.concat(list);
          //пометим em|ch|sl коллеги|начальник|подчиненный
          this.degree360.students[ind] = this.degree360.students[ind] || { experts: [] };
          this.degree360.students[ind].experts =
            this.degree360.students[ind].experts || [];
          this.degree360.students[ind].experts = [
            ...this.degree360.students[ind].experts,
            ...experts.map((el) => {
              return { ...el, type: "em" };
            }),
          ];
          console.log("::expertsAdd experts=", this.degree360.students[ind].experts);
        // });
      });
    },
    // chiefAdd() {
    //   let $this = this;
    //   const stud_id = this.parent.user_current.ID;
    //   const ind = this.degree360.students.findIndex(el => el.id == stud_id);
    //   console.log(":: expertsAdd ind=", ind, this.degree360.students);
    //   if(ind < 0){
    //     return;
    //   }
    //   this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
    //   let experts = this.degree360.students[ind].experts;
    //   let stud_deps = this.degree360.students[ind].dep;
    //   console.log("::chiefAdd stud_id, stud_deps=", stud_id, stud_deps);
    //   if (stud_deps && stud_deps.length > 0) {
    //     let arBatch = {};
    //     for (let id of stud_deps) {
    //       arBatch["get_" + id] = ["department.get", { ID: id }];
    //     }
    //     $this.parent.callBatchIB(arBatch).then((result) => {
    //       console.log("::chiefAdd arBatch result=", result);
    //       let arChiefs = [];
    //       for (let i in result.result) {
    //         // console.log('::chiefAdd arBatch result=',i,result.result[i]);
    //         let item = result.result[i][0];
    //         if (item && item.UF_HEAD != stud_id && item.UF_HEAD != 0) {
    //           arChiefs.push(item.UF_HEAD);
    //         }
    //       }
    //       console.log("::chiefAdd arChiefs =", arChiefs);
    //       if (arChiefs.length > 0) {
    //         //получаем начлаьников отделов юзера
    //         $this.parent.getUsers(true, null, arChiefs).then(
    //           (users) => {
    //             console.log("::chiefAdd users =", users);
    //             for (let i in users) {
    //               let user = users[i];
    //               let foundUser = experts.find((elem) => elem.id == user.ID);
    //               console.log("::chiefAdd foundUser =", foundUser);
    //               if (!foundUser) {
    //                 let itemUser = {
    //                   id: user.ID,
    //                   name: user.NAME + " " + user.LAST_NAME,
    //                   deps: user.UF_DEPARTMENT,
    //                   color: "primary",
    //                   type: "ch",
    //                 };
    //                 experts.push(itemUser);
    //               }
    //             }
    //             console.log("::chiefAdd experts =", experts);
    //             console.log( "::chiefAdd this.degree360.students[ind] =", this.degree360.students[ind] );
    //           },
    //           (err) => {
    //             console.log(err);
    //           }
    //         );
    //       }
    //     });
    //   }
    // },
    chiefAdd() {
      let $this = this;
      const stud_id = this.parent.user_current.ID;
      const ind = this.degree360.students.findIndex(el => el.id == stud_id);
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = this.degree360.students[ind].experts;
      console.log("::chiefAdd stud_id, departments=", stud_id, $this.departments);
      
        this.parent.appOptionGet('struct').then((res) => {
          if(this.parent.isJSON(res.DETAIL_TEXT)){
            let tree = JSON.parse(res.DETAIL_TEXT);
            if(tree[0]){
              let stud_deps=[];
              this.parent.getUserDepartments(stud_id, tree[0], stud_deps)

              console.log("::chiefAdd stud_deps=", stud_deps);
              let arChiefs = [];
              for (let dep_id of stud_deps) {
                let item = $this.departments.find((el) => el.value == dep_id);
                console.log("::chiefAdd dep_id=", dep_id, item);
                if (item?.uf_head && item.uf_head != stud_id && item.uf_head != 0) {
                  arChiefs.push(item.uf_head);
                }else if(item && (!item.uf_head || item.uf_head == stud_id) ){
                  //Если юзер сам начальник в этом отделе, то поищем начальника в отделе выше по стркуктуре
                  const chain = this.getChain($this.departments, item.value);
                  console.log(`id=${item.ID}, chain=`,chain);
                  for(let dep of chain){
                    if(dep.uf_head && dep.uf_head != stud_id){
                      arChiefs.push(dep.uf_head);
                      break;
                    }
                  }

                }
              }
              console.log("::chiefAdd arChiefs =", arChiefs);
              if (arChiefs.length > 0) {
                //for id получаем name начлаьников отделов юзера
                $this.parent.getUsersByIds(arChiefs).then( (users) => {
                    console.log("::chiefAdd Chiefs =", users);
                    for (let i in users) {
                      let user = users[i];
                      let foundUser = experts.find((elem) => elem.id == user.id);
                      if (!foundUser) {
                        let itemUser = {
                          id: user.id,
                          name: user.name,
                          deps: user.dep,
                          color: "secondary",
                          type: "ch",
                        };
                        experts.push(itemUser);
                      }
                    }
                    console.log("::chiefAdd experts =", experts);
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }else{
                //если нет начальников, то предлагаем выбрать вручную
                this.parent.usersAdd([], true).then((res) => {
                    console.log("::chiefAdd res=", res);
                    let user = res[0];
                    let itemUser = {
                      id: user.id,
                      name: user.name,
                      deps: user.dep,
                      color: "primary",
                      type: "ch",
                    };
                    experts.push(itemUser);
                  });
              }
            }
          }
        });
      // }
    },
    findObjectByValue(array, value) {
      return array.find(item => item.value == value);
    },
    getChain(array, startValue) {
      const result = [];
      let currentObject = this.findObjectByValue(array, startValue);

      while (currentObject) {
          result.push(currentObject);
          currentObject = this.findObjectByValue(array, currentObject.parent);
      }

      return result;
    },
    slavesAdd(){
      let $this = this;
      const stud_id = this.parent.user_current.ID;
      const ind = this.degree360.students.findIndex(el => el.id == stud_id);
      if(ind < 0){
        return;
      }
      this.degree360.students[ind].experts = this.degree360.students[ind].experts || [];
      let experts = this.degree360.students[ind].experts;
      console.log("::slavesAdd stud_id, departments=", stud_id, $this.departments);
      
      this.parent.appOptionGet('struct').then((res) => {
        if(this.parent.isJSON(res.DETAIL_TEXT)){
          let tree = JSON.parse(res.DETAIL_TEXT);
          if(tree[0]){
            let stud_deps=[];
            this.parent.getUserDepartments(stud_id, tree[0], stud_deps)
            console.log("::slavesAdd stud_deps=", stud_deps); //отделы юзера
            let deps_head_ids=[]; // массив id отделов где юзер начальник
            for(let dep of stud_deps){
              let item = $this.departments.find((el) => el.value == dep);
              if(item && item.uf_head==stud_id){
                //юзер начальник в этом отделе
                deps_head_ids.push(item.value);
              }
            }
            if(deps_head_ids.length>0){
               $this.parent.getUsers(true, deps_head_ids).then( (users) => {
                console.log("::slavesAdd users =", users);
                for (let i in users) {
                  let user = users[i];
                  if(!experts.find((elem) => elem.id == user.id)){
                    if (!user.id != stud_id){
                      let itemUser = {
                        id: user.id,
                        name: user.name,
                        deps: user.dep,
                        color: "primary",
                        type: "sl",
                      };
                      experts.push(itemUser);
                    }
                  }else{
                    $this.parent.notify_title = this.message[this.lang].attention;
                    $this.parent.notify_text = this.message[this.lang].such_user_exist;
                    $this.parent.notify = true;
                  }
                }
              });
            }

          }
        }
      });

    },
    // slavesAdd() {
    //   let $this = this;
    //   const stud_id = this.parent.user_current.ID;
    //   const ind = this.degree360.students.findIndex(el => el.id == stud_id);
    //   console.log(":: expertsAdd ind=", ind, this.degree360.students);
    //   if(ind < 0){
    //     return;
    //   }
    //   let experts = this.degree360.students[ind].experts || [];
    //   let stud_deps = this.degree360.students[ind].dep;
    //   if (stud_deps && stud_deps.length > 0) {
    //     let arBatch = {};
    //     for (let id of stud_deps) {
    //       arBatch["get_" + id] = ["department.get", { ID: id }];
    //     }
    //     $this.parent.callBatchIB(arBatch).then((result) => {
    //       console.log("::slavesAdd arBatch result=", result);
    //       for (let i in result.result) {
    //         console.log("::slavesAdd arBatch result=", i, result.result[i]);
    //         let item = result.result[i][0];
    //         if (item && item.UF_HEAD == stud_id) {
    //           //насяльника в своем отделе
    //           let allSubDeps = $this.parent.getChildren($this.departments, item.ID);
    //           console.log(":++++++: allSubDeps=", allSubDeps);
    //           //получаем юзеров этого отдела с подотделами
    //           $this.parent
    //             .getUsers(
    //               true,
    //               [...item.ID, ...allSubDeps.map((elem) => parseInt(item.value))],
    //               null
    //             )
    //             .then(
    //               (users) => {
    //                 for (let i in users) {
    //                   let user = users[i];
    //                   let foundUser = experts.find((elem) => elem.id == user.ID);
    //                   if (!foundUser) {
    //                     let itemUser = {
    //                       id: user.ID,
    //                       name: user.NAME + " " + user.LAST_NAME,
    //                       deps: user.UF_DEPARTMENT,
    //                       color: "primary",
    //                       type: "sl",
    //                     };
    //                     experts.push(itemUser);
    //                   }
    //                 }
    //                 // $this.parent.getSubAccounts(experts).then((res) => {
    //                 //   experts = experts.concat(res);
    //                   console.log("::slavesAdd experts=", experts);
    //                   this.degree360.students[ind].experts = experts;
    //                 // });
    //               },
    //               (err) => {
    //                 console.log(err);
    //               }
    //             );
    //         }
    //       }
    //     });
    //   }
    // },
  },
  components: {},
};
</script>
